import * as React from "react";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { initialDescription } from "../../utils/pagination";
import { UserEmployer, EmployerStatus } from "../../types/EmployerTypes";
import { searchUserEmployersByStatus } from "../../api/EmployerHandlingService";
import { UserEmployers } from "./UserEmployers";
import { useDispatch } from 'react-redux';

function Employers() {
  let emptyUserEmployers: UserEmployer[] = [];

  /* #### CHAKRA-UI - POP-UP #### */
  const toast = useToast();
  /* #### CHAKRA-UI - POP-UP #### */

  /* #### CHAKRA-UI - CHECKBOX #### */
  const [checkedItems, setCheckedItems] = React.useState([false]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  /* #### CHAKRA-UI - CHECKBOX #### */

  const [totalHits, setTotalHits] = React.useState(0);
  const [pageRequest, setPageRequest] = React.useState(initialDescription);

  const [employerStatus, setEmployerStatus] = React.useState(EmployerStatus.ALL);
  const [userEmployers, setUserEmployers] = React.useState(emptyUserEmployers);
  const [checkedUserEmployers, setCheckedUserEmployers] = React.useState(emptyUserEmployers);

  const dispatch = useDispatch();

  useEffect(() => {
    searchUserEmployersByStatus(toast,
                                pageRequest,
                                employerStatus,
                                setEmployerStatus,
                                setTotalHits,
                                setUserEmployers,
                                setCheckedItems,
                                dispatch);
  }, [toast, pageRequest, employerStatus]);

  return (
    UserEmployers(toast,
                  pageRequest,
                  totalHits,
                  employerStatus,
                  userEmployers,
                  checkedUserEmployers,
                  checkedItems,
                  allChecked,
                  isIndeterminate,
                  dispatch,
                  setPageRequest,
                  setTotalHits,
                  setEmployerStatus,
                  setUserEmployers,
                  setCheckedUserEmployers,
                  setCheckedItems)
  );
}

export { Employers }
