export type UserSession = {
    isLoggedIn: boolean;
    username: string
    userRoles: string[]
    picUrl: string
};

export type UserToken = {
    username: string
    userRoles: string[]
    accessToken:  string
    refreshToken: string
};

export type User = {
    username: string
    email: string
    password?: string
    enabled?: boolean
    accountExpired?: boolean
    accountLocked?: boolean
    passwordExpired?: boolean
    validated?: boolean
    lastLoginTime?: string
    userRoles?: any[]
    userProviders?: any[]
    profile?: Profile
    createDate?: any
    updateDate?: any
    appleId?: string
    googleId?: string
    evcId?: string
    fcmToken?: string
    appleToken?: string
    googleToken?: string
};

export type Profile = {
    username: string
    firstName: string
    lastName: string
    locale: string
    phone: string
    signUpCompleted: boolean
    pictureName: string
    vehicles: any[]
    ageGroup: string
    education: string
    ethnicity: string
    faith: string
    gender: string
    race: string
    sexualOrientation: string
    zodiacSign: string
    profileAdditionalInfo: ProfileAdditionalInfo
    createDate: any
    updateDate: any
};

export type ProfileAdditionalInfo = {
    socialMediaFavoritePlatforms: string
    hobbies: string
    otherInterests: string
    relationshipStatus: string
    pronouns: string
    partOfLGBTQPlusCommunity: boolean
    employmentStatus: string
    spendingTimeWhileCharging: string
    shoppingBehavior: string
    interestedInSocialNetworking: boolean
};

export type UserLocationExport = {
    evse: string
};

export const OnlySuperAdmin : string[] = ['ROLE_SUPER_ADMIN'];
export const OnlyAdmins : string[] = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'];
export const OnlyAdminsRetailers : string[] = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_RETAILER'];
