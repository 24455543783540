import { AxiosResponse } from "axios";
import { EntityListResponse } from "../types/ApiTypes";
import { QuestionnaireQuestion, Question, Questionnaire, QuestionnaireQuestionRequest } from "../types/QuestionnaireTypes";
import {
  findAllQuestionnaires,
  getAllQuestionnaireQuestionsList,
  getQuestionnaireQuestionsList,
  getQuestionnaireByName,
  addQuestionnaire,
  addQuestionnaireQuestions as addQuestionnaireQuestionsApi
} from "../api/QuestionnaireService";
import { downloadCompletedQuestionnaireCsvFile } from "../api/ReportService";
import { getQuestionsList } from "../api/QuestionService";

const getErrorMessage = (error: any) => {
  return error.message + " (API: " + error.response.data.apierror.debugMessage + ")";
}

export async function getAllQuestionnaireQuestions(pageRequestQuestionnaire: any, onlyValid: boolean) {
  let params = {
    page: pageRequestQuestionnaire ? pageRequestQuestionnaire.page : 0,
    size: pageRequestQuestionnaire ? pageRequestQuestionnaire.pageSize : 8,
    onlyValid: onlyValid
  }
  return await getAllQuestionnaireQuestionsList(params).then((response: AxiosResponse) => {
    let entityResponse: EntityListResponse<QuestionnaireQuestion> = response.data;
    return entityResponse;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function getQuestionnaireQuestions(questionnaireName: string, pageRequestQuestionnaire: any) {
  let params = {
    questionnaireName: questionnaireName,
    page: pageRequestQuestionnaire ? pageRequestQuestionnaire.page : 0,
    size: pageRequestQuestionnaire ? pageRequestQuestionnaire.pageSize : 8,
  }
  return await getQuestionnaireQuestionsList(params).then((response: AxiosResponse) => {
    let entityResponse: EntityListResponse<QuestionnaireQuestion> = response.data;
    return entityResponse;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function getActiveQuestions(pageRequestActive: any) {
  let params = {
    page: pageRequestActive ? pageRequestActive.page : 0,
    size: pageRequestActive ? pageRequestActive.pageSize : 6,
    status: "VALID"
  }
  return await getQuestionsList(params).then((response: AxiosResponse) => {
    let entityResponse: EntityListResponse<Question> = response.data;
    return entityResponse;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function getInactiveQuestions(pageRequestInactive: any) {
  let params = {
    page: pageRequestInactive ? pageRequestInactive.page : 0,
    size: pageRequestInactive ? pageRequestInactive.pageSize : 4,
    status: "INVALID"
  }
  return await getQuestionsList(params).then((response: AxiosResponse) => {
    let entityResponse: EntityListResponse<Question> = response.data;
    return entityResponse;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function loadAllQuestionnaires() {
  return await findAllQuestionnaires().then((response: AxiosResponse) => {
    let entityResponse: EntityListResponse<Questionnaire> = response.data;
    return entityResponse;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function retrieveQuestionnaireByName(questionnaireName: string) {
  let params = {
    questionnaireName: questionnaireName
  }
  return await getQuestionnaireByName(params).then((response: AxiosResponse) => {
    let questionnaire: Questionnaire = response.data;
    return questionnaire;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function downloadQuestionnaireCsv(questionnaireName: string) {
  let params = {
    questionnaireName: questionnaireName
  }
  return await downloadCompletedQuestionnaireCsvFile(params).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function createQuestionnaire(questionnaire: Questionnaire) {
  return await addQuestionnaire(questionnaire).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function addQuestionnaireQuestions(questionnaireName: string, questionnaireQuestions: QuestionnaireQuestion[]) {
  let body: QuestionnaireQuestionRequest = {
    questionnaireName: questionnaireName,
    questionnaireQuestions: questionnaireQuestions
  }
  return await addQuestionnaireQuestionsApi(body).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}
