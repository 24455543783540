import { authApiHeaderMultiPartFile, authApiHeader } from "./AuthHeader";

const axios = require('axios').default;
const API_URL = process.env.REACT_APP_API_URL + "/api/report";

export const downloadUnifiedEvsesCsvFileByParameter = (goStationEvsesCsvFile: File, roamingEvsesCsvFile: File) => {
  let data = new FormData();
  data.append("goStationEvsesCsvFile", goStationEvsesCsvFile);
  data.append("roamingEvsesCsvFile", roamingEvsesCsvFile);
  return axios.post(API_URL + "/downloadUnifiedEvsesCsvFile", data, authApiHeaderMultiPartFile());
}

export const downloadUnifiedEvsesCsvFile = () => {
  return axios.get(API_URL + "/downloadUnifiedEvsesCsvFile", { headers: authApiHeader(), responseType: 'blob' });
}

export const downloadCompletedQuestionnaireCsvFile = (params: any) => {
  return axios.get(API_URL + "/downloadCompletedQuestionnaireCsvFile", { headers: authApiHeader(), params, responseType: 'blob' });
}

export const downloadAllPromoCodesCsvFile = () => {
  return axios.get(API_URL + "/downloadAllPromoCodesCsvFile", { headers: authApiHeader(), responseType: 'blob' });
}

export const downloadPromoCodeCsvFile = (params: any) => {
  return axios.get(API_URL + "/downloadPromoCodeCsvFile", { headers: authApiHeader(), params, responseType: 'blob' });
}

export const downloadAdReportCsv = (params: any) => {
  return axios.get(API_URL + "/downloadAdReportCsvFile", { headers: authApiHeader(), params, responseType: 'blob' });
};

export const downloadAllAdsReportCsv = () => {
  return axios.get(API_URL + "/downloadAllAdsReportCsvFile", { headers: authApiHeader(), responseType: 'blob' });
};
