function displayMessage(toast: any, status: string, title: string, message: string, position: string) {
  toast({
    status: status,
    title: title,
    description: message,
    position: position,
    duration: 5000,
    isClosable: true,
  });
}

function displayInfoMessage(toast: any, title: string, message: string, position: string) {
  displayMessage(toast, "info", title, message, position);
}

function displaySuccessMessage(toast: any, title: string, message: string, position: string) {
  displayMessage(toast, "success", title, message, position);
}

function displayErrorMessage(toast: any, title: string, message: string, position: string) {
  displayMessage(toast, "error", title, message, position);
}

export { displayInfoMessage, displaySuccessMessage, displayErrorMessage }
