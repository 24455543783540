import { Action } from 'redux';
import { setLoading } from '../actions/loadingActions';

export interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
};

export interface LoadingAction extends Action {
  type: typeof setLoading.type;
  payload: boolean;
}

function isLoadingAction(action: Action): action is LoadingAction {
  return action.type === setLoading.type;
}

export const loadingReducer = (
  state = initialState,
  action: Action
): LoadingState => {
  if (isLoadingAction(action)) {
    switch (action.type) {
      case setLoading.type:
        return {
          ...state,
          isLoading: action.payload,
        };
    }
  }

  return state;
};