import { AxiosResponse } from "axios";
import { downloadAdReportCsv, downloadAllAdsReportCsv } from "../api/ReportService";
import { getApiErrorMessage } from "../utils/ErrorMessage";

export async function downloadAdsCsv(adName: string) {
  let params = {
    adName: adName
  }
  return await downloadAdReportCsv(params).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getApiErrorMessage(error);
  });
};

export async function downloadAllAdsCsv() {
  return await downloadAllAdsReportCsv().then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getApiErrorMessage(error);
  });
};

