import {ChakraProvider, ColorModeScript, CSSReset} from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import {SessionContextProvider} from "./context/AuthContext";
import {customTheme} from "./utils/theme";
import {BrowserRouter as Router} from "react-router-dom";
import { store } from "./store/index";
import { Provider } from "react-redux";

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <SessionContextProvider>
      <ChakraProvider theme={customTheme}>
        <CSSReset />
        <Router>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </ChakraProvider>
    </SessionContextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
