import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    VStack,
    HStack,
    Heading,
    FormControl,
    Input,
    FormErrorMessage,
    Select,
    Center,
    Divider,
    Button,
    useToast,
    FormLabel,
    Textarea,
    RadioGroup,
    Radio,
    Spacer,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    NumberInput,
    NumberInputField,
    ModalBody, Modal, useDisclosure, AspectRatio, Checkbox,
} from "@chakra-ui/react"
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom"
import DatePicker from "react-datepicker";
import {addAd, addAdVideo, getAd, getAdVideo, updateAd, updateAdVideo} from "../../api/AdService";
import {AxiosResponse} from "axios";
import {useEffect, useState, ChangeEvent} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Ad, AdCategory} from "../../types/AdsTypes";
import {addAdCategory, getAdCategoryList} from "../../api/AdCategoryService";
import {EntityListResponse} from "../../types/ApiTypes";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import Loader from "../../utils/Loading";

export function AdsDetails() {
    const toast = useToast();
    let { adId } = useParams();
    let match = useRouteMatch();
    let history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure()

    let initial : Ad = {
        name: "",
        isAnnouncement: true,
        categoryName: "",
        type: "",
        title: "",
        content: "",
        link: "",
        active: true,
        videoUrl: "",
        audienceGender: "",
        audienceAgeRange: "",
        startDate: "",
        expireDate: "",
        isLocationRestricted: false,
        quizQuestion: "",
        quizAnswer1: "",
        quizAnswer2: "",
        quizAnswer3: "",
        quizAnswer4: "",
        quizCorrectAnswer: 0
    };
    const [ad, setAd] = useState(initial);
    const [startDate, setStartDate] = useState(new Date());
    const [expireDate, setExpireDate] = useState(new Date());
    const initialLat = '35.098702'
    const initialLong = '-106.564627'

    const [lat, setLat] = useState(initialLat);
    const [long, setLong] = useState(initialLong);
    const [radius, setRadius] = useState(1);

    const [isLatError, setIsLatError] = useState(false);
    const [isLongError, setIsLongError] = useState(false);
    const [isRadiusError, setIsRadiusError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [evse, setEvse] = useState("");
    const [isEvseError, setIsEvseError] = useState(false);

    const [networkId, setNetworkId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locationRestriction, setLocationRestriction] = useState("NO_LOCATION_RESTRICTION");

    let initialCategories : AdCategory[] = []
    const [categoriesList, setCategoriesList] = React.useState(initialCategories)
    const [newCategory, setNewCategory] = useState("");

    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedNewFile, setSelectedNewFile] = useState(false);

    const [isActiveOverlay, setIsActiveOverlay] = useState(false);

    const [adURL, setAdURL] = useState("");


     // Handle changes and validation
     const handleLatChange = (valueAsString: string) => {
        setIsLatError(!valueAsString);
        setLat(valueAsString);
    };

    const handleLongChange = (valueAsString: string) => {
        setIsLongError(!valueAsString);
        setLong(valueAsString);
    };

    const handleRadiusChange = (valueAsString: string, valueAsNumber: number) => {
        setIsRadiusError(isNaN(valueAsNumber) || valueAsNumber < 1);
        setRadius(valueAsNumber);
    };

    const handleEvseChange = (e: ChangeEvent<HTMLInputElement>) => {
        const valueAsString = e.target.value
        setIsEvseError(valueAsString === '');
        setEvse(valueAsString);
    };

    const schema = yup.object().shape({
        name: yup.string().required(),
        categoryName: yup.string().min(1).notOneOf(categoriesList.map(cat => cat.name)),
        text: yup.string().max(100).required(),
        title: yup.string().min(1),
        content: yup.string().max(100),
        link: yup.string().min(1),
        videoUrl: yup.string().min(1),
        quizQuestion: yup.string().min(1),
        quizAnswer1: yup.string().min(1),
        quizAnswer2: yup.string().min(1),
        quizAnswer3: yup.string().min(1),
        quizAnswer4: yup.string().min(1),
        quizCorrectAnswer: yup.number().min(1).max(4)
    });

    const { register, formState: { errors } } = useForm<Ad>({
        mode: "onBlur",
        resolver: yupResolver(schema),
    });

    const setField = (name : string, value: any) => {
        const newAd: any = {
            ...ad,
            [name]: value
        }
        setAd(newAd)
    }

    const handleUpload = (event: any) => {
        setSelectedFile(event.target.files[0])
        setSelectedNewFile(true)
        let temp = URL.createObjectURL(event.target.files[0])
        setAdURL(temp);
    }

    const saveAction = () => {
        if (locationRestriction === "AREA_LOCATION_RESTRICTION" && (isLatError || isLongError || isRadiusError)
            || (locationRestriction === "LOCATION_RESTRICTION" && evse === '')) {
            toast({
                title: "Validation error",
                position: "bottom",
                description: "Please add a valid area for location",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return;
        }
        setIsActiveOverlay(true)
        ad.startDate = startDate.toISOString()
        ad.expireDate = expireDate.toISOString()
        ad.isLocationRestricted = locationRestriction !== "NO_LOCATION_RESTRICTION"

        // clear old values
        ad.physicalReference = ''
        ad.latitude = 0
        ad.longitude = 0
        ad.radius = 0

        if (locationRestriction === "LOCATION_RESTRICTION") {
            ad.physicalReference = evse
        } else if (locationRestriction === "AREA_LOCATION_RESTRICTION") {
            ad.latitude = Number(lat)
            ad.longitude = Number(long)
            ad.radius = radius
        }

        if (ad.categoryName === "NEW_TOPIC") {
            let adCategory : AdCategory = {
                name: newCategory,
                label: newCategory
            }
            addAdCategory(adCategory).then((response: AxiosResponse) => {
                if ([200, 201].includes(response.status)) {
                    ad.categoryName = newCategory
                    if (!adId) {
                        createAd(ad)
                    } else {
                        modifyAd(ad)
                    }
                } else {
                    return Promise.reject(response.data.apierror.message);
                }
            }).catch( (error: any) => {
                setIsActiveOverlay(false)
                toast({
                    title: "Failed to add Ad Category",
                    position: "bottom",
                    description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            })
        } else {
            if (!adId) {
                createAd(ad)
            } else {
                modifyAd(ad)
            }
        }
    };

    const createAd = (ad : Ad) => {
        addAd(ad).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                if (ad.type === "VIDEO") {
                    uploadAdVideo()
                } else {
                    setIsActiveOverlay(false)
                    toast({
                        title: "New Ad created",
                        position: "top",
                        description: "Ad added successfully",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                    })
                    history.push('/ads');
                }
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            setIsActiveOverlay(false)
            toast({
                title: "Failed to add Ad",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    const modifyAd = (ad : Ad) => {
        updateAd(ad).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                if (ad.type === "VIDEO" && selectedNewFile && selectedFile) {
                    reUploadAdVideo()
                } else {
                    setIsActiveOverlay(false)
                    toast({
                        title: "Ad",
                        position: "top",
                        description: "Ad updated successfully",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                    })
                    history.push('/ads');
                }
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            setIsActiveOverlay(false)
            toast({
                title: "Failed to update Ad",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    const uploadAdVideo = () => {
        if (ad.name && selectedNewFile && selectedFile) {
            let data = new FormData();
            data.append("video", selectedFile);
            addAdVideo(ad.name, data).then((response: AxiosResponse) => {
                if ([200, 201].includes(response.status)) {
                    setIsActiveOverlay(false)
                    toast({
                        title: "Ad video added",
                        position: "top",
                        description: "Ad video added successfully",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                    })
                    history.push('/ads');
                } else {
                    return Promise.reject(response.data.apierror.message);
                }
            }).catch( (error: any) => {
                setIsActiveOverlay(false)
                toast({
                    title: "Failed to upload Ad video",
                    position: "bottom",
                    description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            })
        } else {
            setIsActiveOverlay(false)
            toast({
                title: "Missing values",
                position: "bottom",
                description: "You must select a name and video before saving the Ad",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    };

    const reUploadAdVideo = () => {
        let data = new FormData();
        data.append("video", selectedFile as File);
        updateAdVideo(ad.name as string, data).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                setIsActiveOverlay(false)
                toast({
                    title: "Ad video updated",
                    position: "top",
                    description: "Ad video updated successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                history.push('/ads');
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            setIsActiveOverlay(false)
            toast({
                title: "Failed to re upload Ad video",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    useEffect(() => {
        if (adId) {
            let params = {
                name: adId
            }
            setIsLoading(true);
            getAd(params).then((response: AxiosResponse) => {
                setIsLoading(false);
                if ([200, 201].includes(response.status)) {
                    const startDateFormatted = moment(response.data.startDate).toDate();
                    const expireDateFormatted = moment(response.data.expireDate).toDate();
                    setStartDate(startDateFormatted);
                    setExpireDate(expireDateFormatted);
                    if (response.data.isLocationRestricted) {
                        if (response.data.radius && response.data.radius > 0) {
                            setLat(response.data.latitude);
                            setLong(response.data.longitude);
                            setRadius(response.data.radius as number);
                            setLocationRestriction("AREA_LOCATION_RESTRICTION");
                        } else {
                            setNetworkId(response.data.networkId as string);
                            setLocationId(response.data.locationId as string);
                            setLocationRestriction("LOCATION_RESTRICTION");
                        }
                    } else {
                        setLocationRestriction("NO_LOCATION_RESTRICTION");
                    }
                    setAd(response.data as Ad);
                    getAdVideo(params).then((response: AxiosResponse) => {
                        if ([200, 201].includes(response.status)) {
                            setAdURL(response.data);
                        } else {
                            return Promise.reject(response.data.apierror.message);
                        }
                    }).catch( (error: any) => {
                        toast({
                            title: "Failed to get Ad video from backend",
                            position: "bottom",
                            description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        history.push('/ads');
                    })
                } else {
                    return Promise.reject(response.data.apierror.message);
                }
            }).catch( (error: any) => {
                setIsLoading(false);
                toast({
                    title: "Failed to get Ad from backend",
                    position: "bottom",
                    description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
                history.push('/ads');
            })
        }
    }, [history, adId, toast]);

    useEffect(() => {
        getAdCategoryList(null).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                let entityResponse: EntityListResponse<AdCategory> = response.data
                setCategoriesList(entityResponse.data);
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to get Ads Categories from backend",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            history.push('/ads');
        })
    }, [history, toast]);

    return (
      <LoadingOverlay active={isActiveOverlay} spinner text='Uploading Ad...'>
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem color="gostation.100">
                      <BreadcrumbLink as={Link} to="/ads">
                          <Text>Ads</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem color="gostation.500" isCurrentPage>
                      <BreadcrumbLink as={Link} to={match.url}>
                          {adId ? <Text>Edit Ad</Text> : <Text>New Ad</Text>}
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          <Flex w="96%" h="90%" minH="600px" bg="white" ml={6} mr={6}>
            {isLoading ? (
                <Loader />
            ) : (

              <VStack>
                  <HStack>
                      <VStack minH="600px" minW="550px" pl={4} pr={4} alignItems="left">
                          <Heading pb={1} pt={2} fontWeight="bold" size="sm" color="gostation.500">Name</Heading>
                          <FormControl id="key" p="1"
                                       isInvalid={!!errors?.name?.message}
                                       errortext={errors?.name?.message}>
                              <FormLabel fontSize="xs" color="gray">Type a unique Name</FormLabel>
                              <Input {...register("name")} isRequired isDisabled={adId} placeholder="Ad Name" value={ad.name} size="md" onChange={(e) => setField("name", e.target.value)}  />
                              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                          </FormControl>
                          <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500">Category</Heading>
                          <Checkbox isDisabled={adId} isChecked={ad.isAnnouncement} onChange={(e) => setField("isAnnouncement", e.target.checked)}>Is the Ad an Announcement?</Checkbox>
                          {!ad.isAnnouncement && <HStack spacing={4}>
                              <FormControl id="cat" w="250px" p="1">
                                  <FormLabel fontSize="xs" color="gray">Select a Category</FormLabel>
                                  <Select isRequired placeholder="Please Select" value={ad.categoryName} onChange={(e) => setField("categoryName", e.target.value)}>
                                      <option value="NEW_TOPIC">Add a new topic</option>
                                      {categoriesList && categoriesList.map((category : AdCategory) => {
                                          return (
                                              <option value={category.name}>{category.label}</option>
                                          )
                                      })}
                                  </Select>
                              </FormControl>
                              {(ad.categoryName === "NEW_TOPIC") && <FormControl id="newCat" w="250px" p="1"
                                           isInvalid={!!errors?.categoryName?.message}
                                           errortext={errors?.categoryName?.message}>
                                  <FormLabel fontSize="xs" color="gray">Type New Category</FormLabel>
                                  <Input {...register("categoryName")} isRequired placeholder="New Category" value={newCategory} size="md" onChange={(e) => setNewCategory(e.target.value)}  />
                                  <FormErrorMessage>{errors?.categoryName?.message}</FormErrorMessage>
                              </FormControl>}
                          </HStack>}
                          <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500">Type</Heading>
                          <FormControl id="type" w="250px" p="1">
                              <FormLabel fontSize="xs" color="gray">Select Ad Type</FormLabel>
                              <Select isRequired placeholder="Please Select" value={ad.type} onChange={(e) => setField("type", e.target.value)}>
                                  <option value="VIDEO">Video</option>
                                  <option value="LINK">Text</option>
                              </Select>
                          </FormControl>
                          {(ad.type === "LINK") && <Box>
                              <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500">Text Details</Heading>
                              <FormControl id="title" p="1"
                                           isInvalid={!!errors?.title?.message}
                                           errortext={errors?.title?.message}>
                                  <FormLabel fontSize="xs" color="gray">Heading</FormLabel>
                                  <Input {...register("title")} placeholder="Add a Heading" value={ad.title} size="md" onChange={(e) => setField("title", e.target.value)}  />
                                  <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                              </FormControl>
                              <FormControl id="content" p="1"
                                           isInvalid={!!errors?.content?.message}
                                           errortext={errors?.content?.message}>
                                  <FormLabel fontSize="xs" color="gray">Body</FormLabel>
                                  <Textarea {...register("content")} mb={4} value={ad.content} placeholder="Type content here..." size="md" onChange={(e) => setField("content", e.target.value)}/>
                                  <FormErrorMessage>{errors?.content?.message}</FormErrorMessage>
                              </FormControl>
                          </Box>}
                          {(ad.type === "LINK") && <Box>
                              <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500">Destination URL</Heading>
                              <FormControl id="link" p="1"
                                           isInvalid={!!errors?.link?.message}
                                           errortext={errors?.link?.message}>
                                  <FormLabel fontSize="xs" color="gray">Destination URL</FormLabel>
                                  <Input {...register("link")} placeholder="https://www.website.com" value={ad.link} size="md" onChange={(e) => setField("link", e.target.value)}  />
                                  <FormErrorMessage>{errors?.link?.message}</FormErrorMessage>
                              </FormControl>
                          </Box>}
                          {(ad.type === "VIDEO") && <Box>
                              <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500">Video Details</Heading>
                              <HStack spacing={4}>
                                  <FormControl id="selectedFile" p="1">
                                      <FormLabel fontSize="xs" color="gray">Upload Video</FormLabel>
                                      <Input p={1} type="file" size="md" onChange={handleUpload}  />
                                  </FormControl>
                                  {adURL &&
                                  <FormControl id="previewVideo" p="1" w="150px">
                                      <FormLabel fontSize="xs" color="gray">Preview Video</FormLabel>
                                      <Button onClick={onOpen} colorScheme="gostation" bg="gostation.100" variant="solid" size="md">Preview Video</Button>
                                  </FormControl>
                                  }
                                  <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none">
                                      <ModalOverlay />
                                      <ModalContent maxW="380">
                                          <ModalHeader color="gostation.500" pt={2} pl={6} pb={0}>Video Preview</ModalHeader>
                                          <ModalBody p={2}>
                                              <AspectRatio maxW="360px" maxH="640px" ratio={9 / 19.5}>
                                                  <iframe
                                                      title="preview"
                                                      src={adURL}
                                                  />
                                              </AspectRatio>
                                          </ModalBody>
                                      </ModalContent>
                                  </Modal>
                              </HStack>
                          </Box>}
                          {(ad.type === "VIDEO") && <Box>
                              <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500">Post Ad Quiz</Heading>
                              <FormControl id="quizQuestion" p="1"
                                           isInvalid={!!errors?.quizQuestion?.message}
                                           errortext={errors?.quizQuestion?.message}>
                                  <FormLabel fontSize="xs" color="gray">Question Text</FormLabel>
                                  <Input {...register("quizQuestion")} placeholder="Type the question here" value={ad.quizQuestion} size="md" onChange={(e) => setField("quizQuestion", e.target.value)}  />
                                  <FormErrorMessage>{errors?.quizQuestion?.message}</FormErrorMessage>
                              </FormControl>
                              <HStack>
                                  <FormControl id="quizAnswer1" p="1"
                                               isInvalid={!!errors?.quizAnswer1?.message}
                                               errortext={errors?.quizAnswer1?.message}>
                                      <FormLabel fontSize="xs" color="gray">1 Answer text</FormLabel>
                                      <Input {...register("quizAnswer1")} placeholder="Type answer here" value={ad.quizAnswer1} size="md" onChange={(e) => setField("quizAnswer1", e.target.value)}  />
                                      <FormErrorMessage>{errors?.quizAnswer1?.message}</FormErrorMessage>
                                  </FormControl>
                                  <FormControl id="quizAnswer2" p="1"
                                               isInvalid={!!errors?.quizAnswer2?.message}
                                               errortext={errors?.quizAnswer2?.message}>
                                      <FormLabel fontSize="xs" color="gray">2 Answer text</FormLabel>
                                      <Input {...register("quizAnswer2")} placeholder="Type answer here" value={ad.quizAnswer2} size="md" onChange={(e) => setField("quizAnswer2", e.target.value)}  />
                                      <FormErrorMessage>{errors?.quizAnswer2?.message}</FormErrorMessage>
                                  </FormControl>
                              </HStack>
                              <HStack>
                                  <FormControl id="quizAnswer3" p="1"
                                               isInvalid={!!errors?.quizAnswer3?.message}
                                               errortext={errors?.quizAnswer3?.message}>
                                      <FormLabel fontSize="xs" color="gray">3 Answer text</FormLabel>
                                      <Input {...register("quizAnswer3")} placeholder="Type answer here" value={ad.quizAnswer3} size="md" onChange={(e) => setField("quizAnswer3", e.target.value)}  />
                                      <FormErrorMessage>{errors?.quizAnswer3?.message}</FormErrorMessage>
                                  </FormControl>
                                  <FormControl id="quizAnswer4" p="1"
                                               isInvalid={!!errors?.quizAnswer4?.message}
                                               errortext={errors?.quizAnswer4?.message}>
                                      <FormLabel fontSize="xs" color="gray">4 Answer text</FormLabel>
                                      <Input {...register("quizAnswer4")} placeholder="Type answer here" value={ad.quizAnswer4} size="md" onChange={(e) => setField("quizAnswer4", e.target.value)}  />
                                      <FormErrorMessage>{errors?.quizAnswer4?.message}</FormErrorMessage>
                                  </FormControl>
                              </HStack>
                              <Heading pb={1} pt={2} fontWeight="bold" size="sm" color="gostation.500">Post Ad Quiz Answer</Heading>
                              <FormControl id="quizCorrectAnswer" p="1">
                                  <FormLabel fontSize="xs" color="gray">Select correct Answer</FormLabel>
                                  <RadioGroup onChange={(nextValue : string) => setField("quizCorrectAnswer", Number(nextValue))} value={ad.quizCorrectAnswer}>
                                      <HStack p={2}>
                                          <Radio value={1}>Answer 1</Radio>
                                          <Spacer/>
                                          <Radio value={2}>Answer 2</Radio>
                                          <Spacer/>
                                          <Radio value={3}>Answer 3</Radio>
                                          <Spacer/>
                                          <Radio value={4}>Answer 4</Radio>
                                      </HStack>
                                  </RadioGroup>
                              </FormControl>
                          </Box>}
                      </VStack>
                      <Center h="600px">
                          <Divider orientation="vertical" />
                      </Center>
                      <VStack minH="600px" minW="550px" pl={4} pr={4} alignItems="left">
                          <Heading pb={1} pt={2} fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Ad Status</Heading>
                          <FormControl id="status" p="1">
                              <FormLabel fontSize="xs" color="gray">Status</FormLabel>
                              <HStack spacing={5}>
                                  {ad.active ? <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">ACTIVE</Center>
                                      : <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#76777A" color="#76777A">INACTIVE</Center>}
                                  <Button onClick={() => {setField("active", !ad.active)}} mr="auto" colorScheme="gostation" bg="gostation.100" variant="solid" size="md">CHANGE STATUS</Button>
                              </HStack>
                          </FormControl>
                          <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Audience</Heading>
                          <HStack spacing={4}>
                              <FormControl id="gender" w="250px" p="1">
                                  <FormLabel fontSize="xs" color="gray">Gender</FormLabel>
                                  <Select isRequired placeholder="Please Select" value={ad.audienceGender} onChange={(e) => setField("audienceGender", e.target.value)}>
                                      <option value="MALE">Male</option>
                                      <option value="FEMALE">Female</option>
                                      <option value="OTHER">Other</option>
                                      <option value="PREFER_NOT_TO_SAY">Prefer not to say</option>
                                  </Select>
                              </FormControl>
                              <FormControl id="age" w="250px" p="1">
                                  <FormLabel fontSize="xs" color="gray">Age Range</FormLabel>
                                  <Select isRequired placeholder="Please Select" value={ad.audienceAgeRange} onChange={(e) => setField("audienceAgeRange", e.target.value)}>
                                      <option value="UNDER_18">Under 18</option>
                                      <option value="BETWEEN_18_24">Between 18 and 24</option>
                                      <option value="BETWEEN_25_34">Between 25 and 34</option>
                                      <option value="BETWEEN_35_44">Between 35 and 44</option>
                                      <option value="BETWEEN_45_54">Between 45 and 54</option>
                                      <option value="BETWEEN_55_64">Between 55 and 64</option>
                                      <option value="BETWEEN_65_74">Between 65 and 74</option>
                                      <option value="OVER_75">Over 75</option>
                                      <option value="PREFER_NOT_TO_SAY">Prefer not to say</option>
                                  </Select>
                              </FormControl>
                          </HStack>
                          <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Ad Duration</Heading>
                          <HStack spacing={4}>
                              <FormControl id="start" w="250px" p="1">
                                  <FormLabel fontSize="xs" color="gray">Start Date</FormLabel>
                                  <DatePicker className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                              </FormControl>
                              <FormControl id="expire" w="250px" p="1">
                                  <FormLabel fontSize="xs" color="gray">End Date</FormLabel>
                                  <DatePicker className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" selected={expireDate} onChange={(date: Date) => setExpireDate(date)} />
                              </FormControl>
                          </HStack>
                          <Heading fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Ad Location Restrictions</Heading>
                          <HStack spacing={10}>
                              <Text fontSize="xs" color="gray">Criteria</Text>
                          </HStack>
                          <VStack alignItems="start">
                              <RadioGroup onChange={(nextValue : string) => setLocationRestriction(nextValue)} value={locationRestriction}>
                                  <Radio pl={2} size='sm' colorScheme='gostation' value="NO_LOCATION_RESTRICTION" defaultChecked>
                                      No Location restriction
                                  </Radio>
                                  <Radio pl={2} size='sm' colorScheme='gostation' value="LOCATION_RESTRICTION">
                                      Location specific
                                  </Radio>
                                  <Radio pl={2} size='sm' colorScheme='gostation' value="AREA_LOCATION_RESTRICTION">
                                      Area restriction for Location
                                  </Radio>
                              </RadioGroup>
                              {(locationRestriction === "LOCATION_RESTRICTION") && <Box>
                                  <HStack spacing={"90px"} mt={2}>
                                      <Text fontSize="xs" color="gray">Station Physical Reference</Text>
                                      {networkId && locationId && <Text fontSize="xs" color="gray">Network Id</Text>}
                                      {networkId && locationId && <Text fontSize="xs" color="gray">Location Id</Text>}
                                  </HStack>
                                  <HStack spacing={5} mt={"0.5rem"}>
                                      <FormControl isRequired isInvalid={isEvseError}>
                                        <Input placeholder="e.g. GODC0001" value={evse} size="md" onChange={handleEvseChange}/>
                                        {isEvseError && <FormErrorMessage>Please add a location.</FormErrorMessage>}
                                      </FormControl>
                                      {networkId && locationId && <Input isDisabled value={networkId} size="md" maxW="130px" />}
                                      {networkId && locationId && <Input isDisabled value={locationId} size="md"/>}
                                  </HStack>
                              </Box>}
                              {(locationRestriction === "AREA_LOCATION_RESTRICTION") && <Box>
                                  <HStack spacing={"100px"} mt={2}>
                                      <Text fontSize="xs" color="gray">Location Latitude</Text>
                                      <Text fontSize="xs" color="gray">Location Longitude</Text>
                                      <Text fontSize="xs" color="gray">Location Distance</Text>
                                  </HStack>
                                  <HStack spacing={5} mt={2} alignItems='flex-start'>
                                    <FormControl isRequired isInvalid={isLatError}>
                                        <NumberInput
                                            value={lat}
                                            precision={6}
                                            step={0.2}
                                            onChange={handleLatChange}>
                                            <NumberInputField />
                                        </NumberInput>
                                        {isLatError && <FormErrorMessage>Latitude is required.</FormErrorMessage>}
                                    </FormControl>

                                    <FormControl isRequired isInvalid={isLongError}>
                                        <NumberInput
                                            value={long}
                                            precision={6}
                                            step={0.2}
                                            onChange={handleLongChange}>
                                            <NumberInputField />
                                        </NumberInput>
                                        {isLongError && <FormErrorMessage>Longitude is required.</FormErrorMessage>}
                                    </FormControl>

                                    <FormControl isRequired isInvalid={isRadiusError}>
                                        <NumberInput
                                            value={radius}
                                            placeholder="Enter a number higher than zero"
                                            min={1}
                                            maxW="100px"
                                            onChange={handleRadiusChange}>
                                            <NumberInputField />
                                        </NumberInput>
                                        {isRadiusError && <FormErrorMessage>Radius must be greater than zero.</FormErrorMessage>}
                                    </FormControl>
                                </HStack>
                              </Box>}
                          </VStack>
                      </VStack>
                  </HStack>
                  <Divider/>
                  <HStack spacing={6}>
                      <Button as={Link} to="/ads" ml="auto" colorScheme="gostation" variant="outline" size="md">CANCEL</Button>
                      <Button onClick={() => {saveAction()}} mr="auto" colorScheme="gostation" variant="solid" size="md">SAVE</Button>
                  </HStack>
              </VStack>
            )}
          </Flex>
      </Box>
      </LoadingOverlay>
  );
}
