const applyUsaPhoneMasking = (phone: string) => {
  if (phone && phone.length === 10 && !isNaN(Number(phone))) {
    const phoneNumber = "(" + phone.substring(0, 3) + ")-"
      + phone.substring(3, 6) + "-"
      + phone.substring(6, phone.length);

    return (phoneNumber);
  } else {
    return phone
  }
}

export { applyUsaPhoneMasking };
