import React, { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  Input,
  Button,
  Image,
  FormErrorMessage,
  Checkbox,
  Link,
  VStack,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import backgroundPhoto from '../../assets/loginBackground.png';
import logo from '../../assets/loginLogo.png';
import { LoginFormInputs } from '../../types/LoginTypes';
import { login, logout } from '../../api/AuthService';
import { OnlyAdminsRetailers, UserSession } from '../../types/UserTypes';
import { useSessionContext } from '../../context/AuthContext';

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().min(8).required(),
  staySignedIn: yup.boolean(),
});

export function Login() {
  const [,setCurrentUser] = useSessionContext();
  const history = useHistory();
  const toast = useToast();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => setShow(!show);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<LoginFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      setIsLoading(true);
      let user: UserSession = await login(data);
      setIsLoading(false);
      if (
        user &&
        OnlyAdminsRetailers.some((role) => user.userRoles.includes(role))
      ) {
        setCurrentUser(user);
        toast({
          title: 'Successful Login.',
          position: 'top',
          description: 'You have been successfully logged in.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        history.push('/dashboard');
      } else {
        logout();
        toast({
          title: 'Account error.',
          position: 'top',
          description: 'User must be an Admin or Retailer.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        reset();
      }
    } catch (error) {
      toast({
        title: 'Failed login.',
        position: 'top',
        description: 'Invalid username or password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      reset();
    }
  };

  return (
    <Flex w="full" h="100vh" alignContent="center" justify="center">
      <Box w={['full', '40%']} h="100vh" fontFamily="Helvetica">
        <VStack spacing={0}>
          <VStack w="415px">
            <Box pt={['inherit', 120]} pb={['inherit', 10]}>
              <Image src={logo} />
            </Box>
            <form>
              <FormControl
                isInvalid={!!errors?.username?.message}
                errortext={errors?.username?.message}
                p="4"
                isRequired
              >
                <Input
                  {...register('username')}
                  type="text"
                  name="username"
                  placeholder="Email or Username"
                />
                <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors?.password?.message}
                errortext={errors?.password?.message}
                pl="4"
                pr="4"
                pb="4"
                isRequired
              >
                <InputGroup size="md">
                  <Input
                    {...register('password')}
                    type={show ? 'text' : 'password'}
                    name="password"
                    placeholder="Password"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
              </FormControl>
              <Checkbox
                pl="4"
                {...register('staySignedIn')}
                name="staySignedIn"
              >
                Stay signed in
              </Checkbox>
              <Button
                onClick={handleSubmit(onSubmit)}
                mx="4"
                mt="2"
                mb="1"
                w="93%"
                colorScheme="gostation"
                variant="solid"
                role="enter"
                isLoading={isLoading}
                loadingText="SIGNING IN"
                spinnerPlacement="start"
                disabled={!!errors.username || !!errors.password || isLoading}
              >
                SIGN IN
              </Button>

              <Link
                as={RouterLink}
                to="/forgotPassword"
                color="gostation.500"
                textDecoration="underline"
                p="4"
              >
                Forgot Password?
              </Link>
            </form>
          </VStack>
        </VStack>
      </Box>
      <Box w={['0%', '60%']} h="100vh">
        <Image src={backgroundPhoto} />
      </Box>
    </Flex>
  );
}
