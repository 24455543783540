import {authApiHeader, authApiHeaderMultiPartFile} from "./AuthHeader";
import {Ad} from "../types/AdsTypes";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/ads";

//Ads
export const getAdsList = (params: any) => {
    return axios.get(API_URL, { headers: authApiHeader(), params});
};

export const getAd = (params: any) => {
    return axios.get(API_URL + "/findByName", { headers: authApiHeader(), params});
};

export const searchAd = (params: any) => {
    return axios.get(API_URL + "/search", { headers: authApiHeader(), params});
};

export const getAdVideo = (params: any) => {
    return axios.get(API_URL + "/getAdVideo", { headers: authApiHeader(), params});
};

export const addAd = (body: Ad) => {
    return axios.post(API_URL, body,{ headers: authApiHeader()});
};

export const updateAd = (body: Ad) => {
    return axios.put(API_URL, body,{ headers: authApiHeader()});
};

export const addAdVideo = (name: string, data: any) => {
    return axios.post(API_URL + "/addVideo/" + name, data, authApiHeaderMultiPartFile());
};

export const updateAdVideo = (name: string, data: any) => {
    return axios.put(API_URL + "/addVideo/" + name, data, authApiHeaderMultiPartFile());
};


