export type Employer = {
  employerId: string
  name: string
  emailSuffix: string
  imageUrl: string
  termsAndConditionsUrl: string
  locations: EmployerLocation[]
}

export type EmployerLocation = {
  locationId: string
  networkId: string
  name: string
  address: EvcAddress
}

export type EvcAddress = {
  streetAddress: string
  city: string
  state: string
  iso2CountryCode: number
  iso3CountryCode: number
  regionCode: number
  zipCode: string
}

export type UserEmployer = {
  username: string
  email: string
  selectedLocation: EmployerLocation
  isEmailValidated: boolean
  status: EmployerStatus
}

export enum EmployerStatus {
  ALL = "",
  PENDING = "PENDING",
  ENROLLED = "ENROLLED",
  INACTIVE = "INACTIVE"
}
