import {authApiHeader} from "./AuthHeader";
import {AdCategory} from "../types/AdsTypes";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/adCategories";

//AdCategories
export const getAdCategoryList = (params: any) => {
    return axios.get(API_URL, { headers: authApiHeader(), params});
};

export const getAdCategory = (params: any) => {
    return axios.get(API_URL + "/findByName", { headers: authApiHeader(), params});
};

export const searchAdCategory = (params: any) => {
    return axios.get(API_URL + "/search", { headers: authApiHeader(), params});
};

export const addAdCategory = (body: AdCategory) => {
    return axios.post(API_URL, body,{ headers: authApiHeader()});
};

export const updateAdCategory = (body: AdCategory) => {
    return axios.put(API_URL, body,{ headers: authApiHeader()});
};