import * as React from "react";
import { useEffect } from "react";
import { Link as ReactLink, useHistory } from "react-router-dom";
import {
  useToast,
  Flex,
  HStack,
  VStack,
  StackDivider,
  Box,
  Text,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from "@chakra-ui/react";
import { Question } from "../../types/QuestionnaireTypes";
import {
  initialDescriptionInvalidQuestions,
  initialDescriptionQuestionnaire,
  initialDescriptionValidQuestions,
} from "../../utils/pagination";
import {
  getQuestionnaireQuestions,
  getActiveQuestions,
  getInactiveQuestions
} from "../../api/QuestionnaireHandlingService";
import { QuestionnaireSelectComponent } from "./QuestionnaireComponents";
import { QuestionnaireQuestions } from "./QuestionnaireQuestions";
import { QuestionnaireManagement } from "./QuestionnaireManagement";
import { displayErrorMessage } from "../../utils/PopUpMessage";

export function Questionnaires() {
  let history = useHistory();

  const toast = useToast();
  let activeEmptyQuestionArray: Question[] = [];
  let inactiveEmptyQuestionArray: Question[] = [];

  const [questionnairesLoadSwitch, setQuestionnairesLoadSwitch] = React.useState(0);
  const [questionnaireList, setQuestionnaireList] = React.useState<string[]>([]);
  const [questionnaireQuestionsList, setQuestionnaireQuestionsList] = React.useState<Question[]>([]);
  const [activeQuestionsList, setActiveQuestionsList] = React.useState<Question[]>([]);
  const [inactiveQuestionsList, setInactiveQuestionsList] = React.useState<Question[]>([]);
  const [pageRequestQuestionnaire, setPageRequestQuestionnaire] = React.useState(initialDescriptionQuestionnaire);
  const [pageRequestActive, setPageRequestActive] = React.useState(initialDescriptionValidQuestions);
  const [pageRequestInactive, setPageRequestInactive] = React.useState(initialDescriptionInvalidQuestions);
  const [totalHitsQuestionnaire, setTotalHitsQuestionnaire] = React.useState(0);
  const [totalHitsActive, setTotalHitsActive] = React.useState(0);
  const [totalHitsInactive, setTotalHitsInactive] = React.useState(0);
  const [selectedQuestionnaireName, setSelectedQuestionnaireName] = React.useState("");
  const [isLoadingQuestionnaireQuestions, setIsLoadingQuestionnaireQuestions] = React.useState(true);
  const [isLoadingActiveQuestions, setIsLoadingActiveQuestions] = React.useState(true);
  const [isLoadingInactiveQuestions, setIsLoadingInactiveQuestions] = React.useState(true);

  useEffect(() => {
    if (selectedQuestionnaireName !== "") {
      setIsLoadingQuestionnaireQuestions(true);
      getQuestionnaireQuestions(selectedQuestionnaireName, pageRequestQuestionnaire).then(function(response) {
        setIsLoadingQuestionnaireQuestions(false);
        if (response !== null) {
          setTotalHitsQuestionnaire(response.responseDescription.totalHits);
          setQuestionnaireQuestionsList(response.data);
        } else {
          displayErrorMessage(toast, "Failed to get questionnaire questions from backend", JSON.stringify(response), "bottom");
        }
      });
    } else {
      setIsLoadingQuestionnaireQuestions(false);
      setQuestionnaireQuestionsList([]);
      setTotalHitsQuestionnaire(0);
    }
  }, [selectedQuestionnaireName, pageRequestQuestionnaire, toast]);  

  useEffect(() => {
    setIsLoadingActiveQuestions(true)
    setActiveQuestionsList(activeEmptyQuestionArray);
    getActiveQuestions(pageRequestActive).then(function(response) {
      setIsLoadingActiveQuestions(false)
      if (response !== null) {
        setTotalHitsActive(response.responseDescription.totalHits);
        setActiveQuestionsList(response.data);
      } else {
        displayErrorMessage(toast, "Failed to get valid questions from backend", JSON.stringify(response), "bottom");
      }
    });
  }, [pageRequestActive, toast]);

  useEffect(() => {
    setIsLoadingInactiveQuestions(true)
    setInactiveQuestionsList(inactiveEmptyQuestionArray)
    getInactiveQuestions(pageRequestInactive).then(function(response) {
      setIsLoadingInactiveQuestions(false)
      if (response !== null) {
        setTotalHitsInactive(response.responseDescription.totalHits);
        setInactiveQuestionsList(response.data);
      } else {
        displayErrorMessage(toast, "Failed to get invalid questions from backend", JSON.stringify(response), "bottom");
      }
    });

  }, [pageRequestInactive, toast]);

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={ReactLink} to="/questionnaires">
              <Text>Questionnaires</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Tabs variant='enclosed' >
        <TabList>
          <Tab ml={4} pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Questions</Tab>
          <Tab pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Management</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack
              divider={<StackDivider borderColor='gray.200' />}
              spacing={5}
              align='stretch'
            >
              <HStack spacing="2%">
                <Box>
                  {
                    QuestionnaireSelectComponent(toast,
                                                 questionnairesLoadSwitch,
                                                 questionnaireList,
                                                 setQuestionnairesLoadSwitch,
                                                 setQuestionnaireList,
                                                 setSelectedQuestionnaireName)
                  }
                </Box>
              </HStack>

              {
                QuestionnaireQuestions(toast,
                                       history,
                                       selectedQuestionnaireName,
                                       questionnaireQuestionsList,
                                       activeQuestionsList,
                                       inactiveQuestionsList,
                                       pageRequestQuestionnaire,
                                       pageRequestActive,
                                       pageRequestInactive,
                                       totalHitsQuestionnaire,
                                       totalHitsActive,
                                       totalHitsInactive,
                                       isLoadingQuestionnaireQuestions,
                                       isLoadingActiveQuestions,
                                       isLoadingInactiveQuestions,
                                       setQuestionnaireQuestionsList,
                                       setActiveQuestionsList,
                                       setInactiveQuestionsList,
                                       setPageRequestQuestionnaire,
                                       setPageRequestActive,
                                       setPageRequestInactive,
                                       setTotalHitsQuestionnaire,
                                       setTotalHitsActive,
                                       setTotalHitsInactive)
              }
            </VStack>
          </TabPanel>

          <TabPanel>
            {
              QuestionnaireManagement(toast, setQuestionnaireList)
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
