import { searchUserByPattern as searchAPI } from "../api/UserHandlingService";
import { PageRequest } from "../types/ApiTypes";
import { User } from "../types/UserTypes";
import { setLoading } from '../actions/loadingActions';
import { AppDispatch } from '../store/index';

const userSearchByPattern = (
  pattern: string,
  pageRequest: PageRequest,
  setTotalHits: React.Dispatch<React.SetStateAction<number>>,
  setUsersList: React.Dispatch<React.SetStateAction<User[]>>,
  toast: Function,
  additionalFilters: { [key: string]: any } = {} 
) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  try {
    let params = {
      page: pageRequest ? pageRequest.page : 0,
      size: pageRequest ? pageRequest.pageSize : 50,
      pattern: pattern,
      ...additionalFilters 
    };
    const response = await searchAPI(params);
    if (response) {
      setTotalHits(response.responseDescription.totalHits);
      setUsersList(response.data);
      dispatch(setLoading(false));
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast({
      title: "Failed to get Users from backend",
      position: "bottom",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};

export default userSearchByPattern;
