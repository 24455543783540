import {authCredentialsHeader} from "./AuthHeader";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/signup";


export const requestForgotPassword = (email: string) => {
    const params = new URLSearchParams();
    params.append('email', email);

    return axios.post(API_URL + "/forgotPassword", params, authCredentialsHeader());
};

