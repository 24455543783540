import * as React from "react";
import {
  useToast,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  Text,
  Box,
  HStack,
  VStack,
  StackDivider,
  Button,
  Link,
  BreadcrumbLink
} from "@chakra-ui/react";
import {
  downloadQuestionnaireCsv as downloadQuestionnaireCsvApi,
} from "../../api/QuestionnaireHandlingService";
import { QuestionnaireSelectComponent } from "../questionnaires/QuestionnaireComponents";
import { downloadCsvFile } from "../../utils/CsvAid";
import { displayErrorMessage } from "../../utils/PopUpMessage";

export const ReportQuestionnaire = () => {
  const toast = useToast();

  const [questionnaireList, setQuestionnaireList] = React.useState<string[]>([]);

  const [selectedQuestionnaireName, setSelectedQuestionnaireName] = React.useState("");

  const [questionnairesLoadSwitch, setQuestionnairesLoadSwitch] = React.useState(0);

  const [csvButtonSwitch, setCsvButtonSwitch] = React.useState(0);

  const downloadQuestionnaireCsv = () => {
    // Turn on loading spinning:
    setCsvButtonSwitch(1);
    downloadQuestionnaireCsvApi(selectedQuestionnaireName).then(function(response) {
      if (response !== null) {
        downloadCsvFile(response, 'completed_questionnaire.csv');
        // Turn off loading spinning:
        setCsvButtonSwitch(0);
      } else {
        displayErrorMessage(toast, "Failed to get questionnaires from backend", JSON.stringify(response), "bottom");
      }
    });
  }

  const getCsvSelectComponent = () => {
    return QuestionnaireSelectComponent(toast,
                                        questionnairesLoadSwitch,
                                        questionnaireList,
                                        setQuestionnairesLoadSwitch,
                                        setQuestionnaireList,
                                        setSelectedQuestionnaireName);
  }

  const getCsvButtonComponent = () => {
    if (csvButtonSwitch === 0) {
      return (
        <Button
          ml="7%"
          onClick={() => downloadQuestionnaireCsv()}
          bg="primary.500"
          color="white"
          variant="solid"
          size="sm"
          isDisabled={selectedQuestionnaireName === ""}>
          Download CSV
        </Button>
      );
    } else if (csvButtonSwitch === 1) {
      return (<Button isLoading loadingText='Processing' colorScheme='teal' variant='outline'></Button>);
    }
  }

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
          <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
              <BreadcrumbItem color="gostation.500" isCurrentPage>
                  <BreadcrumbLink as={Link} to="/reportQuestionnaire">
                  <Text>Report: Users Who Completed The Questionnaire</Text>
                  </BreadcrumbLink>
              </BreadcrumbItem>
          </Breadcrumb>
      </Flex>

    <Flex pb="10%" h="90%" minH="660px" ml={6} mr={6}>
      <Box p='5'>
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={5}
          align='stretch'
        >
          <Box h='40px'>
            <HStack spacing="2%">
              <Box>
                {getCsvSelectComponent()}
              </Box>
              <Box>
                {getCsvButtonComponent()}
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </Flex>
    </Box>
  );
}
