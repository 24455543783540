import * as React from "react";
import {
  Select
} from "@chakra-ui/react";
import { MdArrowDropDown } from 'react-icons/md'
import { BiLoader } from "react-icons/bi";
import {
  loadAllQuestionnaires as loadAllQuestionnairesApi,
} from "../../api/QuestionnaireHandlingService";
import { displayErrorMessage } from "../../utils/PopUpMessage";

const LoadAllQuestionnaires = (toast: any, setQuestionnaireList: (questionnaireList: string[]) => void) => {
  loadAllQuestionnairesApi().then(function(response) {
    if (response !== null) {
      setQuestionnaireList(response);
    } else {
      displayErrorMessage(toast, "Failed to get questionnaires from backend", JSON.stringify(response), "bottom");
    }
  });
}

const QuestionnaireSelectComponent = (toast: any,
                                      questionnairesLoadSwitch: number,
                                      questionnaireList: string[],
                                      setQuestionnairesLoadSwitch: (questionnairesLoadSwitch: number) => void,
                                      setQuestionnaireList: (questionnaireList: string[]) => void,
                                      setSelectedQuestionnaireName: (selectedQuestionnaireName: string) => void) => {
  if (questionnairesLoadSwitch === 0) {
    LoadAllQuestionnaires(toast, setQuestionnaireList);
    setQuestionnairesLoadSwitch(1);
  }

  return (
    <Select minW="240px" icon={questionnaireList.length > 0 ? <MdArrowDropDown/> : <BiLoader/>} placeholder='Select a questionnaire' isDisabled={false} onChange={(e) => setSelectedQuestionnaireName(e.target.value)}>
      {
        questionnaireList && questionnaireList.map((questionnaire: string, index: number) => {
          return (<option key={index} value={questionnaire.name}>{questionnaire.name}</option>);
        })
      }
    </Select>
  );
}

export { LoadAllQuestionnaires, QuestionnaireSelectComponent }
