import { authApiHeader } from "./AuthHeader";
import { VehicleMake, VehicleModel, VehicleUpdate } from "../types/VehicleTypes";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/vehicles";

// Vehicles:
export const getVehicleList = (params: any) => {
  return axios.get(API_URL + "/getAllVehicles", { headers: authApiHeader(), params });
};

// Makes:
export const getMakeList = (params: any) => {
  return axios.get(API_URL + "/getAllBrands", { headers: authApiHeader(), params });
};

export const searchMakes = (params: any) => {
  return axios.get(API_URL + "/searchBrands", { headers: authApiHeader(), params });
};

export const addMake = (body: VehicleMake) => {
  return axios.post(API_URL + "/addBrand", body, { headers: authApiHeader() });
};

// Models:
export const getModelList = (params: any) => {
  return axios.get(API_URL + "/getAllModels", { headers: authApiHeader(), params });
};

export const getModelListByBrand = (params: any) => {
  return axios.get(API_URL + "/getAllModelsByBrand", { headers: authApiHeader(), params });
};

export const searchModels = (params: any) => {
  return axios.get(API_URL + "/searchModels", { headers: authApiHeader(), params });
};

export const searchModelsByBrand = (params: any) => {
  return axios.get(API_URL + "/searchModelsByBrand", { headers: authApiHeader(), params });
};

export const addModel = (body: VehicleModel) => {
  return axios.post(API_URL + "/addModel", body, { headers: authApiHeader() });
};

// Csv:
export const addMakeModelCsv = (body: VehicleModel[]) => {
  return axios.post(API_URL + "/addBrandModelCsv", body, { headers: authApiHeader() });
};

// Plug Types:
export const getPlugTypes = () => {
  return axios.get(API_URL + "/getAllPlugTypes", { headers: authApiHeader() });
};

export const modifyMake = (body: VehicleUpdate) => {
  return axios.put(API_URL + "/modifyBrand", body, { headers: authApiHeader() });
};

export const modifyModel = (body: VehicleUpdate) => {
  return axios.put(API_URL + "/modifyModel", body, { headers: authApiHeader() });
};

export const modifyMakeAndModel = (body: VehicleUpdate) => {
  return axios.put(API_URL + "/modifyBrandAndModel", body, { headers: authApiHeader() });
};
