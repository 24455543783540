enum Status {
    ALL = "ALL",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    SUSPENDED = "SUSPENDED"
}

const StatusDescriptions: Record<Status, string> = {
    [Status.ALL]: "All",
    [Status.ACTIVE]: "Active",
    [Status.INACTIVE]: "Inactive",
    [Status.SUSPENDED]: "Suspended"
};

export { Status, StatusDescriptions };
