import {authApiHeader} from "./AuthHeader";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/userDataExport";

//User Data export
export const exportUserProfile = (body: string[]) => {
    return axios.post(API_URL + "/profile", body, { headers: authApiHeader()});
};

export const exportUserHistoric = (body: string[]) => {
    return axios.post(API_URL + "/historic", body, { headers: authApiHeader()});
};

export const exportUserVehicle = (body: string[]) => {
    return axios.post(API_URL + "/vehicle", body, { headers: authApiHeader()});
};

export const exportUserPromoCodes = (body: string[]) => {
    return axios.post(API_URL + "/promoCodes", body, { headers: authApiHeader()});
};

export const exportUserAds = (body: string[]) => {
    return axios.post(API_URL + "/ads", body, { headers: authApiHeader()});
};

export const exportUserStation = (params: any) => {
    return axios.get(API_URL + "/station", { headers: authApiHeader(), params});
};

export const exportUserSummary = (params: any) => {
    return axios.get(API_URL + "/summary", { headers: authApiHeader(), params });
};
