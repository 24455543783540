import {UserToken} from "../types/UserTypes";
import {AxiosRequestConfig} from "axios";

const GO_STATION_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID as string;
const GO_STATION_SECRET = process.env.REACT_APP_API_SECRET as string;

export function authApiHeader() {
    const user: UserToken = JSON.parse(localStorage.getItem('user') as string);

    if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
    } else {}
}

export function authApiHeaderMultiPartFile() {
    const user: UserToken = JSON.parse(localStorage.getItem('user') as string);

    if (user && user.accessToken) {
        let config : AxiosRequestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + user.accessToken,
                'Accept': 'application/json'
            }
        };
        return config
    } else {}
}

export function authCredentialsHeader() {
    let config : AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
        auth: {
            username: GO_STATION_CLIENT_ID,
            password: GO_STATION_SECRET,
        }
    };
    return config
}