import * as React from "react"
import {UserSession, UserToken} from '../types/UserTypes'

export const initialSession: UserSession = {
    isLoggedIn: false,
    username: "",
    userRoles: [],
    picUrl: "",
};

export const getUserSession = () => {
    const user : UserToken = JSON.parse(localStorage.getItem('user') as string);

    if (user) {
        let currentUser: UserSession = {
            isLoggedIn: true,
            username: user.username,
            userRoles: user.userRoles,
            picUrl: ""
        }
        return currentUser
    } else {
        return initialSession
    }
}

const SessionContext = React.createContext<[UserSession, (session: UserSession) => void]>([getUserSession(), () => {}]);

export const useSessionContext = () => React.useContext(SessionContext);

export const SessionContextProvider: React.FC = (props) => {

    const [sessionState, setSessionState] = React.useState(getUserSession());
    const defaultSessionContext: [UserSession, typeof setSessionState]  = [sessionState, setSessionState];

    return (
        <SessionContext.Provider value={defaultSessionContext}>
            {props.children}
        </SessionContext.Provider>
    );
}
