import {
  downloadUnifiedEvsesCsvFileByParameter,
  downloadUnifiedEvsesCsvFile,
  downloadAllPromoCodesCsvFile,
  downloadPromoCodeCsvFile,
} from "../api/ReportService";

const getErrorMessage = (error: any) => {
  return error.message + " (API: " + error?.response?.data?.apierror?.debugMessage + ")";
};

export const downloadMergedEvsesCsvFileByParameter = async (goStationEvsesCsvFile: File, roamingEvsesCsvFile: File) => {
  try {
    const response = await downloadUnifiedEvsesCsvFileByParameter(goStationEvsesCsvFile, roamingEvsesCsvFile);
    return response.data;
  } catch (error: any) {
    return getErrorMessage(error);
  }
};

export const downloadMergedEvsesCsvFile = async () => {
  try {
    const response = await downloadUnifiedEvsesCsvFile();
    return response.data;
  } catch (error: any) {
    return getErrorMessage(error);
  }
};

export const downloadPromoCodesCsv = async () => {
  try {
    const response = await downloadAllPromoCodesCsvFile();
    return response.data;
  } catch (error: any) {
    return getErrorMessage(error);
  }
};

export async function downloadPromoCodeCsv(promoCode: string) {
  let params = {
    promoCode: promoCode
  }
  try {
    const response = await downloadPromoCodeCsvFile(params);
    return response.data;
  } catch(error: any) {
    return getErrorMessage(error);
  }
};
