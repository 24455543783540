import { authApiHeader } from "./AuthHeader";
import { Questionnaire, QuestionnaireQuestionRequest } from "../types/QuestionnaireTypes";

const axios = require('axios').default;
const API_URL = process.env.REACT_APP_API_URL + "/api/questionnaires";

export const findAllQuestionnaires = () => {
  return axios.get(API_URL + "/findAllQuestionnaires", { headers: authApiHeader() });
}

export const getQuestionnaireByName = (params: any) => {
  return axios.get(API_URL + "/getQuestionnaireByName", { headers: authApiHeader(), params });
}

// Questionnaires:
export const getAllQuestionnaireQuestionsList = (params: any) => {
  return axios.get(API_URL, { headers: authApiHeader(), params });
}

export const getQuestionnaireQuestionsList = (params: any) => {
  return axios.get(API_URL + "/getQuestionsByQuestionnaire", { headers: authApiHeader(), params });
}

export const addQuestionnaire = (body: Questionnaire) => {
  return axios.post(API_URL + "/addQuestionnaire", body, { headers: authApiHeader() });
}

export const addQuestionnaireQuestions = (body: QuestionnaireQuestionRequest) => {
  return axios.post(API_URL + "/addQuestionnaireQuestions", body, { headers: authApiHeader() });
}
