enum AgeGroup {
    ALL = "ALL",
    UNDER_18 = "UNDER_18",
    BETWEEN_18_24 = "BETWEEN_18_24",
    BETWEEN_25_34 = "BETWEEN_25_34",
    BETWEEN_35_44 = "BETWEEN_35_44",
    BETWEEN_45_54 = "BETWEEN_45_54",
    BETWEEN_55_64 = "BETWEEN_55_64",
    BETWEEN_65_74 = "BETWEEN_65_74",
    OVER_75 = "OVER_75",
    PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY"
}

const AgeGroupDescriptions: Record<AgeGroup, string> = {
    [AgeGroup.ALL]: "All",
    [AgeGroup.UNDER_18]: "Under 18",
    [AgeGroup.BETWEEN_18_24]: "Between 18 and 24",
    [AgeGroup.BETWEEN_25_34]: "Between 25 and 34",
    [AgeGroup.BETWEEN_35_44]: "Between 35 and 44",
    [AgeGroup.BETWEEN_45_54]: "Between 45 and 54",
    [AgeGroup.BETWEEN_55_64]: "Between 55 and 64",
    [AgeGroup.BETWEEN_65_74]: "Between 65 and 74",
    [AgeGroup.OVER_75]: "Over 75",
    [AgeGroup.PREFER_NOT_TO_SAY]: "Prefer not to say"
}

export { AgeGroup, AgeGroupDescriptions };
