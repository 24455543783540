import React, { useState } from "react";
import {
  useToast, useDisclosure,
  Flex, Box, Button, Text, Breadcrumb, BreadcrumbLink, BreadcrumbItem, Table, TableCaption, Tr, Tbody, Td, Stack, Checkbox, Center,
  Input, Modal, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalContent, Spacer, Tfoot, Th
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { ChangeEvent, useEffect } from "react";
import {
  getMakeList,
  getModelListByBrand,
  addMake as addMakeApi,
  addModel as addModelApi,
  addMakeModelCsv,
  modifyMake as modifyMakeApi,
  modifyModel as modifyModelApi,
} from "../../api/VehicleService";
import {
  sendCsvFile,
  getVehicleBrands,
  getVehicleModels,
  addVehicleBrand,
  addVehicleModel,
  modifyVehicleBrand,
  modifyVehicleModel
} from "../../api/VehicleHandlingService";
import { CsvImportResponse, EntityListResponse } from "../../types/ApiTypes";
import { AxiosResponse } from "axios";
import { AiOutlineArrowLeft, AiFillCheckCircle, MdFileUpload } from "react-icons/all";
import { VehicleMake, VehicleModel, VehicleUpdate } from "../../types/VehicleTypes";
import { CSVReader } from "react-papaparse";
import { ParseResult } from "papaparse";
import VehicleModelUpdate from "./VehicleModelUpdate";
import { displayInfoMessage, displaySuccessMessage, displayErrorMessage } from "../../utils/PopUpMessage";

export function VehicleMakeModel() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()

  let emptyStringArray: string[] = []
  let emptyBooleanArray: boolean[] = []
  let emptyVehicleModelArray: VehicleModel[] = []

  const [makeList, setMakeList] = React.useState(emptyStringArray)
  const [selectedMake, setSelectedMake] = React.useState("")
  const [selectedModel, setSelectedModel] = React.useState("")
  const [newMake, setNewMake] = React.useState("")
  const [newModel, setNewModel] = React.useState("")
  const [modifiedMake, setModifiedMake] = React.useState("")
  const [modifiedModel, setModifiedModel] = React.useState("")
  const [modelList, setModelList] = React.useState(emptyStringArray)

  const [checkedItems, setCheckedItems] = React.useState(emptyBooleanArray)
  const [addedMake, setAddedMake] = React.useState(emptyBooleanArray)
  const [addedModel, setAddedModel] = React.useState(emptyBooleanArray)

  const [csvFile, setCsvFile] = React.useState(emptyVehicleModelArray)
  const [isImportDisabled, setIsImportDisabled] = React.useState(true)

  const [vehicleBrandForEdition, setVehicleBrandForEdition] = React.useState(0);

  const [editableVehicleModels, setEditableVehicleModels] = React.useState({});

  // #### VEHICLE BRAND ####
  const cancelVehicleBrandEdition = (index: number) => {
    setVehicleBrandForEdition(0);
  }

  const getVehicleBrandCheckbox = (vehicle: string, index: number) => {
    return (
      <Box>
        <Checkbox
          isChecked={checkedItems[index]}
          onChange={(e) => checkItemMake(e, index)}
          value={vehicle}
        >
          {vehicle}
        </Checkbox>
      </Box>

    );
  }

  const getVehicleBrandEditButton = (vehicle: string, index: number) => {
    return (
      <Box>
        <Button onClick={() => setVehicleBrandForEdition(1)} bg="primary.500" color="white" variant="solid" size="sm">Edit</Button>
      </Box>
    );
  }

  const getEditableVehicleBrand = (vehicleBrand: string, index: number) => {
    return (
      <Box>
        <Button onClick={() => cancelVehicleBrandEdition(index)} bg="Gray" color="white" variant="solid" size="sm">Cancel</Button>
        <Input
          m={1}
          type="text"
          name="modifyMake"
          placeholder={vehicleBrand}
          size="sm"
          w="60%"
          borderColor="gray.300"
          onChange={(e) => setModifiedMake(e.target.value)} />
        <Button onClick={() => modifyMake()} bg="primary.500" color="white" variant="solid" size="sm">Modify Make</Button>
      </Box>
    );
  }

  const getProperVehicleBrandComponent = (vehicleBrand: string, index: number) => {
    if (vehicleBrandForEdition === 1) {
      return getEditableVehicleBrand(vehicleBrand, index);
    } else {
      return getVehicleBrandEditButton(vehicleBrand, index);
    }
  }
  // #### VEHICLE BRAND ####

  // #### VEHICLE MODEL ####
  const setVehicleModelForEdition = (vehicleModel: string, index: number) => {
    editableVehicleModels[index] = vehicleModel;
  }

  const cancelVehicleModelEdition = (index: number) => {
    let remainingEditableModels = {};
    delete editableVehicleModels[index];
    for (let index in editableVehicleModels) {
      remainingEditableModels[index] = editableVehicleModels[index];
    }
    setEditableVehicleModels(remainingEditableModels);
  }

  const getVehicleModelCheckbox = (vehicle: string, index: number) => {
    return (
      <Box>
        <Checkbox
          onChange={(e) => {checkItemModel(e, index); setVehicleModelForEdition(e.target.value, index)}}
          value={vehicle}
        >
          {vehicle}
        </Checkbox>
      </Box>
    );
  }

  const getEditableVehicleModel = (vehicleModel: string, index: number) => {
    return (
      <Box>
        <Button onClick={() => cancelVehicleModelEdition(index)} bg="Gray" color="white" variant="solid" size="sm">Cancel</Button>
        <Input
          m={1}
          type="text"
          name="modifyModel"
          placeholder={vehicleModel}
          size="sm"
          w="60%"
          borderColor="gray.300"
          onChange={(e) => setModifiedModel(e.target.value)} />
        <Button onClick={() => modifyModel()} bg="primary.500" color="white" variant="solid" size="sm">Modify Model</Button>
      </Box>
    );
  }

  const getProperVehicleModelComponent = (vehicleModel: string, index: number) => {
    if (vehicleModel === editableVehicleModels[index]) {
      return getEditableVehicleModel(vehicleModel, index);
    } else {
      return getVehicleModelCheckbox(vehicleModel, index);
    }
  }
  // #### VEHICLE MODEL ####

  const checkItemMake = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    const makeAdded = addedMake.map(() => false);
    setAddedMake(makeAdded)
    const modelAdded = addedModel.map(() => false);
    setAddedModel(modelAdded)
    if (e.target.checked) {
      setSelectedMake(e.target.value)
      getModels(false, e.target.value)
    } else {
      setSelectedMake("")
    }
    const checkList = checkedItems.map(() => false);
    checkList[i] = e.target.checked
    setCheckedItems(checkList)
  };

  const checkItemModel = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    if (e.target.checked) {
      setSelectedModel(e.target.value)
    } else {
      setSelectedModel("")
    }
  };

  const resetAddMake = () => {
    let element = document.getElementsByName("addMake")[0] as HTMLInputElement
    element.value = ""
    setNewMake("")
  };

  const resetAddModel = () => {
    let element = document.getElementsByName("addModel")[0] as HTMLInputElement
    element.value = ""
    setNewModel("")
  };

  const resetModifiedMake = () => {
    let element = document.getElementsByName("modifyMake")[0] as HTMLInputElement
    element.value = ""
    setModifiedMake("")
  };

  const resetModifiedModel = () => {
    let element = document.getElementsByName("modifyModel")[0] as HTMLInputElement
    element.value = ""
    setModifiedModel("")
  };

  const addMake = () => {
    addVehicleBrand(newMake).then(function(response) {
      if (response === true) {
        getMakes(true);
      } else {
        displayErrorMessage(toast, "Failed to add the new make", JSON.stringify(response), "bottom");
      }
    });
  }

  const addModel = () => {
    addVehicleModel(selectedMake, newModel).then(function(response) {
      if (response === true) {
        getModels(true, selectedMake)
      } else {
        displayErrorMessage(toast, "Failed to add the new model", JSON.stringify(response), "bottom");
      }
    });
  }

  const modifyMake = () => {
    modifyVehicleBrand(selectedMake, modifiedMake).then(function(response) {
      if (response === true) {
        getMakes(true);
      } else {
        displayErrorMessage(toast, "Failed to update make", JSON.stringify(response), "bottom");
      }
    });
  }

  const modifyModel = () => {
    modifyVehicleModel(selectedMake, selectedModel, modifiedModel).then(function(response) {
      if (response === true) {
        getModels(true, selectedMake);
      } else {
        displayErrorMessage(toast, "Failed to update model", JSON.stringify(response), "bottom");
      }
    });
  }

  const getMakes = (newMakeAdded: boolean) => {
    getVehicleBrands(newMakeAdded).then(function(response) {
      if (response) {
        const checked = response.map(() => false);
        setCheckedItems(checked);
        setSelectedMake("");
        const added = response.map(() => false);
        if (newMakeAdded) {
          added[response.indexOf(newMake)] = true;
        }
        setAddedMake(added);
        setMakeList(response);
      } else {
        displayErrorMessage(toast, "Failed to get Models from backend", JSON.stringify(response), "bottom");
      }
    });
  }

  const getModels = (newModelAdded: boolean, make: string) => {
    getVehicleModels(make).then(function(response) {
      if (response) {
        const added = response.map(() => false);
        if (newModelAdded) {
          added[response.indexOf(newModel)] = true
        }
        setAddedModel(added)
        setModelList(response);
      } else {
        displayErrorMessage(toast, "Failed to get Models from backend", JSON.stringify(response), "bottom");
      }
    });
  }

  const handleOnDrop = (data: Array<ParseResult<any>>) => {
    let body: VehicleModel[] = []
    data.forEach((row: ParseResult<any>) => {
      //handle parsing errors
      if (!row.errors || !(row.errors.length > 0)) {
        //skip bad rows
        if (!row.meta.aborted && !row.meta.truncated) {
          let rowData: string[] = row.data
          //skip headers
          if (rowData[0] !== 'Make' && rowData[1] !== 'Model') {
            let vehicle: VehicleModel = {
              brand: rowData[0],
              model: rowData[1]
            }
            body.push(vehicle);
          }
        }
      }
    })
    setIsImportDisabled(false)
    setCsvFile(body)
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    setIsImportDisabled(true)
    setCsvFile([])
  };

  const handleOnRemoveFile = (data: any) => {
    setIsImportDisabled(true)
    setCsvFile([])
  };

  const sendFile = () => {
    sendCsvFile(csvFile).then(function(response) {
      if (response) {
        displaySuccessMessage(toast, "Upload Successful!", response.brandImported + " Makes and " + response.modelImported + " Models added to the database", "bottom");
        onClose()
        getMakes(false)
      } else {
        displayErrorMessage(toast, "Failed to upload CSV file", JSON.stringify(response), "bottom");
      }
    });
  }

  useEffect(() => {
    let isMounted = true; // this flag denote mount status
    if (isMounted) {
      getMakes(false)
    }
    return () => { isMounted = false };
    // eslint-disable-next-line
  }, []);

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb fontWeight="bold" fontSize="lg">
          <BreadcrumbItem color="gostation.100">
            <BreadcrumbLink as={Link} to="/vehicles">
              <Text>Vehicles</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gostation.500">
            <BreadcrumbLink as={Link} to="/vehicles/makeModel">
              <Text>Makes & Models</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex h="8%" ml={6} pl={4} mr={6} pr={4} alignItems="center" bg="white">
        <Button as={Link} leftIcon={<AiOutlineArrowLeft />} to="/vehicles" bg="gray.300" color="gostation.500" variant="solid" size="sm">BACK TO VEHICLES</Button>
        <Button ml="auto" leftIcon={<MdFileUpload />} colorScheme="gostation" variant="solid" size="sm" onClick={onOpen}>UPLOAD CSV</Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color="gostation.500">Upload CSV</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack direction="column">
                <Text fontSize="xs">Upload a bulk list of Makes and Models</Text>
                <CSVReader
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  addRemoveButton
                  onRemoveFile={handleOnRemoveFile}
                >
                  <Text>Drop CSV file here or click to upload.</Text>
                </CSVReader>
                <Stack direction="row">
                  <Spacer />
                  <Button isDisabled={isImportDisabled} maxW="40%" leftIcon={<MdFileUpload />} colorScheme="gostation" variant="solid" size="sm" onClick={() => { sendFile() }}>UPLOAD CSV</Button>
                </Stack>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
      <Stack direction="row" pl={6} pr={6} spacing={4}>
        <Table variant="simple" bg="white" size="sm">
          <TableCaption placement="top" fontWeight="bold" fontSize="md" color="gostation.500" textAlign="left">Manage Makes: {selectedMake}</TableCaption>
          <Tbody>
            {makeList && makeList.map((vehicle: string, i: number) => {
              return (
                <Tr key={i}>
                  <td width="25%">
                    { getVehicleBrandCheckbox(vehicle, i) }
                  </td>
                  <td>
                    { checkedItems[i] === true ? getProperVehicleBrandComponent(vehicle, i) : "" }
                  </td>

                  {addedMake[i] && (<Td p={2}>
                    <Stack direction="row" spacing={2}>
                      <AiFillCheckCircle />
                      <Text pl={1} color="gostation.500" fontSize="sm">Your Make is now on the list!</Text>
                    </Stack>
                  </Td>)}
                </Tr>)
            })}
          </Tbody>
          <Tfoot>
            <Tr key="footMake">
              <Th colSpan={2}>
                <Input
                  m={2}
                  type="text"
                  name="addMake"
                  placeholder="Add new make"
                  size="sm"
                  w="60%"
                  borderColor="gray.300"
                  onChange={(e) => setNewMake(e.target.value)}
                />
                <Button onClick={() => addMake()} bg="primary.500" color="white" variant="solid" size="sm">ADD</Button>
              </Th>
            </Tr>
          </Tfoot>
        </Table>
        <Table variant="simple" bg="white" size="sm">
          <TableCaption placement="top" fontWeight="bold" fontSize="md" color="gostation.500" textAlign="left">Manage Models</TableCaption>
          {checkedItems.some(item => item) && modelList ?
            (<Tbody>{(modelList.map((vehicle: string, i: number) => {
              return (
                <Tr key={i}>

                  {addedModel[i] && (<Td p={2}>
                    <Stack direction="row" spacing={2}>
                      <AiFillCheckCircle />
                      <Text pl={1} color="gostation.500" fontSize="sm">Your Model is now on the list!</Text>
                    </Stack>
                  </Td>)}

                  { getProperVehicleModelComponent(vehicle, i) }

                </Tr>)
            }))}
              <Tr key="footModel">
                <Th colSpan={2}>
                  <Input
                    m={2}
                    type="text"
                    name="addModel"
                    placeholder="Add new model"
                    size="sm"
                    w="60%"
                    borderColor="gray.300"
                    onChange={(e) => setNewModel(e.target.value)}
                  />
                  <Button onClick={() => addModel()} bg="primary.500" color="white" variant="solid" size="sm">ADD</Button>
                </Th>
              </Tr>
            </Tbody>)
            : (<Tbody bg="background.primary"><Tr key="noMakeSelected"><Td><Center minH={100} borderWidth={1} borderStyle="dashed" bg="background.primary" color="gray" fontSize="xl">Select a Make first to view Models</Center></Td></Tr></Tbody>)}
        </Table>
      </Stack>
    </Box>
  );
}
