import React from "react"
import { createIcon } from "@chakra-ui/icons"

export const AdsIcon = createIcon({
  displayName: "AdsIcon",
  viewBox: "0 0 20 20",
  path: (
      <path
          fill="currentColor"
          d="M15 9.167v1.666h3.333V9.167H15zM13.333 14.675c.8.592 1.842 1.375 2.667 1.992.333-.442.667-.892 1-1.334-.825-.616-1.867-1.4-2.667-2-.333.45-.666.9-1 1.342zM17 4.667c-.333-.442-.667-.892-1-1.334-.825.617-1.867 1.4-2.667 2 .334.442.667.892 1 1.334.8-.6 1.842-1.375 2.667-2zM3.333 7.5c-.916 0-1.666.75-1.666 1.667v1.666c0 .917.75 1.667 1.666 1.667h.834v3.333h1.666V12.5h.834l4.166 2.5V5L6.667 7.5H3.333zm4.192 1.425l1.642-.983v4.116l-1.642-.983-.4-.242H3.333V9.167H7.125l.4-.242zM12.917 10a3.733 3.733 0 00-1.25-2.792v5.575A3.702 3.702 0 0012.917 10z"
      />
  ),
})

export const QuestionnaireIcon = createIcon({
    displayName: "QuestionnaireIcon",
    viewBox: "0 0 20 20",
    path: (
        <path
            fill="currentColor"
            d="M15 13.3929V1.60714C15 .719866 14.2801 0 13.3929 0H1.60714C.719866 0 0 .719866 0 1.60714V13.3929C0 14.2801.719866 15 1.60714 15H13.3929C14.2801 15 15 14.2801 15 13.3929Zm-13.39286 0V1.60714H13.3929V13.3929H1.60714Zm1.60715-9.37504v.53571c0 .29598.23973.53572.53571.53572h4.28571c.29599 0 .53572-.23974.53572-.53572v-.53571c0-.29599-.23973-.53572-.53572-.53572H3.75c-.29598 0-.53571.23973-.53571.53572Zm0 3.21428v.53572c0 .29598.23973.53571.53571.53571h7.5c.296 0 .5357-.23973.5357-.53571v-.53572c0-.29598-.2397-.53571-.5357-.53571h-7.5c-.29598 0-.53571.23973-.53571.53571Zm0 3.21426v.5357c0 .296.23973.5358.53571.5358h2.14286c.29598 0 .53571-.2398.53571-.5358v-.5357c0-.296-.23973-.53569-.53571-.53569H3.75c-.29598 0-.53571.23969-.53571.53569Z"
        />
    ),
})

export const NotificationIcon = createIcon({
    displayName: "NotificationIcon",
    viewBox: "0 0 20 20",
    path: (
        <path
            fill="currentColor"
            d="M6.99967 16.3333c.91667 0 1.66667-.75 1.66667-1.6666H5.33301c0 .9166.75 1.6666 1.66666 1.6666Zm5.00003-5V7.16667c0-2.55833-1.3584-4.7-3.75003-5.26667V.083336h-2.5V1.9c-2.38333.56667-3.75 2.7-3.75 5.26667v4.16663L.333008 13v.8333H13.6663V13l-1.6666-1.6667Zm-1.6667.8334H3.66634V7.16667c0-2.06667 1.25833-3.75 3.33333-3.75S10.333 5.1 10.333 7.16667v5.00003Z"
        />
    ),
})

export const FaqsIcon = createIcon({
  displayName: "FaqsIcon",
  viewBox: "0 0 20 20",
  path: (
      <path
          fill="currentColor"
          d="M12.5 3.333v5.834H4.308l-.975.975V3.333H12.5zm.833-1.666H2.5a.836.836 0 00-.833.833v11.667L5 10.833h8.333a.836.836 0 00.834-.833V2.5a.836.836 0 00-.834-.833zM17.5 5h-1.667v7.5H5v1.667c0 .458.375.833.833.833H15l3.333 3.333v-12.5A.836.836 0 0017.5 5z"
      />
  ),
})

export const RewardsIcon = createIcon({
  displayName: "RewardsIcon",
  viewBox: "0 0 20 20",
  path: (
      <path
          fill="currentColor"
          d="M16.667 5.5H14.85c.092-.258.15-.542.15-.833a2.497 2.497 0 00-4.583-1.375L10 3.85l-.417-.567A2.51 2.51 0 007.5 2.167a2.497 2.497 0 00-2.5 2.5c0 .291.058.575.15.833H3.333c-.925 0-1.658.742-1.658 1.667l-.008 9.166A1.66 1.66 0 003.333 18h13.334a1.66 1.66 0 001.666-1.667V7.167A1.66 1.66 0 0016.667 5.5zM12.5 3.833c.458 0 .833.375.833.834a.836.836 0 01-.833.833.836.836 0 01-.833-.833c0-.459.375-.834.833-.834zm-5 0c.458 0 .833.375.833.834A.836.836 0 017.5 5.5a.836.836 0 01-.833-.833c0-.459.375-.834.833-.834zm9.167 12.5H3.333v-1.666h13.334v1.666zm0-4.166H3.333v-5h4.234L5.833 9.525l1.35.975 1.984-2.7L10 6.667l.833 1.133 1.984 2.7 1.35-.975-1.734-2.358h4.234v5z"
      />
  ),
})

export const PromoCodesIcon = createIcon({
    displayName: "PromoCodesIcon",
    viewBox: "0 0 24 24",
    path: (
        <path
          fill="currentColor"
          d="M14.8 8L16 9.2 9.2 16 8 14.8 14.8 8M4 4h16c1.11 0 2 .89 2 2v4a2 2 0 100 4v4c0 1.11-.89 2-2 2H4a2 2 0 01-2-2v-4c1.11 0 2-.89 2-2a2 2 0 00-2-2V6a2 2 0 012-2m0 2v2.54a3.994 3.994 0 010 6.92V18h16v-2.54a3.994 3.994 0 010-6.92V6H4m5.5 2c.83 0 1.5.67 1.5 1.5S10.33 11 9.5 11 8 10.33 8 9.5 8.67 8 9.5 8m5 5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5z"
        />
    ),
});


export const UsersIcon = createIcon({
  displayName: "UsersIcon",
  viewBox: "0 0 20 20",
  path: (
      <path
          fill="currentColor"
          d="M7.5 11.458c-1.95 0-5.833.975-5.833 2.917v1.458h11.666v-1.458c0-1.942-3.883-2.917-5.833-2.917zm-3.883 2.709c.7-.484 2.391-1.042 3.883-1.042s3.183.558 3.883 1.042H3.617zM7.5 10a2.92 2.92 0 002.917-2.917A2.92 2.92 0 007.5 4.167a2.92 2.92 0 00-2.917 2.916A2.92 2.92 0 007.5 10zm0-4.167c.692 0 1.25.559 1.25 1.25 0 .692-.558 1.25-1.25 1.25s-1.25-.558-1.25-1.25c0-.691.558-1.25 1.25-1.25zm5.867 5.675c.966.7 1.633 1.634 1.633 2.867v1.458h3.333v-1.458c0-1.683-2.916-2.642-4.966-2.867zM12.5 10a2.92 2.92 0 002.917-2.917A2.92 2.92 0 0012.5 4.167c-.45 0-.867.108-1.25.291.525.742.833 1.65.833 2.625 0 .975-.308 1.884-.833 2.625.383.184.8.292 1.25.292z"
      />
  ),
})

export const VehiclesIcon = createIcon({
  displayName: "VehiclesIcon",
  viewBox: "0 0 20 20",
  path: (
      <path
          fill="currentColor"
          d="M15.767 5.508a1.245 1.245 0 00-1.184-.841H5.417c-.55 0-1.009.35-1.184.841L2.5 10.5v6.667c0 .458.375.833.833.833h.834A.836.836 0 005 17.167v-.834h10v.834c0 .458.375.833.833.833h.834a.836.836 0 00.833-.833V10.5l-1.733-4.992zm-10.059.825h8.575l.9 2.592H4.808l.9-2.592zm10.125 8.334H4.167V10.5h11.666v4.167zm-9.583-.834a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm7.5 0a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
      />
  ),
})

export const ChangeStatusIcon = createIcon({
    displayName: "PromoCodesIcon",
    viewBox: "0 0 20 20",
    path: (
        <path
            fill="currentColor"
            d="M9.99992 5.00004v2.5l3.33338-3.33333L9.99992.833374V3.33337c-3.68334 0-6.66667 2.98334-6.66667 6.66663 0 1.3084.38334 2.525 1.03334 3.55l1.21666-1.2166c-.375-.6917-.58333-1.4917-.58333-2.3334 0-2.75829 2.24167-4.99996 5-4.99996zm5.63338 1.45l-1.2167 1.21667c.3666.7.5833 1.49166.5833 2.33329 0 2.7584-2.2416 5-4.99998 5v-2.5l-3.33334 3.3334 3.33334 3.3333v-2.5c3.68338 0 6.66668-2.9833 6.66668-6.6667 0-1.30829-.3833-2.52496-1.0333-3.54996z"
        />
    ),
})

export const ChangePasswordIcon = createIcon({
    displayName: "PromoCodesIcon",
    viewBox: "0 0 20 20",
    path: (
        <path
            fill="currentColor"
            d="M14.9999 6.66671h-.8333V5.00004c0-2.3-1.8667-4.166666-4.16668-4.166666-2.3 0-4.16667 1.866666-4.16667 4.166666v1.66667h-.83333c-.91667 0-1.66667.75-1.66667 1.66666v8.33333c0 .9167.75 1.6667 1.66667 1.6667h9.99998c.9167 0 1.6667-.75 1.6667-1.6667V8.33337c0-.91666-.75-1.66666-1.6667-1.66666zM7.49992 5.00004c0-1.38333 1.11666-2.5 2.5-2.5 1.38338 0 2.49998 1.11667 2.49998 2.5v1.66667H7.49992V5.00004zM14.9999 16.6667H4.99992V8.33337h9.99998v8.33333zm-4.99998-2.5c.91668 0 1.66668-.75 1.66668-1.6667 0-.9166-.75-1.6666-1.66668-1.6666-.91667 0-1.66667.75-1.66667 1.6666 0 .9167.75 1.6667 1.66667 1.6667z"
        />
    ),
})

export const EmployerIcon = createIcon({
  displayName: "EmployerIcon",
  viewBox: "0 0 20 20",
  path: (
      <path
          fill="currentColor"
          d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"
      />
  ),
})

export const ReportIcon = createIcon({
  displayName: "ReportIcon",
  viewBox: "0 0 20 20",
  path: (
      <path
          fill="currentColor"
          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
      />
  ),
})
