import * as React from "react"
import {
  useToast,
  Flex,
  Box,
  Input,
  Button,
  Text,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  InputRightElement,
  InputGroup,
  Stack,
  Spacer,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Center,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  useDisclosure,
  Select,
  VStack,
  HStack,
  Heading, Divider, Checkbox, ModalFooter, FormErrorMessage, FormControl,
} from "@chakra-ui/react"
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Link, useRouteMatch } from "react-router-dom"
import { useEffect, useState } from "react";
import userSearchByPattern from "../../helpers/userSearchHelper";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import { User, UserLocationExport } from "../../types/UserTypes";
import {
  changeSize,
  initialDescriptionShort,
  isNextPage,
  isPrevPage,
  prevPage,
  nextPage
} from "../../utils/pagination";
import { BiFilter } from "react-icons/bi";
import { AxiosResponse } from "axios";
import { CSVDownloader, CSVReader, jsonToCSV } from "react-papaparse";
import { MdFileDownload, MdFileUpload } from "react-icons/all";
import LoadingOverlay from "react-loading-overlay";
import {
  UserDataExportAds,
  UserDataExportHistoric, UserDataExportLocation,
  UserDataExportProfile,
  UserDataExportPromoCodes,
  UserDataExportVehicle
} from "../../types/UserExportTypes";
import {
  exportUserAds,
  exportUserHistoric,
  exportUserProfile,
  exportUserPromoCodes, exportUserStation,
  exportUserVehicle
} from "../../api/UserExportService";
import CustomTable from "../../components/CustomTable";
import { ParseResult } from "papaparse";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { debounce } from 'lodash';
import { PageRequest } from "../../types/ApiTypes";

export function ReportUser() {
  const toast = useToast();
  let match = useRouteMatch();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenList,
    onOpen: onOpenList,
    onClose: onCloseList
  } = useDisclosure()
  const [isActiveOverlay, setIsActiveOverlay] = useState(false);

  let initial: User[] = []
  let initialUsers: User[] = []
  let initialSelectedUsers: User[] = []
  let initialCSVUsers: User[] = []

  const [users, setUsers] = useState(initial);
  const [selectedUsersList, setSelectedUsersList] = React.useState(initialSelectedUsers)

  const [csvData, setCsvData] = React.useState("")
  const [usersList, setUsersList] = React.useState(initialUsers)
  const [pageRequest, setPageRequest] = React.useState(initialDescriptionShort)
  const [totalHits, setTotalHits] = React.useState(0)
  const [dataType, setDataType] = React.useState("")
  const [csvFile, setCsvFile] = React.useState(initialCSVUsers)
  const [isImportDisabled, setIsImportDisabled] = React.useState(true)

  const [fileReady, setFileReady] = useState(false);
  const [evse, setEvse] = useState("");

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const schema = yup.object().shape({
    evse: yup.string().min(1).required()
  });

  const { register, formState: { errors } } = useForm<UserLocationExport>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const addUserList = (checked: boolean, user: User) => {
    if (checked) {
      let temp = selectedUsersList
      temp.push(user)
      setSelectedUsersList(temp)
    } else {
      let newList = selectedUsersList.filter((item: User) => item.username !== user.username);
      setSelectedUsersList(newList)
    }
  };

  const addUserCSV = () => {
    addUsersInternal(csvFile)
    setCsvFile([])
    onCloseList()
  };

  const addUsers = () => {
    addUsersInternal(selectedUsersList)
    setSelectedUsersList([])
    setCurrentFilter("")
    onClose();
  };

  const addUsersInternal = (usersInternal: User[]) => {
    let newList
    if (users.length > 0) {
      newList = usersInternal.filter((item: User) => !users?.some(user => item.username === user.username));
    } else {
      newList = usersInternal
    }
    let temp = users
    newList.forEach((item: User) => {
      temp.push(item);
    })
    setUsers(temp);
  };

  const setExportType = (type: string) => {
    clearState();
    setDataType(type);
  };

  const clearState = () => {
    setUsers([]);
    setSelectedUsersList([]);
    setUsersList([]);
    setCsvFile([]);
    setCsvData("");

    setTotalHits(0);
    setPageRequest({ ...initialDescriptionShort });

    setDataType("")
    setIsImportDisabled(true)
    setFileReady(false);

    setEvse("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  const generateFile = () => {
    let body = users.map(user => user.username)
    switch (dataType) {
      case "HISTORIC":
        setIsActiveOverlay(true)
        exportUserHistoric(body).then((response: AxiosResponse) => {
          if ([200, 201].includes(response.status)) {
            let exportData: UserDataExportHistoric[] = response.data
            let config = {
              header: false
            }
            let arrayHeader = ["Username", "First Name", "Last Name", "Email", "Member Since", "Last Charge",
              "# Charges YTD", "# Charges 1 Year", "# Charges 6 month", "# Charges 3 Month", "# Charges 1 Month",
              "Total Charge Time (Min)", "Total Energy Consumed (kw)", "Total Transaction Amount (USD)", "Total Parking Idle Fees Assessed",
              "Avg Charge Time Per Session (Min)", "Avg Energy Consumed Per Session (kw)", "Avg Transaction Amount Per Session (USD)"];
            let header = arrayHeader.join(",") + '\n';
            let file = header + jsonToCSV(exportData, config)
            setCsvData(file)
            setFileReady(true);
            setIsActiveOverlay(false)
          } else {
            setIsActiveOverlay(false)
            const error = (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
          }
        }).catch((error: any) => {
          setIsActiveOverlay(false)
          toast({
            title: "Failed to get users export data",
            position: "bottom",
            description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        })
        break;
      case "VEHICLE":
        setIsActiveOverlay(true)
        exportUserVehicle(body).then((response: AxiosResponse) => {
          if ([200, 201].includes(response.status)) {
            let exportData: UserDataExportVehicle[] = response.data
            let config = {
              header: false
            }
            let arrayHeader = ["Username", "First Name", "Last Name", "Email", "Vehicle Make", "Vehicle Model",
              "Connector Type", "Vehicle Year"];
            let header = arrayHeader.join(",") + '\n';
            let file = header + jsonToCSV(exportData, config)
            setCsvData(file)
            setFileReady(true);
            setIsActiveOverlay(false)
          } else {
            setIsActiveOverlay(false)
            const error = (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
          }
        }).catch((error: any) => {
          setIsActiveOverlay(false)
          toast({
            title: "Failed to get users export data",
            position: "bottom",
            description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        })
        break;
      case "PROMO_CODES":
        setIsActiveOverlay(true)
        exportUserPromoCodes(body).then((response: AxiosResponse) => {
          if ([200, 201].includes(response.status)) {
            let exportData: UserDataExportPromoCodes[] = response.data
            let config = {
              header: false
            }
            let arrayHeader = ["Username", "First Name", "Last Name", "Email", "Promo Codes Received", "Promo Codes Used",
              "# Promo Codes used on L2", "# Promo Codes used on L3", "# Expired Before Use", "Total $ Saved"];
            let header = arrayHeader.join(",") + '\n';
            let file = header + jsonToCSV(exportData, config)
            setCsvData(file)
            setFileReady(true);
            setIsActiveOverlay(false)
          } else {
            setIsActiveOverlay(false)
            const error = (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
          }
        }).catch((error: any) => {
          setIsActiveOverlay(false)
          toast({
            title: "Failed to get users export data",
            position: "bottom",
            description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        })
        break;
      case "ADS":
        setIsActiveOverlay(true)
        exportUserAds(body).then((response: AxiosResponse) => {
          if ([200, 201].includes(response.status)) {
            let exportData: UserDataExportAds[] = response.data
            let config = {
              header: false
            }
            let arrayHeader = ["Username", "First Name", "Last Name", "Email", "# Ads Served", "#Ads Completed", "Preferred Ad Category"];
            let header = arrayHeader.join(",") + '\n';
            let file = header + jsonToCSV(exportData, config)
            setCsvData(file)
            setFileReady(true);
            setIsActiveOverlay(false)
          } else {
            setIsActiveOverlay(false)
            const error = (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
          }
        }).catch((error: any) => {
          setIsActiveOverlay(false)
          toast({
            title: "Failed to get users export data",
            position: "bottom",
            description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        })
        break;
      case "PROFILE":
        setIsActiveOverlay(true)
        exportUserProfile(body).then((response: AxiosResponse) => {
          if ([200, 201].includes(response.status)) {
            let exportData: UserDataExportProfile[] = response.data
            let config = {
              header: false
            }
            let arrayHeader = ["Username", "First Name", "Last Name", "Email", "Zip Code", "Phone",
              "Gender", "Age Group", "How they Heard About Us", "Completed Sign Up",
              "Started Questionnaire", "Completed Questionnaire"];
            let header = arrayHeader.join(",") + '\n';
            let file = header + jsonToCSV(exportData, config)
            setCsvData(file)
            setFileReady(true);
            setIsActiveOverlay(false)
          } else {
            setIsActiveOverlay(false)
            const error = (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
          }
        }).catch((error: any) => {
          setIsActiveOverlay(false)
          toast({
            title: "Failed to get users export data",
            position: "bottom",
            description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        })
        break;
      case "LOCATION":
        if (evse) {
          setIsActiveOverlay(true)
          let paramsLocation = {
            physicalReference: evse,
            fromDate: fromDate,
            toDate: toDate
          }
          exportUserStation(paramsLocation).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
              let exportData: UserDataExportLocation[] = response.data
              let config = {
                header: false
              }
              let arrayHeader = ["Username", "First Name", "Last Name", "Email", "Last Charge", "# Charges",
                "Total Charge Time", "Total Energy Consumed", "Total Transaction Amount", "Total Parking Idle Fees",
                "Average Charge Time Per Session", "Average Energy Consumed Per Session", "Average Transaction Amount Per Session"];
              let header = arrayHeader.join(",") + '\n';
              let file = header + jsonToCSV(exportData, config)
              setCsvData(file)
              setFileReady(true);
              setIsActiveOverlay(false)
            } else {
              setIsActiveOverlay(false)
              const error = (response.data && response.data.message) || response.statusText;
              return Promise.reject(error);
            }
          }).catch((error: any) => {
            setIsActiveOverlay(false)
            toast({
              title: "Failed to get users export data",
              position: "bottom",
              description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
              status: "error",
              duration: 5000,
              isClosable: true,
            })
          })
        } else {
          toast({
            title: "No Physical Reference selected",
            position: "bottom",
            description: "Please specify a location",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        }
        break;
    }
  };

  const [currentFilter, setCurrentFilter] = useState("");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const isFirstOpenRef = React.useRef(true);
  const previousFilterRef = React.useRef<string | undefined>(undefined);
  const previousPageRequestRef = React.useRef<PageRequest | undefined>(undefined);

  const searchUser = React.useCallback((pattern: string) => {
      if ((pattern !== "") && (currentFilter !== pattern)) {
          setPageRequest(prevState => ({ ...prevState, page: 0 }));
      } else {
          setCurrentFilter(pattern);
      }
      dispatch(userSearchByPattern(pattern, pageRequest, setTotalHits, setUsersList, toast));
  }, [dispatch, pageRequest, setTotalHits, setUsersList, toast, currentFilter]);

  const debouncedSearchUser = React.useCallback(
      debounce((pattern: string) => {
          searchUser(pattern);
      }, 500),
      [searchUser]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearchUser(e.target.value);
  };

  useEffect(() => {
      if (isOpen) {
          if (isFirstOpenRef.current || currentFilter !== previousFilterRef.current || pageRequest.page !== previousPageRequestRef.current?.page) {
              debouncedSearchUser(currentFilter);
          }
          isFirstOpenRef.current = false;
      }
      previousFilterRef.current = currentFilter;
      previousPageRequestRef.current = pageRequest;

      return () => {
          debouncedSearchUser.cancel();
      };
  }, [currentFilter, debouncedSearchUser, isOpen]);

  const handleOnDrop = (data: Array<ParseResult<any>>) => {
    let body: User[] = []
    data.forEach((row: ParseResult<any>) => {
      //handle parsing errors
      if (!row.errors || !(row.errors.length > 0)) {
        //skip bad rows
        if (!row.meta.aborted && !row.meta.truncated) {
          let rowData: string[] = row.data
          //skip headers
          if (rowData[0] !== 'Username') {
            let user: User = {
              username: rowData[0],
              email: rowData[1]
            }
            body.push(user);
          }
        }
      }
    })
    setIsImportDisabled(false)
    setCsvFile(body)
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    setIsImportDisabled(true)
    setCsvFile([])
  };

  const handleOnRemoveFile = (data: any) => {
    setIsImportDisabled(true)
    setCsvFile([])
  };

  const columnsData = [
    {
      Header: "USERNAME",
      accessor: "username"
    },
    {
      Header: "FIRST NAME",
      accessor: "profile.firstName"
    },
    {
      Header: "LAST NAME",
      accessor: "profile.lastName"
    }
  ];

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
          <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
              <BreadcrumbItem color="gostation.500" isCurrentPage>
                  <BreadcrumbLink as={Link} to="/reportUser">
                  <Text>Report: Users Data Export</Text>
                  </BreadcrumbLink>
              </BreadcrumbItem>
          </Breadcrumb>
      </Flex>
      <Flex w="96%" h="90%" minH="500px" bg="white" ml={6} mr={6}>
        <LoadingOverlay active={isActiveOverlay} spinner text='Creating File...'>
          <VStack>
            <HStack alignItems="flex-start">
              <VStack minH="300px" minW="400px" pl={4} pr={4} alignItems="left">
                <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Export Type</Heading>
                <Text fontSize="xs" color="gray">Choose what data do you want to export</Text>
                <Select placeholder='Select Option' isRequired value={dataType} onChange={(e) => setExportType(e.target.value)}>
                  <option value="PROFILE">Profile Data</option>
                  <option value="HISTORIC">Charge History Data</option>
                  <option value="VEHICLE">Vehicle Data</option>
                  <option value="PROMO_CODES">Promo Codes Data</option>
                  <option value="ADS">Ads Data</option>
                  <option value="LOCATION">Location charge Data</option>
                </Select>
              </VStack>
              <Center h="300px">
                <Divider orientation="vertical" />
              </Center>
              <VStack minH="300px" minW="600px" pl={4} pr={4} alignItems="left">
                {dataType && dataType !== "LOCATION" && <Box>
                  <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Target Users</Heading>
                  <Text pl={20} fontSize="xs" color="gray">Specify Users</Text>
                  <HStack spacing={5}>
                    <Button onClick={onOpen} colorScheme="gostation" bg="gostation.100" variant="solid" size="md">SELECT USER/S</Button>
                    <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none" size="lg">
                      <ModalOverlay />
                      <ModalContent minH="450px" width="fit-content" minW="950px">
                        <ModalHeader color="gostation.500">Select Users to Export</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
                            <InputGroup size="sm" w="60%" >
                              <Input
                                type="search"
                                name="search"
                                placeholder="Search users by username, phone number or email"
                                onChange={handleInputChange}
                              />
                              <InputRightElement width="2.5rem">
                                <Icon as={BiFilter} />
                              </InputRightElement>
                            </InputGroup>
                          </Flex>
                          <Box borderBottomWidth="1px">
                            <Stack direction="row" spacing={1} pt={3}>
                              <Menu>
                                <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
                                  Users/page: {pageRequest.pageSize}
                                </MenuButton>
                                <MenuList>
                                  <MenuItem value={5} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>5</MenuItem>
                                  <MenuItem value={10} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>10</MenuItem>
                                  <MenuItem value={15} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>15</MenuItem>
                                </MenuList>
                              </Menu>
                              <Spacer />
                              <IconButton
                                variant="outline"
                                aria-label="Previous page"
                                isDisabled={!isPrevPage(pageRequest, totalHits)}
                                boxSize={6}
                                icon={<ChevronLeftIcon />}
                                onClick={() => { prevPage(pageRequest, setPageRequest) }}
                              />
                              <Text pl={1} fontSize="sm">
                                {(pageRequest.pageSize * pageRequest.page)}-{Math.min(pageRequest.pageSize * (pageRequest.page + 1), totalHits)} of {totalHits}
                              </Text>
                              <IconButton
                                variant="outline"
                                isDisabled={!isNextPage(pageRequest, totalHits)}
                                aria-label="Next page"
                                boxSize={6}
                                icon={<ChevronRightIcon />}
                                onClick={() => { nextPage(pageRequest, setPageRequest) }}
                              />
                            </Stack>
                          </Box>
                          {isLoading ? (
                            <div className="custom-loader">
                            </div>
                          ) : (
                            <Table variant="simple" size="sm">
                              <Thead>
                                <Tr>
                                  <Th />
                                  <Th color="gostation.500">USERNAME</Th>
                                  <Th color="gostation.500">FIRST NAME</Th>
                                  <Th color="gostation.500">LAST NAME</Th>
                                  <Th color="gostation.500">PHONE NUMBER</Th>
                                  <Th color="gostation.500">EMAIL</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {usersList && usersList.map((user: User, i: number) => {
                                  return (
                                    <Tr key={i}>
                                      <Td><Checkbox onChange={(e) => addUserList(e.target.checked, user)} /></Td>
                                      <Td>{user.username}</Td>
                                      <Td>{user.profile?.firstName}</Td>
                                      <Td>{user.profile?.lastName}</Td>
                                      <Td>{user.profile?.phone}</Td>
                                      <Td>{user.email}</Td>
                                    </Tr>)
                                })}
                              </Tbody>
                            </Table>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Button colorScheme="gostation" onClick={addUsers}>
                            Add
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                    <Button ml="auto" leftIcon={<MdFileUpload />} colorScheme="gostation" bg="gostation.100" variant="solid" size="md" onClick={onOpenList}>UPLOAD USER LIST</Button>
                    <Modal isCentered isOpen={isOpenList} onClose={onCloseList} motionPreset="none">
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader color="gostation.500">Upload User List CSV</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <Stack direction="column">
                            <Text fontSize="xs">Upload a bulk list of Users</Text>
                            <CSVReader
                              onDrop={handleOnDrop}
                              onError={handleOnError}
                              addRemoveButton
                              onRemoveFile={handleOnRemoveFile}
                            >
                              <Text>Drop CSV file here or click to upload.</Text>
                            </CSVReader>
                            <Stack direction="row">
                              <Spacer />
                              <Button isDisabled={isImportDisabled} maxW="40%" leftIcon={<MdFileUpload />} colorScheme="gostation" variant="solid" size="sm" onClick={() => { addUserCSV() }}>UPLOAD USER LIST</Button>
                            </Stack>
                          </Stack>
                        </ModalBody>
                      </ModalContent>
                    </Modal>
                  </HStack>
                  <Box maxWidth="700px" mx="auto" mt="30px">
                    <CustomTable
                      tableData={users}
                      columnsData={columnsData}
                      pageSizeDefault={5}
                    />
                  </Box>

                </Box>}
                {dataType === "LOCATION" && <Box>
                  <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Target Location and Dates</Heading>
                  <Text fontSize="xs" color="gray" mt={"0.5rem"}>Specify Station Physical Reference</Text>
                  <FormControl isInvalid={!!errors?.evse?.message} errortext={errors?.evse?.message} id="name" w="300px">
                    <Input {...register("evse")} isRequired placeholder="e.g. GODC0001" value={evse} size="md" mt={"0.5rem"} onChange={(e) => setEvse(e.target.value)} />
                    <FormErrorMessage>{errors?.evse?.message}</FormErrorMessage>
                  </FormControl>
                  <HStack spacing={"160px"} mt={"0.5rem"}>
                    <Text fontSize="xs" color="gray">From Date</Text>
                    <Text fontSize="xs" color="gray">To Date</Text>
                  </HStack>
                  <HStack mt={"0.3rem"}>
                    <DatePicker className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" selected={fromDate} onChange={(date: Date) => setFromDate(date)} />
                    <DatePicker className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" selected={toDate} onChange={(date: Date) => setToDate(date)} />
                  </HStack>
                </Box>}
              </VStack>
            </HStack>
            <Divider />
            <HStack spacing={6} pt={4}>
              <Button onClick={() => { generateFile() }} colorScheme="gostation" variant="outline" size="md">GENERATE FILE</Button>
              <CSVDownloader
                data={csvData}
                filename={'user_data' + '_'+ dataType.toLowerCase()}
              >
                <Button isDisabled={!fileReady} leftIcon={<MdFileDownload />} colorScheme="gostation" variant="solid" size="md">DOWNLOAD</Button>
              </CSVDownloader>
            </HStack>
          </VStack>
        </LoadingOverlay>
      </Flex>
    </Box>
  );
}

