import React from "react";
import {Flex, Text, Button, Menu, MenuList, MenuItem, MenuButton, Avatar} from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link } from "react-router-dom";
import {useSessionContext} from "../context/AuthContext";

export const UserMenu = ({...props}) => {
    const [currentUser] = useSessionContext();

    return (
        <Flex
          bg="transparent"
          justifySelf="flex-end"
          {...props}
        >
          <Menu>
              <MenuButton as={Button} bg="transparent" rightIcon={<ChevronDownIcon />}>
                  <Text color="gostation.500">{currentUser.username}</Text>
              </MenuButton>
              <MenuList>
                  <MenuItem as={Link} to="/logout">Log Out</MenuItem>
              </MenuList>
          </Menu>
          <Avatar size="sm" alignSelf="center" ml={1} mr={2} name={currentUser.username} src={currentUser.picUrl} />
        </Flex>
    );
};
