import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import {
  Flex,
  Box,
  SimpleGrid,
  Image,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons';
import {
  AdsIcon,
  FaqsIcon,
  NotificationIcon,
  PromoCodesIcon,
  QuestionnaireIcon,
  UsersIcon,
  VehiclesIcon,
  EmployerIcon,
  RewardsIcon,
  ReportIcon
} from "../utils/icons";
import logo from "../assets/LogoIcon.svg";
import { OnlyAdmins, OnlyAdminsRetailers, OnlySuperAdmin } from "../types/UserTypes";
import { useSessionContext } from "../context/AuthContext";

import { RiMenuFoldLine } from "react-icons/ri";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { Link } from 'react-router-dom';

const reportDropDownItems = [ 
  { label: "Ads", route: "/reportAds", role: OnlyAdmins },
  { label: "EVSEs", route: "/reportEvse", role: OnlyAdmins },
  { label: "Promo Codes", route: "/reportPromoCodes", role: OnlyAdmins },
  { label: "Questionnaires", route: "/reportQuestionnaire", role: OnlyAdmins },
  { label: "Users", route: "/reportUser", role: OnlyAdmins },
];

const navItems = [
  { label: "Users", icon: UsersIcon, route: "/users", roles: OnlyAdmins },
  { label: "Vehicles", icon: VehiclesIcon, route: "/vehicles", roles: OnlyAdmins },
  { label: "Promo Codes", icon: PromoCodesIcon, route: "/promoCodes", roles: OnlySuperAdmin },
  { label: "Questionnaires", icon: QuestionnaireIcon, route: "/questionnaires", roles: OnlySuperAdmin },
  { label: "Ads", icon: AdsIcon, route: "/ads", roles: OnlySuperAdmin },
  { label: "Notifications", icon: NotificationIcon, route: "/notification", roles: OnlySuperAdmin },
  { label: "Rewards", icon: RewardsIcon, route: "/rewards", roles: OnlySuperAdmin },
  { label: "FAQs", icon: FaqsIcon, route: "/faqs", roles: OnlyAdminsRetailers },
  { label: "Employers", icon: EmployerIcon, route: "/employers", roles: OnlyAdmins }
];

const ReportAccordionDropDown = (sessionContext: any) => {
  return (
    <Box pl='-100' pt='-10px' pb='-10px' marginLeft='10px'>
      <Accordion allowToggle>
        <AccordionItem border='0'>  
          <h2>
            <AccordionButton position="relative">
              <Box as="span" flex='1' textAlign='left'>
                <ReportIcon mr={2} color="gostation.100" boxSize="24px" />
                Reports
              </Box>
              <AccordionIcon position="absolute" left="140px" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {reportDropDownItems.map(item => {
              if (!item.role.some(role => sessionContext.userRoles.includes(role))) return null;
              return (
                <Box pl="50px" pt="1px" pb="1px">
                  <NavLink to={item.route}>{item.label}</NavLink>
                </Box>
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}

const ReportMenuDropDown = (sessionContext: any) => {
  return (
    <Box pl='-100' pt='-10px' pb='-10px' marginTop='15px' marginLeft='25px'>
      <Menu
        px={4}
        py={2}
        transition='all 0.2s'
        borderRadius='md'
        borderWidth='1px'
        _hover={{ bg: 'gray.400' }}
        _expanded={{ bg: 'blue.400' }}
        _focus={{ boxShadow: 'outline' }}
      >
        <MenuButton>
          <ReportIcon mr={2} color="gostation.100" boxSize="24px" />
        </MenuButton>
        <MenuList>
          {reportDropDownItems.map(item => {
            if (!item.role.some(role => sessionContext.userRoles.includes(role))) return null;
            return (
              <MenuItem icon={<ArrowRightIcon />} color="gostation.100" as={NavLink} to={item.route}>{item.label}</MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
}

export const NavBar = ({ isToggled, toggleNavBar, ...props }) => {
  const [currentUser] = useSessionContext();
  let match = useRouteMatch();

  const isCurrentUrl = (url: string) => {
    return url === match.url;
  };

  return (
    <Flex as="nav" wrap="wrap" bg="gostation.500" color="white" display="block" lineHeight={8} {...props}>
      { isToggled ?
        (<Box position="absolute" top="17px" left="2px" cursor="pointer">
            <RiMenuUnfoldFill onClick={() => toggleNavBar()}/>
        </Box>) :
        (<Box position="absolute" top="44px" left="10px" cursor="pointer">
            <RiMenuFoldLine onClick={() => toggleNavBar()}/>
        </Box>)
      }

      <SimpleGrid>
        <Center w="full" h={isToggled ? "50px" : "100px"} p={4} mb={4}>
          <Link to="/">
            <Image src={logo} boxSize={isToggled ? "30px" : "60px"} />
          </Link>
        </Center>

        {navItems.map(item => {
          if (!item.roles.some(role => currentUser.userRoles.includes(role))) return null;

          const IconComponent = item.icon;
          return (
            <Box key={item.route} pl={isCurrentUrl(item.route) ? "4px" : "15px"}
                 bg={isCurrentUrl(item.route) ? "primary.200" : "transparent"}>
              <Box pl={isCurrentUrl(item.route) ? "25px" : "11px"}
                   pt="15px"
                   pb="15px"
                   bg={isCurrentUrl(item.route) ? "gostation.600" : "transparent"}>
                <NavLink to={item.route}>
                  <IconComponent mr={2} color={isCurrentUrl(item.route) ? "primary.200" : "gostation.100"} boxSize="24px" />
                  {!isToggled && item.label}
                </NavLink>
              </Box>
            </Box>
          );
        })}
        {!isToggled && ReportAccordionDropDown(currentUser)}
        {isToggled && ReportMenuDropDown(currentUser)}
      </SimpleGrid>
    </Flex>
  );
};
