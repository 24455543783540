import React, { useState, useRef, useEffect } from 'react';
import { Flex, Spacer, Box } from '@chakra-ui/react';
import { NavBar } from './NavBar';
import { UserMenu } from './UserMenu';

export function LoggedInLayout({ ...props }) {
  const [isToggled, setIsToggled] = useState(false);
  const [childrenHeight, setChildrenHeight] = useState(0);
  const childrenRef = useRef<HTMLDivElement>(null);
  const sidebarOffset = 40;
  const sidebarContentHeight = 916;

  useEffect(() => {
    // we are using resize observer package in order to resize the sidebar
    // in the cases where the content of the page get resized
    if (childrenRef?.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setChildrenHeight(entry.contentRect.height);
        }
      });

      // Start observing the element
      resizeObserver.observe(childrenRef.current);

      // Disconnect the observer on cleanup
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const determineSidebarHeight = () => {
    return Math.max(sidebarContentHeight, childrenHeight) + sidebarOffset;
  };

  return (
    <Flex w="full" h="100vh" justify="space-between" {...props}>
      <NavBar
        w={isToggled ? '80px' : '220px'}
        minW={isToggled ? '80px' : '220px'}
        h={determineSidebarHeight()}
        minH="100vh"
        isToggled={isToggled}
        toggleNavBar={() => setIsToggled((prev) => !prev)}
      />
      <Box w={isToggled ? 'calc(100% - 50px)' : 'calc(100% - 220px)'} h="100vh">
        <Flex w="100%" h={10}>
          <Spacer />
          <UserMenu />
        </Flex>
        <div ref={childrenRef}>{props.children}</div>
      </Box>
    </Flex>
  );
}
