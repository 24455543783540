import * as React from "react";
import {
  Box,
  HStack,
  Heading,
  FormControl,
  FormLabel,
  Input
} from "@chakra-ui/react";

const UploadCsvFile = (toast: any, label: string, setSelectedFile: (selectedFile: File) => void) => {
  const handleUpload = (event: any) => {
    setSelectedFile(event.target.files[0]);
  }

  return (
    <Box h="40px">
      <Heading pb={1} pt={1} fontWeight="bold" size="sm" color="gostation.500">File Details</Heading>
      <HStack spacing="2%">
        <Box>
          <FormControl id="selectedFile" p="1">
            <FormLabel fontSize="xs" color="gray">Upload {label}</FormLabel>
            <Input p={1} type="file" size="md" accept=".csv" onChange={handleUpload} />
          </FormControl>
        </Box>
      </HStack>
    </Box>
  );
}

export { UploadCsvFile }
