import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    HStack,
    VStack,
    Button,
    Divider,
    ModalOverlay,
    Modal,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Checkbox,
    Center,
    ModalFooter,
    Spacer,
    ModalContent,
    useDisclosure,
    useToast,
    Select,
    Input,
    FormControl,
    Heading,
    FormErrorMessage,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper, InputGroup, Radio, RadioGroup
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import {ChangeEvent, useEffect, useState} from "react";
import "../../css/react-datepicker.css";
import {PromoCodeAutomatic, PromoCodeAutomaticHistoric, RewardRules} from "../../types/PromoCodeAutomaticTypes";
import {PromoCode} from "../../types/PromoCodeTypes";
import {AxiosResponse} from "axios";
import {EntityListResponse} from "../../types/ApiTypes";
import {getPromoCodeListByStatus} from "../../api/PromoCodeService";
import {
    getAll, getAllByName, getAllNamesValues,
    getAllTypesValues,
    getPromoCodeAutomatic,
    setPromoCodeAutomatic
} from "../../api/PromoCodeAutomaticService";
import CustomTable from "../../components/CustomTable";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Ad} from "../../types/AdsTypes";
import {getAdsList} from "../../api/AdService";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import { setLoading } from '../../actions/loadingActions';

export function AutomaticRewards() {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const {
        isOpen: isOpenAd,
        onOpen: onOpenAd,
        onClose: onCloseAd
    } = useDisclosure()

    const [code, setCode] = useState("");
    const [adName, setAdName] = useState("");
    const [name, setName] = useState("NEW_REWARD");
    const [type, setType] = useState("WELCOME");
    const [evse, setEvse] = useState("");
    const [networkId, setNetworkId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locationRestriction, setLocationRestriction] = useState("NO_LOCATION_RESTRICTION");

    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);

    const [amount, setAmount] = useState(1);
    const [frequency, setFrequency] = useState("DAILY");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [lat, setLat] = useState(35.098702);
    const [long, setLong] = useState(-106.564627);
    const [radius, setRadius] = useState(0);
    const [level1, setLevel1] = useState(false);
    const [level2, setLevel2] = useState(false);
    const [level3, setLevel3] = useState(false);

    const [newName, setNewName] = useState("");

    let initialTypeList : string[] = []
    const [typeList, setTypeList] = useState(initialTypeList);

    let initialNameList : string[] = []
    const [nameList, setNameList] = useState(initialNameList);

    let initialAds : Ad[] = []
    const [adsList, setAdsList] = React.useState(initialAds)
    const [ad, setAd] = React.useState("")

    let initial : PromoCode[] = []
    const [promosList, setPromosList] = React.useState(initial)
    const [promoCode, setPromoCode] = React.useState("")

    let initialCheck : boolean[] = []
    const [checkedItems, setCheckedItems] = React.useState(initialCheck)

    let initialCheckAds : boolean[] = []
    const [checkedAdsItems, setCheckedAdsItems] = React.useState(initialCheckAds)

    let initialRewardsHistoric : PromoCodeAutomaticHistoric[] = []
    const [historicByName, setHistoricByName] = React.useState(initialRewardsHistoric)

    const [isAdSpecific, setIsAdSpecific] = useState(false);
    const [isQuizCorrect, setIsQuizCorrect] = useState(false);

    const schema = yup.object().shape({
        name: yup.string().min(1).notOneOf(nameList).required(),
        text: yup.string().max(100).required()
    });

    const moneyFormat = (val: number) => `$` + val
    const percentageFormat = (val: number) => `-` + val + `%`

    const { register, formState: { errors } } = useForm<PromoCodeAutomatic>({
        mode: "onBlur",
        resolver: yupResolver(schema),
    });

    const savePromo = () => {
        setCode(promoCode);
        onClose()
    };

    const saveAd = () => {
        setAdName(ad);
        onCloseAd()
    };

    const getPromoCodes = () => {
        let params = {
            status: true
        }
        getPromoCodeListByStatus(params).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                let entityResponse: EntityListResponse<PromoCode> = response.data
                setPromosList(entityResponse.data);
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to get Promo Codes from backend",
                position: "bottom",
                description: JSON.stringify(error.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })})
    };

    const getAds = () => {
        let params = {
            page: 0
        }
        getAdsList(params).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                let entityResponse: EntityListResponse<Ad> = response.data
                setAdsList(entityResponse.data);
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to get Ads from backend",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })})
    };

    const openModal = () => {
        getPromoCodes()
        onOpen()
    };

    const openAdModal = () => {
        getAds()
        onOpenAd()
    };

    const reset = () => {
        setCode("");
        setAdName("");
        setName("NEW_REWARD");
        setNewName("");
        setType("WELCOME");

        setEvse("");
        setNetworkId("");
        setLocationId("");
        setLocationRestriction("NO_LOCATION_RESTRICTION");

        setAmount(1);
        setFrequency("DAILY");
        setStartDate(new Date());
        setEndDate(new Date());
        setLat(35.098702);
        setLong(-106.564627);
        setRadius(0);
        setLevel1(false);
        setLevel2(false);
        setLevel3(false);
        setIsAdSpecific(false);
        setIsQuizCorrect(false);

        setCheckedItems([]);
        setCheckedAdsItems([]);

        getAllNames();
        getAllHistoric();
    };

    const setReward = (value: PromoCodeAutomatic) => {
        setCode(value.code as string);
        setType(value.type as string);
        if (value.type === "CHARGE" || value.type === "ADS") {
            setAmount(value.rules?.amount as number);
            setFrequency(value.rules?.frequency as string);
            if (frequency === "CUSTOM") {
                setStartDate(new Date(value.rules?.startDate as string));
                setEndDate(new Date(value.rules?.endDate as string));
            }
            if (value.type === "CHARGE") {
                if (value.rules?.networkId && value.rules?.locationId) {
                    setLocationRestriction("LOCATION_RESTRICTION");
                    setNetworkId(value.rules?.networkId as string);
                    setLocationId(value.rules?.locationId as string);
                    setLat(0);
                    setLong(0);
                    setRadius(0);
                } else if (value.rules?.radius) {
                    setLocationRestriction("AREA_LOCATION_RESTRICTION");
                    setLat(value.rules?.latitude as number);
                    setLong(value.rules?.longitude as number);
                    setRadius(value.rules?.radius as number);
                    setNetworkId("");
                    setLocationId("");
                } else {
                    setLocationRestriction("NO_LOCATION_RESTRICTION");
                    setLat(0);
                    setLong(0);
                    setRadius(0);
                    setNetworkId("");
                    setLocationId("");
                }
                if (value.rules?.powerLevels) {
                    if (value.rules?.powerLevels?.includes("LEVEL_1")){
                        setLevel1(true);
                    }
                    if (value.rules?.powerLevels?.includes("LEVEL_2")){
                        setLevel2(true);
                    }
                    if (value.rules?.powerLevels?.includes("LEVEL_3")){
                        setLevel3(true);
                    }
                } else {
                    setLevel1(false);
                    setLevel2(false);
                    setLevel3(false);
                }
            } else if (value.type === "ADS") {
                if (value.rules?.adViewed) {
                    setIsAdSpecific(true);
                    setAdName(value.rules?.adViewed);
                } else {
                    setIsAdSpecific(false);
                    setAdName("");
                }
                setIsQuizCorrect(value.rules?.quizCorrect as boolean);
            }
        }
    };

    const saveAction = () => {
        let rules : RewardRules = {}
        if (type === "CHARGE") {
            let power : string[] = []
            if (level1) {
                power.push("LEVEL_1")
            }
            if (level2) {
                power.push("LEVEL_2")
            }
            if (level3) {
                power.push("LEVEL_3")
            }
            if (frequency === "CUSTOM") {
                rules = {
                    amount: amount,
                    frequency: frequency,
                    startDate: startDate,
                    endDate: endDate,
                    powerLevels: power
                }
            } else {
                rules = {
                    amount: amount,
                    frequency: frequency,
                    powerLevels: power
                }
            }
            const { ...rest } = rules

            if (locationRestriction === "LOCATION_RESTRICTION" && evse) {
                rules = {
                    ...rest,
                    physicalReference: evse
                }
            } else if (locationRestriction === "AREA_LOCATION_RESTRICTION" && radius > 0) {
                rules = {
                    ...rest,
                    latitude: lat,
                    longitude: long,
                    radius: radius
                }
            } else {
                rules = {
                    ...rest
                }
            }
        } else if (type === "ADS") {
            if (frequency === "CUSTOM") {
                rules = {
                    amount: amount,
                    frequency: frequency,
                    startDate: startDate,
                    endDate: endDate,
                    quizCorrect: isQuizCorrect,
                    adViewed: adName
                }
            } else {
                rules = {
                    amount: amount,
                    frequency: frequency,
                    quizCorrect: isQuizCorrect,
                    adViewed: adName
                }
            }
        }
        let promoCodeAutomatic : PromoCodeAutomatic = {
            code : code,
            type : type,
            name : name === "NEW_REWARD" ? newName : name,
            rules : rules
        }
        setPromoCodeAutomatic(promoCodeAutomatic).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                toast({
                    title: "Promo Code Reward",
                    position: "top",
                    description: "Promo code reward set by type successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                reset()
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to add Promo Code Reward by Type",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    const selectName = (selectedName: string) => {
        if (selectedName === "NEW_REWARD") {
            reset();
        } else if (selectedName) {
            setCheckedItems([]);
            setCheckedAdsItems([]);
            setName(selectedName);
            getRewardByName(selectedName);
            getHistoricByName(selectedName);
        }
    };

    const getRewardByName = (selectedName: string) => {
        let params = {
            name: selectedName
        }
        getPromoCodeAutomatic(params).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                let reward: PromoCodeAutomatic = response.data
                setReward(reward)
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            if (error?.response?.status !== 404) {
                toast({
                    title: "Failed to get Promo Code Reward from backend",
                    position: "bottom",
                    description: JSON.stringify(error.message),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        })
    };

    const getHistoricByName = async (selectedName: string) => {
        try {
          dispatch(setLoading(true));

          let params = {
            name: selectedName
          };
          const response = await getAllByName(params);
          if ([200, 201].includes(response.status)) {
            let entityResponse: EntityListResponse<PromoCodeAutomaticHistoric> = response.data;
            setHistoricByName(entityResponse.data);
          } else {
            const error = (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
          }
        } catch (error: any) {
          toast({
            title: "Failed to get Promo Code Reward Historic from backend",
            position: "bottom",
            description: JSON.stringify(error.message),
            status: "error",
            duration: 5000,
            isClosable: true
          });
        } finally {
            dispatch(setLoading(false));
        }
      };

    const getAllHistoric = () => {
        let params = {}
        getAll(params).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                let entityResponse: EntityListResponse<PromoCodeAutomaticHistoric> = response.data
                setHistoricByName(entityResponse.data);
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to get Promo Code Reward Historic from backend",
                position: "bottom",
                description: JSON.stringify(error.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })})
    };

    const checkItem = (e: ChangeEvent<HTMLInputElement>, i: number, promo: PromoCode) => {
        if (e.target.checked) {
            setPromoCode(promo.code as string)
        } else {
            setPromoCode("")
        }

        const checkList = checkedItems.map(() => false);
        checkList[i] = e.target.checked
        setCheckedItems(checkList)
    };

    const checkAdsItem = (e: ChangeEvent<HTMLInputElement>, i: number, value: Ad) => {
        if (e.target.checked) {
            setAd(value.name as string)
        } else {
            setAd("")
        }

        const checkList = checkedAdsItems.map(() => false);
        checkList[i] = e.target.checked
        setCheckedAdsItems(checkList)
    };

    useEffect(() => {
        dispatch(setLoading(true));
        getAllNames();
        getAllTypesValues().then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                setTypeList(response.data as string[]);
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to get reward types from backend",
                position: "bottom",
                description: JSON.stringify(error.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })}).finally(() => {
                dispatch(setLoading(false))
});
        getAllHistoric()
    }, [toast]);

    const getAllNames = () => {
        getAllNamesValues().then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                setNameList(response.data as string[]);
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to get reward names from backend",
                position: "bottom",
                description: JSON.stringify(error.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })})
    };

    const columnsData = [
        {
            Header: "CODE",
            accessor: "code"
        },
        {
            Header: "NAME",
            accessor: "name"
        },
        {
            Header: "TYPE",
            accessor: "type"
        },
        {
            Header: "CREATE DATE",
            accessor: "createDate"
        },
        {
            Header: "UPDATE DATE",
            accessor: "updateDate"
        }
    ];

    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem color="gostation.500" isCurrentPage>
                      <BreadcrumbLink as={Link} to="/rewards">
                          <Text>Automatic Rewards</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          <Flex w="96%" h="90%" minH="500px" bg="white" ml={6} mr={6}>
              <VStack spacing={3} alignItems="flex-start">
                  <HStack>
                      <VStack minH="400px" minW="500px" pl={4} pr={4} alignItems="left">
                          <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Reward details</Heading>
                          <Text fontSize="xs" color="gray">Reward Name</Text>
                          <HStack>
                              <Select isRequired value={name} onChange={(e) => selectName(e.target.value)}>
                                  <option value="NEW_REWARD" key="NEW_REWARD">
                                    { (nameList && nameList.length) > 0 ? "Add a new reward" : "Loading rewards..." }
                                  </option>
                                  {nameList && nameList.map((names : string, index: number) => {
                                      return (
                                          <option key={names + index} value={names}>{names}</option>
                                      )
                                  })}
                              </Select>
                              {(name === "NEW_REWARD") && <FormControl isInvalid={!!errors?.name?.message} errortext={errors?.name?.message} id="name" w="300px" p="1">
                                  <Input {...register("name")} isRequired placeholder="New Name" value={newName} size="md" onChange={(e) => setNewName(e.target.value)}  />
                                  <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                              </FormControl>}
                          </HStack>
                          <Text fontSize="xs" color="gray">Reward Type</Text>
                          <Select isDisabled={name!=="NEW_REWARD"} isRequired value={type} onChange={(e) => setType(e.target.value)}>
                              { (!typeList || typeList.length === 0) &&
                                <option value="NEW_REWARD" key="NEW_REWARD">Loading rewards...</option>
                              }
                              {typeList && typeList.map((types : string, index: number) => {
                                  return (
                                      <option key={index} value={types}>{types}</option>
                                  )
                              })}
                          </Select>
                          <Text fontSize="xs" color="gray">Reward Promo Code</Text>
                          <HStack spacing={5}>
                              <Button onClick={openModal} minW="170px" colorScheme="gostation" bg="gostation.100" variant="solid" size="md">SELECT REWARD</Button>
                              <Input isDisabled placeholder="REWARD" value={code} size="md"/>
                              <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none">
                                  <ModalOverlay />
                                  <ModalContent minW="550px">
                                      <ModalHeader color="gostation.500">Select Promo Code</ModalHeader>
                                      <ModalCloseButton />
                                      <ModalBody>
                                          {promosList.length > 0 ?
                                              (<Table variant="simple" size="sm">
                                                  <Thead>
                                                      <Tr>
                                                          <Th/>
                                                          <Th color="gray">PROMO CODE</Th>
                                                          <Th color="gray">VALUE</Th>
                                                          <Th color="gray">TYPE</Th>
                                                      </Tr>
                                                  </Thead>
                                                  <Tbody>
                                                      {promosList && promosList.map((promo: PromoCode , i: number) => {
                                                          return (
                                                              <Tr key={i}>
                                                                  <Td><Checkbox isChecked={checkedItems[i]} onChange={(e) => checkItem(e, i, promo)}/></Td>
                                                                  <Td>{promo.code}</Td>
                                                                  <Td>{promo.valueType === "MONEY" ? moneyFormat(promo.value as number) : percentageFormat(promo.value as number)}</Td>
                                                                  <Td>{promo.type}</Td>
                                                              </Tr>)
                                                      })}
                                                  </Tbody>
                                              </Table>)
                                              : (<Center ml={4} mr={4} minH={200} borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">
                                                  <Text>No Valid Promo Codes Available</Text>
                                              </Center>)}
                                      </ModalBody>
                                      <ModalFooter>
                                          <Spacer/>
                                          <Button colorScheme="gostation" onClick={savePromo}>SAVE</Button>
                                      </ModalFooter>
                                  </ModalContent>
                              </Modal>
                          </HStack>
                      </VStack>
                      <VStack minH="300px" minW="600px" pl={10} pr={4} alignItems="left">
                          {((type === "CHARGE") || (type === "ADS")) && <Box>
                              <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Reward conditions</Heading>
                              <Text fontSize="xs" mb={2} color="gray">Amount Required</Text>
                              <InputGroup>
                                  <NumberInput min={1} value={amount} isDisabled={name!=="NEW_REWARD"}
                                               onChange={(valueAsString, valueAsNumber) => setAmount(valueAsNumber)}>
                                      <NumberInputField />
                                      <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                      </NumberInputStepper>
                                  </NumberInput>
                              </InputGroup>
                              <HStack spacing={"160px"} mt={2} mb={2}>
                                  <Text fontSize="xs" color="gray">Frequency</Text>
                                  {(frequency === "CUSTOM") && <HStack spacing={"160px"}>
                                          <Text fontSize="xs" color="gray">Start Date</Text>
                                          <Text fontSize="xs" color="gray">End Date</Text>
                                  </HStack>}
                              </HStack>
                              <HStack spacing={5}>
                                  <Select isRequired isDisabled={name!=="NEW_REWARD"} value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                                      <option value="DAILY">Daily</option>
                                      <option value="WEEKLY">Weekly</option>
                                      <option value="MONTHLY">Monthly</option>
                                      <option value="YEARLY">Yearly</option>
                                      <option value="CUSTOM">Custom</option>
                                  </Select>
                                  {(frequency === "CUSTOM") && <HStack>
                                      <DatePicker disabled={name!=="NEW_REWARD"} className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                                      <DatePicker disabled={name!=="NEW_REWARD"} className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" selected={endDate} onChange={(date: Date) => setEndDate(date)} />
                                  </HStack>}
                              </HStack>
                              {(type === "CHARGE") && <Box>
                              <Text fontSize="xs" color="gray" mt={2} mb={2}>Location</Text>
                              <RadioGroup onChange={(nextValue : string) => setLocationRestriction(nextValue)} value={locationRestriction}>
                                  <Radio isDisabled={name!=="NEW_REWARD"} pl={2} size='sm' colorScheme='gostation' value="NO_LOCATION_RESTRICTION" defaultChecked>
                                      No Location restriction
                                  </Radio>
                                  <Radio isDisabled={name!=="NEW_REWARD"} pl={2} size='sm' colorScheme='gostation' value="LOCATION_RESTRICTION">
                                      Location specific
                                  </Radio>
                                  <Radio isDisabled={name!=="NEW_REWARD"} pl={2} size='sm' colorScheme='gostation' value="AREA_LOCATION_RESTRICTION">
                                      Area restriction for Location
                                  </Radio>
                              </RadioGroup>
                              {(locationRestriction === "LOCATION_RESTRICTION") && (name==="NEW_REWARD") && <Box>
                              <HStack spacing={"118px"} mt={2}>
                                  <Text fontSize="xs" color="gray">Station Physical Reference</Text>
                              </HStack>
                              <HStack spacing={5}>
                                  <Input isDisabled={name!=="NEW_REWARD"} isRequired placeholder="e.g. GODC0001" value={evse} size="md" mt={"0.5rem"} onChange={(e) => setEvse(e.target.value)}/>
                              </HStack>
                              </Box>}
                              {(locationRestriction === "LOCATION_RESTRICTION") && (name!=="NEW_REWARD") && <Box>
                                  <HStack spacing={"160px"} mt={2}>
                                      <Text fontSize="xs" color="gray">Network Id</Text>
                                      <Text fontSize="xs" color="gray">Location Id</Text>
                                  </HStack>
                                  <HStack spacing={5} mt={1}>
                                      <Input isDisabled value={networkId} size="md" maxW="200px" />
                                      <Input isDisabled value={locationId} size="md"/>
                                  </HStack>
                              </Box>}
                              {(locationRestriction === "AREA_LOCATION_RESTRICTION") && <Box>
                                  <HStack spacing={"118px"} mt={2}>
                                      <Text fontSize="xs" color="gray">Location Latitude</Text>
                                      <Text fontSize="xs" color="gray">Location Longitude</Text>
                                      <Text fontSize="xs" color="gray">Location Distance</Text>
                                  </HStack>
                                  <HStack spacing={5} mt={2}>
                                      <NumberInput value={lat} precision={6} step={0.2} isDisabled={name!=="NEW_REWARD"}
                                                   onChange={(valueAsString, valueAsNumber) => setLat(valueAsNumber)}>
                                          <NumberInputField />
                                      </NumberInput>
                                      <NumberInput value={long} precision={6} step={0.2} isDisabled={name!=="NEW_REWARD"}
                                                   onChange={(valueAsString, valueAsNumber) => setLong(valueAsNumber)}>
                                          <NumberInputField />
                                      </NumberInput>
                                      <NumberInput value={radius} min={0} isDisabled={name!=="NEW_REWARD"}
                                                   onChange={(valueAsString, valueAsNumber) => setRadius(valueAsNumber)}>
                                          <NumberInputField />
                                      </NumberInput>
                                  </HStack>
                              </Box>}
                              <Text mt={2} mb={2} fontSize="xs" color="gray">Power Levels</Text>
                              <HStack spacing={5}>
                                  <Checkbox isChecked={level1} isDisabled={name!=="NEW_REWARD"} onChange={(e) => setLevel1(e.target.checked)}>Level 1</Checkbox>
                                  <Checkbox isChecked={level2} isDisabled={name!=="NEW_REWARD"} onChange={(e) => setLevel2(e.target.checked)}>Level 2</Checkbox>
                                  <Checkbox isChecked={level3} isDisabled={name!=="NEW_REWARD"} onChange={(e) => setLevel3(e.target.checked)}>Level 3</Checkbox>
                              </HStack>
                              </Box>}
                              {(type === "ADS") && <Box>
                                  <HStack spacing={5} pt={4}>
                                      <Checkbox isDisabled={name!=="NEW_REWARD"} isChecked={isAdSpecific} onChange={(e) => setIsAdSpecific(e.target.checked)}>Is Ad Specific</Checkbox>
                                      <Checkbox isDisabled={name!=="NEW_REWARD"} isChecked={isQuizCorrect} onChange={(e) => setIsQuizCorrect(e.target.checked)}>Need to answer quiz correctly</Checkbox>
                                  </HStack>
                                  {isAdSpecific && <Box>
                                      <Text pt={2} pb={2} fontSize="xs" color="gray">Reward Ad</Text>
                                      <HStack spacing={5}>
                                          <Button isDisabled={name!=="NEW_REWARD"} onClick={openAdModal} minW="170px" colorScheme="gostation" bg="gostation.100" variant="solid" size="md">SELECT AD</Button>
                                          <Input isDisabled placeholder="AD" value={adName} size="md"/>
                                          <Modal isCentered isOpen={isOpenAd} onClose={onCloseAd} motionPreset="none">
                                              <ModalOverlay />
                                              <ModalContent minW="550px">
                                                  <ModalHeader color="gostation.500">Select Ad</ModalHeader>
                                                  <ModalCloseButton />
                                                  <ModalBody>
                                                      {adsList.length > 0 ?
                                                          (<Table variant="simple" size="sm">
                                                              <Thead>
                                                                  <Tr>
                                                                      <Th/>
                                                                      <Th color="gray">AD NAME</Th>
                                                                      <Th color="gray">CATEGORY NAME</Th>
                                                                      <Th color="gray">TYPE</Th>
                                                                  </Tr>
                                                              </Thead>
                                                              <Tbody>
                                                                  {adsList && adsList.map((value: Ad , i: number) => {
                                                                      return (
                                                                          <Tr key={i}>
                                                                              <Td><Checkbox isChecked={checkedAdsItems[i]} onChange={(e) => checkAdsItem(e, i, value)}/></Td>
                                                                              <Td>{value.name}</Td>
                                                                              <Td>{value.categoryName}</Td>
                                                                              <Td>{value.type}</Td>
                                                                          </Tr>)
                                                                  })}
                                                              </Tbody>
                                                          </Table>)
                                                          : (<Center ml={4} mr={4} minH={200} borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">
                                                              <Text>No Valid Ads Available</Text>
                                                          </Center>)}
                                                  </ModalBody>
                                                  <ModalFooter>
                                                      <Spacer/>
                                                      <Button colorScheme="gostation" onClick={saveAd}>SAVE</Button>
                                                  </ModalFooter>
                                              </ModalContent>
                                          </Modal>
                                      </HStack>
                                  </Box>}
                              </Box>}
                          </Box>}
                      </VStack>
                  </HStack>
                  <HStack mb={5} pl={4}>
                      <Button as={Link} to="/dashboard" ml="400px" minW={"100px"} colorScheme="gostation" variant="outline" size="md">CANCEL</Button>
                      <Button onClick={() => {saveAction()}} mr="auto" minW={"100px"} colorScheme="gostation" variant="solid" size="md">SAVE</Button>
                  </HStack>
                  <Divider/>
                  <Heading pl={4} fontWeight="bold" size="sm" color="gostation.500">Reward History</Heading>
                  {isLoading ? (
                        <div className="custom-loader-rewards">
                        </div>
                    ) : (
                  <CustomTable
                      tableData={historicByName}
                      columnsData={columnsData}
                      pageSizeDefault={15}
                  />
                  )}
              </VStack>
          </Flex>
      </Box>
  );
}
