import React, { useEffect } from 'react';

export function AppRedirect() {
    useEffect(() => {
        const userAgent: string = navigator.userAgent || navigator.vendor;

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.href = "https://apps.apple.com/us/app/go-station-ev-charging/id1582489272";
        }
        else if (/android/i.test(userAgent)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.gostation.app&hl=en_US&gl=US";
        }
        else {
            window.location.href = "https://apps.apple.com/us/app/go-station-ev-charging/id1582489272";
        }
    }, []);

    return (
        <div>Redirecting to the app store...</div>
    );
};  

