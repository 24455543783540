import { AxiosResponse, CancelTokenSource } from "axios";
import { EntityListResponse, PageRequest } from "../types/ApiTypes";
import { UserEmployer, EmployerStatus } from "../types/EmployerTypes";
import { displaySuccessMessage, displayErrorMessage } from "../utils/PopUpMessage";
import { findUserEmployersByStatus, findUserEmployersByPattern, enrollUserEmployers } from "../api/EmployerService";
import { setLoading } from "../actions/loadingActions";
import { AnyAction } from "@reduxjs/toolkit";
import axios from 'axios';

let cancelToken: CancelTokenSource;

export function isStatusValidForEnroll(employerStatus: EmployerStatus) {
  return EmployerStatus.PENDING === employerStatus ? true : false;
}

export function isThereAnyPendingStatus(userEmployers: UserEmployer[]) {
  return userEmployers.find(userEmployer => EmployerStatus.PENDING === userEmployer.status) ? true : false;
}

export function filterByStatus(toast: any,
                               pageRequest: PageRequest,
                               element: HTMLButtonElement,
                               setEmployerStatus: (employerStatus: EmployerStatus) => void,
                               setTotalHits: (totalHits: number) => void,
                               setUserEmployers: (userEmployers: UserEmployer[]) => void,
                               setCheckedItems: (checkedItems: boolean[]) => void,
                               dispatch: React.Dispatch<AnyAction>,) {
  searchUserEmployersByStatus(toast, pageRequest, element.value as EmployerStatus, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedItems, dispatch);
}

export function isThereAnyCheckedItem(checkedItems: boolean[]) {
  return checkedItems.find(checkedItem => true === checkedItem) ? true : false;
}

function getUninitializedCheckboxSlots(userEmployers: UserEmployer[]) {
  let userEmployersBooleanSlots = [];
  for (let i = 0; i < userEmployers.length; i++) {
    userEmployersBooleanSlots.push(false);
  }
  if (userEmployers.length === 0) {
    userEmployersBooleanSlots.push(false);
  }
  return userEmployersBooleanSlots;
}

export function handleCheckboxObjects(checkedUserEmployers: UserEmployer[], checkedItems: boolean[], userEmployer: UserEmployer, index: number) {
  let wasUserEmployerKept = checkedUserEmployers.indexOf(userEmployer) !== -1 ? true : false;
  let isItemChecked = checkedItems[index];

  if (EmployerStatus.PENDING === userEmployer?.status && isItemChecked) {
    if (!wasUserEmployerKept) {
      checkedUserEmployers.push(userEmployer);
    }
  } else if (!isItemChecked && wasUserEmployerKept) {
    checkedUserEmployers.splice(checkedUserEmployers.indexOf(userEmployer));
  }
}

function handleUsersSearchError(error: any, dispatch: React.Dispatch<AnyAction>, toast: any) {
  if (axios.isCancel(error)) {
    console.log('Request cancelled:', error.message);
  } else {
    dispatch(setLoading(false));
    displayErrorMessage(toast, "Failed to get Users from backend", JSON.stringify(error.message), "bottom");
  }
}

export function searchUserEmployersByPattern(toast: any,
                                             pageRequest: PageRequest,
                                             pattern: string,
                                             setTotalHits: (totalHits: number) => void,
                                             setUserEmployers: (userEmployers: UserEmployer[]) => void,
                                             setCheckedItems: (checkedItems: boolean[]) => void,
                                             dispatch: React.Dispatch<AnyAction>,) {

  if (cancelToken) {
    // If we have a cancel token we cancel the previous request before making a new request
    cancelToken.cancel("Cancelled the previous request");
  }

  cancelToken = axios.CancelToken.source();

  let params = {
    page: pageRequest ? pageRequest.page : 0,
    size: pageRequest ? pageRequest.pageSize : 50,
    pattern: pattern
  }
  dispatch(setLoading(true));
  try {
    findUserEmployersByPattern(params, cancelToken.token).then((response: AxiosResponse) => {
      dispatch(setLoading(false));
      if ([200, 201].includes(response.status)) {
        let entityResponse: EntityListResponse<UserEmployer> = response.data;
        setTotalHits(entityResponse.responseDescription.totalHits);
        setUserEmployers(entityResponse.data);
        // Set the amount of uninitialized checkboxes:
        setCheckedItems(getUninitializedCheckboxSlots(entityResponse.data));
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
      }
    }).catch((error: any) => {
      handleUsersSearchError(error, dispatch, toast);
    })
  } catch (error: any) {
    handleUsersSearchError(error, dispatch, toast);
  }
}

export function searchUserEmployersByStatus(toast: any,
                                            pageRequest: PageRequest,
                                            employerStatus: EmployerStatus,
                                            setEmployerStatus: (employerStatus: EmployerStatus) => void,
                                            setTotalHits: (totalHits: number) => void,
                                            setUserEmployers: (userEmployers: UserEmployer[]) => void,
                                            setCheckedItems: (checkedItems: boolean[]) => void,
                                            dispatch: React.Dispatch<AnyAction>,) {

  let params = {
    employerStatus: employerStatus,
    page: pageRequest ? pageRequest.page : 0,
    size: pageRequest ? pageRequest.pageSize : 50
  }

  if (cancelToken) {
    // If we have a cancel token we cancel the previous request before making a new request
    cancelToken.cancel("Cancelled the previous request");
  }

  cancelToken = axios.CancelToken.source();

  dispatch(setLoading(true));
  try {
    findUserEmployersByStatus(params, cancelToken.token).then((response: AxiosResponse) => {
      dispatch(setLoading(false));
      if ([200, 201].includes(response.status)) {
        let entityResponse: EntityListResponse<UserEmployer> = response.data;
        setEmployerStatus(params.employerStatus);
        setTotalHits(entityResponse.responseDescription.totalHits);
        setUserEmployers(entityResponse.data);
        // Set the amount of uninitialized checkboxes:
        setCheckedItems(getUninitializedCheckboxSlots(entityResponse.data));
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
      }
    }).catch((error: any) => {
      handleUsersSearchError(error, dispatch, toast);
    })
  } catch (error: any) {
    handleUsersSearchError(error, dispatch, toast);
  }
}

export function enrollUsers(toast: any,
                            pageRequest: PageRequest,
                            userEmployers: UserEmployer[],
                            employerStatus: EmployerStatus,
                            setEmployerStatus: (employerStatus: EmployerStatus) => void,
                            setTotalHits: (totalHits: number) => void,
                            setUserEmployers: (userEmployers: UserEmployer[]) => void,
                            setCheckedItems: (checkedItems: boolean[]) => void) {
  let params = {
    userEmployers: userEmployers,
    callbackStatus: EmployerStatus.ALL === employerStatus ? null : employerStatus,
    page: pageRequest ? pageRequest.page : 0,
    size: pageRequest ? pageRequest.pageSize : 50
  }
  enrollUserEmployers(params).then((response: AxiosResponse) => {
    if ([200, 201].includes(response.status)) {
      let entityResponse: EntityListResponse<UserEmployer> = response.data;
      setEmployerStatus(employerStatus);
      setTotalHits(entityResponse.responseDescription.totalHits);
      setUserEmployers(entityResponse.data);
      // Set the amount of uninitialized checkboxes:
      setCheckedItems(getUninitializedCheckboxSlots(entityResponse.data));
      if (userEmployers.length === 1) {
        displaySuccessMessage(toast, "User Enrollment", "User: " + params?.userEmployers?.at(0)?.username + " successfully enrolled.", "bottom");
      } else {
        displaySuccessMessage(toast, "User Enrollment", "Users have been successfully enrolled.", "bottom");
      }
    } else {
      const error = (response.data && response.data.message) || response.statusText;
      return Promise.reject(error);
    }
  }).catch((error: any) => {
    displayErrorMessage(toast, "Failed to enroll the user", JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message), "bottom");
  })
}

export function enrollCheckedUserEmployers(toast: any,
                                           pageRequest: PageRequest,
                                           checkedUserEmployers: UserEmployer[],
                                           employerStatus: EmployerStatus,
                                           setEmployerStatus: (employerStatus: EmployerStatus) => void,
                                           setTotalHits: (totalHits: number) => void,
                                           setUserEmployers: (userEmployers: UserEmployer[]) => void,
                                           setCheckedUserEmployers: (userEmployers: UserEmployer[]) => void,
                                           setCheckedItems: (checkedItems: boolean[]) => void) {
  let userEmployers: UserEmployer[] = checkedUserEmployers;
  setCheckedUserEmployers([]);
  enrollUsers(toast, pageRequest, userEmployers, employerStatus, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedItems);
}
