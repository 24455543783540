function toPascalCase(str: string): string {
    if (!str) return '';
    
    return str
        .toLowerCase()
        .split(/[-_ ]+/) 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

function toCustomTitleCase(str: string): string {
    if (!str) return '';

    const words = str
        .toLowerCase()
        .split(/[-_ ]+/); 

    if (words.length > 0) {
        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    }

    return words.join(' '); 
}

export { toPascalCase, toCustomTitleCase };
