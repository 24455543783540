function downloadCsvFile(inputStreamData: any, downloadFileName: string) {
  const url = window.URL.createObjectURL(new Blob([inputStreamData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', downloadFileName);
  document.body.appendChild(link);
  link.click();
}

export { downloadCsvFile }
