import {authApiHeader} from "./AuthHeader";
import {PromoCodeAutomatic} from "../types/PromoCodeAutomaticTypes";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/promoCodeAutomatic";

export const getPromoCodeAutomatic = (params: any) => {
    return axios.get(API_URL, { headers: authApiHeader(), params});
};

export const setPromoCodeAutomatic = (body: PromoCodeAutomatic) => {
    return axios.post(API_URL, body,{ headers: authApiHeader()});
};

export const getAllTypesValues = () => {
    return axios.get(API_URL + "/types", { headers: authApiHeader()});
};

export const getAllNamesValues = () => {
    return axios.get(API_URL + "/names", { headers: authApiHeader()});
};

export const getAll = (params: any) => {
    return axios.get(API_URL + "/historic", { headers: authApiHeader(), params});
};

export const getAllByName = (params: any) => {
    return axios.get(API_URL + "/historicByName", { headers: authApiHeader(), params});
};