import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem, Center, Button, Stack, Heading, Input, MenuButton, MenuList, MenuItem, Menu, Textarea,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import {ChevronDownIcon} from "@chakra-ui/icons";

export function Faqs() {

    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem isCurrentPage>
                      <BreadcrumbLink as={Link} to="/faqs">
                          <Text>FAQs</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          <Stack direction="row" spacing={2}>
              <Center m={4} minH={100} minW="50%" borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">No FAQs</Center>
              <Box bg="white" minW="45%" p={4} m={4}>
                  <Stack direction="column" spacing={2}>
                      <Heading mb={4} color="gostation.500" size="sm">New FAQ</Heading>
                      <Text fontSize="xs" color="gray">Select FAQ topic</Text>
                      <Menu>
                          <MenuButton mb={4} p={2} as={Button} size="xs" maxW="50%" bg="white" borderWidth={1} rightIcon={<ChevronDownIcon />}>
                              Select Topic
                          </MenuButton>
                          <MenuList>
                              <MenuItem value="Sign In" >Sign In</MenuItem>
                              <MenuItem value="My Account" >My Account</MenuItem>
                          </MenuList>
                      </Menu>
                      <Text fontSize="xs" color="gray">Question</Text>
                      <Input mb={4} placeholder="Type question here" size="md" />
                      <Text fontSize="xs" color="gray">Answer</Text>
                      <Textarea mb={4} placeholder="Type answer here" size="md"/>
                      <Stack direction="row" spacing={4}>
                        <Button isDisabled colorScheme="gostation" variant="outline" size="sm">CANCEL</Button>
                        <Button isDisabled colorScheme="gostation" variant="solid" size="sm">PUBLISH</Button>
                      </Stack>
                  </Stack>
              </Box>
          </Stack>
      </Box>
  );
}
