import * as React from "react"
import {Redirect, useHistory} from "react-router-dom"
import {logout} from "../../api/AuthService";
import {initialSession, useSessionContext} from "../../context/AuthContext";

export function Logout() {
    const [, setCurrentUser] = useSessionContext();
    const history = useHistory();

    React.useEffect(() => {
        logout();
        setCurrentUser(initialSession);
        history.push('/login');
    }, [history, setCurrentUser]);

    return (
        <Redirect to="/login" />
  );
}