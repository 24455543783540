import { authApiHeader } from "./AuthHeader";
import { CancelToken } from "axios";

const axios = require('axios').default;
const API_URL = process.env.REACT_APP_API_URL + "/api/employer";

/**
 * Find users by a pattern, which can be: email, or username, or employer name,
 * or employer email suffix, or location ID, or network ID.
 */
export const findUserEmployersByPattern = (params: any, cancelToken: CancelToken) => {
  return axios.get(API_URL + "/findUserEmployersByPattern", { headers: authApiHeader(), params, cancelToken });
}

/**
 * Find users by status (PENDING, ENROLLED, or INACTIVE).
 */
export const findUserEmployersByStatus = (params: any, cancelToken: CancelToken) => {
  return axios.get(API_URL + "/findUserEmployersByStatus", { headers: authApiHeader(), params, cancelToken });
}

export const enrollUserEmployers = (params: any) => {
  return axios.put(API_URL + "/enrollUserEmployers", params, { headers: authApiHeader() });
}
