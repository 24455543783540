import * as React from "react";
import {
  Flex,
  Box,
  HStack,
  VStack,
  StackDivider,
  Button,
  Input,
  Checkbox
} from "@chakra-ui/react";
import {
  createQuestionnaire as createQuestionnaireApi
} from "../../api/QuestionnaireHandlingService";
import { Questionnaire } from "../../types/QuestionnaireTypes";
import { LoadAllQuestionnaires } from "./QuestionnaireComponents";
import { displaySuccessMessage, displayErrorMessage } from "../../utils/PopUpMessage";

export const QuestionnaireManagement = (toast: any, setQuestionnaireList: (questionnaireList: string[]) => void) => {
  const [questionnaireTitle, setQuestionnaireTitle] = React.useState("");
  const handleQuestionnaireTitleChange = (event: any) => setQuestionnaireTitle(event.target.value);

  const [questionnaireName, setQuestionnaireName] = React.useState("");
  const handleQuestionnaireNameChange = (event: any) => setQuestionnaireName(event.target.value);

  const [allowResubmission, setAllowResubmission] = React.useState(false);

  const createQuestionnaire = (questionnaire: Questionnaire) => {
    createQuestionnaireApi(questionnaire).then(function(response) {
      if (response === true) {
        LoadAllQuestionnaires(toast, setQuestionnaireList);
        displaySuccessMessage(toast, "Questionnaire creation", "The questionnaire was created successfully", "top");
      } else {
        displayErrorMessage(toast, "Failed to create the questionnaire", JSON.stringify(response), "bottom");
      }
    });
  }

  const addQuestionnaire = () => {
    let questionnaire: Questionnaire = {}
    questionnaire.title = questionnaireTitle;
    questionnaire.name = questionnaireName;
    questionnaire.allowResubmission = allowResubmission;
    createQuestionnaire(questionnaire);
    setQuestionnaireTitle("");
    setQuestionnaireName("");
    setAllowResubmission(false);
  }

  return (
    <Flex pb="90%" w="100%" h="90%" minH="660px" ml={6} mr={6}>
      <VStack
        divider={<StackDivider borderColor='gray.200' />}
        spacing={5}
        align='stretch'
      >
        <Box h='40px'>
          <HStack spacing="2%">
            <Box>
              <Input
                m={2}
                type="text"
                name="addQuestionnaireTitle"
                value={questionnaireTitle}
                placeholder="Questionnaire title"
                size="sm"
                w="100%"
                borderColor="gray.300"
                onChange={handleQuestionnaireTitleChange}
              />
            </Box>

            <Box>
              <Input
                m={2}
                type="text"
                name="addQuestionnaireName"
                value={questionnaireName}
                placeholder="Questionnaire name"
                size="sm"
                w="100%"
                borderColor="gray.300"
                onChange={handleQuestionnaireNameChange}
              />
            </Box>

            <Box>
              <Checkbox w="100%" m={2} isChecked={allowResubmission} onChange={(e) => setAllowResubmission(e.target.checked)}>Allow Resubmission</Checkbox>
            </Box>

            <Box>
              <Button ml="7%" onClick={() => addQuestionnaire()} bg="primary.500" color="white" variant="solid" size="sm">Add questionnaire</Button>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Flex>
  );
}
