import {authApiHeader} from "./AuthHeader";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/profile";

//Picture
export const getPicture = () => {
    return axios.get(API_URL + "/getPicture", { headers: authApiHeader()});
};

export const getPictureAdmin = (params: any) => {
    return axios.get(API_URL + "/getPictureAdmin", { headers: authApiHeader(), params});
};

