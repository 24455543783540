import React, { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  Input,
  Button,
  Image,
  FormErrorMessage,
  VStack,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import backgroundPhoto from '../../assets/loginBackground.png';
import logo from '../../assets/loginLogo.png';
import { ForgotPasswordFormInputs } from '../../types/LoginTypes';
import { requestForgotPassword } from '../../api/SignInService';
import { Link } from 'react-router-dom';

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export function ForgotPassword() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: ForgotPasswordFormInputs) => {
    setIsLoading(true);
    try {
      const response = await requestForgotPassword(data.email);
      if ([200, 201].includes(response.status)) {
        toast({
          title: 'Successfully requested a new Password.',
          position: 'top',
          description: 'A Forgot Password email has been sent to you',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
      }
    } catch (error: any) {
      toast({
        title: 'Failed to send a forgot password email to the user',
        position: 'bottom',
        description: JSON.stringify(
          error?.response?.data?.apierror
            ? error?.response?.data?.apierror?.message
            : error?.message,
        ),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex w="full" h="100vh" alignContent="center" justify="center">
      <Box w={['full', '40%']} h="100vh" fontFamily="Helvetica">
        <VStack spacing={0}>
          <Box pt={['inherit', 120]} pb={['inherit', 10]}>
            <Image src={logo} />
          </Box>
          <form>
            <FormControl
              id="email"
              w="415px"
              p="4"
              isInvalid={!!errors?.email?.message}
              errortext={errors?.email?.message}
            >
              <FormLabel fontSize="xs" color="gray">
                Email
              </FormLabel>
              <Input
                {...register('email')}
                isRequired
                bg="#F9F9F9"
                type="email"
                name="email"
                placeholder="Email"
                size="md"
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <Button
              onClick={handleSubmit(onSubmit)}
              mx="4"
              mt="2"
              mb="1"
              w="93%"
              colorScheme="gostation"
              variant="solid"
              role="enter"
              disabled={!!errors.email || isLoading}
              isLoading={isLoading}
              loadingText="SENDING"
              spinnerPlacement="start"
            >
              SEND
            </Button>

            <Button
              as={Link}
              to="/login"
              mx="4"
              mt="1"
              mb="1"
              w="93%"
              colorScheme="gostation"
              variant="outline"
              size="md"
            >
              BACK TO LOGIN
            </Button>
          </form>
        </VStack>
      </Box>
      <Box w={['0%', '60%']} h="100vh">
        <Image src={backgroundPhoto} />
      </Box>
    </Flex>
  );
}
