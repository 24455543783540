import {authApiHeader} from "./AuthHeader";
import {CustomMessage} from "../types/NotificationTypes";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/notification";

//Messages
export const sendToAll = (body: CustomMessage) => {
    return axios.post(API_URL + "/all", body,{ headers: authApiHeader()});
};

export const sendToGroup = (body: CustomMessage) => {
    return axios.post(API_URL, body,{ headers: authApiHeader()});
};
