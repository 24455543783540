import {authApiHeader} from "./AuthHeader";
import {PromoCode} from "../types/PromoCodeTypes";
import { CancelToken } from "axios";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/promoCode";

//Promos
export const getPromoCodeList = (params: any) => {
    return axios.get(API_URL, { headers: authApiHeader(), params});
};

export const getPromoCodeFindAllCodes = (params: any) => {
    return axios.get(API_URL + "/findAllPromoCodes", { headers: authApiHeader(), params});
};

export const getPromoCodeListByStatus = (params: any) => {
    return axios.get(API_URL + "/filterStatus", { headers: authApiHeader(), params});
};

export const getPromoCodeListWithFilters = (params: any) => {
    return axios.get(API_URL + "/filterProjection", { headers: authApiHeader(), params});
};

export const getPromoCode = (params: any) => {
    return axios.get(API_URL + "/findByCode", { headers: authApiHeader(), params});
};

export const searchPromoCode = (params: any, cancelToken: CancelToken) => {
    return axios.get(API_URL + "/search", { headers: authApiHeader(), params, cancelToken });
};

export const searchPromoCodeByStatus = (params: any, cancelToken: CancelToken) => {
    return axios.get(API_URL + "/searchByStatus", { headers: authApiHeader(), params, cancelToken});
};

export const addPromoCode = (body: PromoCode, params: any) => {
    return axios.post(API_URL, body,{ headers: authApiHeader(), params});
};

export const updatePromoCode = (body: PromoCode, params: any) => {
    return axios.put(API_URL, body,{ headers: authApiHeader(), params});
};
