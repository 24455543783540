import * as React from "react"

import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { Login } from "./pages/login/Login"
import { OnlyAdmins, OnlyAdminsRetailers, OnlySuperAdmin, UserSession } from './types/UserTypes'
import { LoggedInLayout } from "./components/LoggedInLayout";
import { Logout } from "./pages/login/Logout";
import { useSessionContext, getUserSession } from "./context/AuthContext";
import { useEffect } from "react";
import { installInterceptors } from "./api/AuthService";
import { Vehicles } from "./pages/vehicles/Vehicles";
import { VehicleMakeModel } from "./pages/vehicles/VehicleMakeModel";
import { Users } from "./pages/users/Users";
import { getPicture } from "./api/ProfileService";
import { AxiosResponse } from "axios";
import { DashBoard } from "./pages/dashboard/Dashboard";
import { PromoCodes } from "./pages/promocodes/PromoCodes";
import { Ads } from "./pages/ads/Ads";
import { Faqs } from "./pages/faqs/Faqs";
import { PromoCodesDetails } from "./pages/promocodes/PromoCodesDetails";
import { UserDetails } from "./pages/users/UserDetails";
import { ForgotPassword } from "./pages/forgetpassword/ForgotPassword";
import { AutomaticRewards } from "./pages/rewards/AutomaticRewards";
import { Questionnaires } from "./pages/questionnaires/Questionnaires";
import { AdsDetails } from "./pages/ads/AdsDetails";
import { QuestionDetails } from "./pages/questionnaires/QuestionDetails";
import { Notification } from "./pages/notification/Notification";
import { Employers } from "./pages/employers/Employers";
import { ReportUser } from "./pages/reports/ReportUser";
import { ReportQuestionnaire } from "./pages/reports/ReportQuestionnaire";
import { ReportEvse } from "./pages/reports/ReportEvse";
import { ReportAds } from "./pages/reports/ReportAds";
import { ReportPromoCodes } from "./pages/reports/ReportPromoCodes";
import { AppRedirect } from "./pages/station/AppRedirect";

interface IProtectedRouteProps {
  children?: React.ReactNode;
  allowedRoles: string[];
  path: string;
}

interface ILoggedInRouteProps {
  children?: React.ReactNode;
  path: string;
}

function ProtectedRoute({ children, allowedRoles, path }: IProtectedRouteProps) {
  const [currentUser] = useSessionContext();
  return currentUser.isLoggedIn ? ((allowedRoles.some(role => currentUser.userRoles.includes(role))) ? <Route path={path}><LoggedInLayout>{children}</LoggedInLayout></Route> : <Redirect from={path} to="/" />) : <Redirect from={path} to="/login" />;
}

function LoggedInRoute({ children, path }: ILoggedInRouteProps) {
  const [currentUser] = useSessionContext();
  return currentUser.isLoggedIn ? <Redirect exact from={path} to="/dashboard" /> : <Route path={path}>{children}</Route>;
}

function DefaultRoute() {
  const [currentUser] = useSessionContext();
  return currentUser.isLoggedIn ? <Redirect from="/" to="dashboard" /> : <Redirect from="/" to="login" />;
}

export function App() {
  const [, setCurrentUser] = useSessionContext();
  const history = useHistory();

  useEffect(() => {
    const user: UserSession = getUserSession();
    installInterceptors(history, setCurrentUser)
    if (user.isLoggedIn) {
      getPicture().then((response: AxiosResponse) => {
        user.picUrl = response.data
        setCurrentUser(user);
      });
    }
  }, [setCurrentUser, history]);

  return (
    <div>
      <Switch>
        <LoggedInRoute path="/login">
          <Login />
        </LoggedInRoute>
        <LoggedInRoute path='/forgotPassword'>
          <ForgotPassword />
        </LoggedInRoute>
        <Route path="/logout">
          <Logout />
        </Route>
        <ProtectedRoute path='/dashboard' allowedRoles={OnlyAdminsRetailers}>
          <DashBoard />
        </ProtectedRoute>
        <Route path='/station'>
          <AppRedirect />
        </Route>
        <ProtectedRoute path='/users' allowedRoles={OnlyAdmins}>
          <Switch>
            <Route exact path='/users' component={Users} />
            <Route path='/users/add' component={UserDetails} />
            <Route path='/users/:username' component={UserDetails} />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path='/vehicles' allowedRoles={OnlyAdmins}>
          <Switch>
            <Route exact path='/vehicles' component={Vehicles} />
            <Route path='/vehicles/makeModel' component={VehicleMakeModel} />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path='/promoCodes' allowedRoles={OnlySuperAdmin}>
          <Switch>
            <Route exact path='/promoCodes' component={PromoCodes} />
            <Route path='/promoCodes/add' component={PromoCodesDetails} />
            <Route path='/promoCodes/:promoId' component={PromoCodesDetails} />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path='/ads' allowedRoles={OnlySuperAdmin}>
          <Switch>
            <Route exact path='/ads' component={Ads} />
            <Route path='/ads/add' component={AdsDetails} />
            <Route path='/ads/:adId' component={AdsDetails} />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path='/questionnaires' allowedRoles={OnlySuperAdmin}>
          <Switch>
            <Route exact path='/questionnaires' component={Questionnaires} />
            <Route path='/questionnaires/questions/add' component={QuestionDetails} />
            <Route path='/questionnaires/questions/:questionId' component={QuestionDetails} />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path='/notification' allowedRoles={OnlySuperAdmin}>
          <Notification />
        </ProtectedRoute>
        <ProtectedRoute path='/rewards' allowedRoles={OnlySuperAdmin}>
          <AutomaticRewards />
        </ProtectedRoute>
        <ProtectedRoute path='/faqs' allowedRoles={OnlyAdminsRetailers}>
          <Faqs />
        </ProtectedRoute>
        <ProtectedRoute path='/employers' allowedRoles={OnlyAdmins}>
          <Employers />
        </ProtectedRoute>
        <ProtectedRoute path='/reportUser' allowedRoles={OnlyAdmins}>
          <ReportUser />
        </ProtectedRoute>
        <ProtectedRoute path='/reportQuestionnaire' allowedRoles={OnlyAdmins}>
          <ReportQuestionnaire />
        </ProtectedRoute>
        <ProtectedRoute path='/reportEvse' allowedRoles={OnlyAdmins}>
          <ReportEvse />
        </ProtectedRoute>
        <ProtectedRoute path='/reportAds' allowedRoles={OnlyAdmins}>
          <ReportAds />
        </ProtectedRoute>
        <ProtectedRoute path='/reportPromoCodes' allowedRoles={OnlyAdmins}>
          <ReportPromoCodes />
        </ProtectedRoute>
        <DefaultRoute />
      </Switch>
    </div>
  );
}
