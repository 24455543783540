import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    InputGroup,
    Input,
    InputRightElement,
    Icon,
    Button,
    Stack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Spacer,
    IconButton,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    useToast,
    Center,
    VStack,
    ModalOverlay,
    ModalContent,
    ModalHeader, ModalCloseButton, ModalBody, Modal, useDisclosure,
} from "@chakra-ui/react"
import {Link, NavLink, useHistory} from "react-router-dom"
import {BiFilter} from "react-icons/bi";
import {AddIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import {changeSize, initialDescription, isNextPage, isPrevPage, nextPage, prevPage} from "../../utils/pagination";
import {PromoCode, PromoCodeProjection} from "../../types/PromoCodeTypes";
import {useEffect, useState} from "react";
import {
    getPromoCodeListWithFilters,
    searchPromoCode,
    searchPromoCodeByStatus
} from "../../api/PromoCodeService";
import {AxiosResponse, CancelToken} from "axios";
import {EntityListResponse} from "../../types/ApiTypes";
import LoadingOverlay from "react-loading-overlay";
import {CSVDownloader, jsonToCSV} from "react-papaparse";
import {MdFileDownload, MdFileUpload} from "react-icons/all";
import {exportPromoCodes} from "../../api/PromoCodeExportService";
import {ExportPromoCodesData} from "../../types/PromoCodeExportTypes";
import Loader from "../../utils/Loading";
import { debounce } from 'lodash';
import axios from 'axios';
import moment from 'moment';

export function PromoCodes() {
    const toast = useToast();
    const history = useHistory();

    let initialPromoCodes : PromoCodeProjection[] = []

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isActiveOverlay, setIsActiveOverlay] = useState(false);
    const [csvData, setCsvData] = React.useState("")

    const [promosList, setPromosList] = React.useState(initialPromoCodes)
    const [pageRequest, setPageRequest] = React.useState(initialDescription)
    const [totalHits, setTotalHits] = React.useState(0)

    const [statusFilter, setStatusFilter] = useState("ACTIVE");
    const [isLoading, setIsLoading] = useState(false )
    const [cancelTokenSource, setCancelTokenSource] = useState(axios.CancelToken.source());
    const [expiredFilter, setExpiredFilter] = useState("ALL");

    const getStatus = (promoCode: PromoCodeProjection) => {
        if (!promoCode.status) {
            return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#76777A" color="#76777A">INACTIVE</Center>;
        } else {
            return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">ACTIVE</Center>;
        }
    };

    const getExpired = (promoCode: PromoCodeProjection) => {
        const currentDate = new Date().toISOString().split('T')[0];
      
        if (!promoCode.expireDate || moment(promoCode.expireDate).isSameOrAfter(currentDate)) {
            return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">NO</Center>;
        } else {
            return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#EB5757" color="#EB5757">YES</Center>;  
        }
    };

    const searchPromo = React.useCallback(
      debounce((promoCode: string) => {
          // Cancel the previous request
          cancelTokenSource.cancel("Cancelled due to new request");

          // Create a new CancelToken
          const newCancelTokenSource = axios.CancelToken.source();
          setCancelTokenSource(newCancelTokenSource);
          if (statusFilter === 'ALL') {
              searchPromoAll(promoCode, newCancelTokenSource.token)
          } else {
              searchPromoByStatus(promoCode, newCancelTokenSource.token)
          }
      }, 300),
      [statusFilter, cancelTokenSource]
    );

    const handleApiResponse = async (apiCall: any, params: any, cancelToken: CancelToken) => {
      try {
          setIsLoading(true);
          const response = await apiCall(params, cancelToken);
          setIsLoading(false);
          if ([200, 201].includes(response.status)) {
              const entityResponse = response.data;
              setTotalHits(entityResponse.responseDescription.totalHits);
              setPromosList(entityResponse.data);
          } else {
              const error = (response.data && response.data.message) || response.statusText;
              return Promise.reject(error);
          }
      } catch (error) {
          if (!axios.isCancel(error)) {
              setIsLoading(false);
              toastError(error);
          }
      }
    };

    const toastError = (error: any) => {
        toast({
            title: "Search for Promo Codes has failed",
            position: "bottom",
            description: JSON.stringify(error.message || error),
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    };

    const searchPromoAll = (promoCode: string, cancelToken: CancelToken) => {
        const params = {
            page: pageRequest ? pageRequest.page : 0,
            size: pageRequest ? pageRequest.pageSize : 50,
            code: promoCode
        };
        handleApiResponse(searchPromoCode, params, cancelToken);
    };

    const searchPromoByStatus = (promoCode: string, cancelToken: CancelToken) => {
        const params = {
            page: pageRequest ? pageRequest.page : 0,
            size: pageRequest ? pageRequest.pageSize : 50,
            code: promoCode,
            status: statusFilter === 'ACTIVE'
        };
        handleApiResponse(searchPromoCodeByStatus, params, cancelToken);
    };

    useEffect(() => {
      setIsLoading(true);
      let params = {
          page: pageRequest ? pageRequest.page : 0,
          size: pageRequest ? pageRequest.pageSize : 50,
          status: statusFilter !== 'ALL' ? (statusFilter === 'ACTIVE') : undefined,
          expired: expiredFilter !== 'ALL' ? (expiredFilter === 'YES') : undefined
      };
  
      try {
          getPromoCodeListWithFilters(params).then((response: AxiosResponse) => {
              if ([200, 201].includes(response.status)) {
                  let entityResponse: EntityListResponse<PromoCodeProjection> = response.data;
                  setTotalHits(entityResponse.responseDescription.totalHits);
                  setPromosList(entityResponse.data);
                  console.log(promosList.length);
              } else {
                  const error = (response.data && response.data.message) || response.statusText;
                  return Promise.reject(error);
              }
          }).catch((error: any) => {
              toast({
                  title: "Failed to get Promo Codes from backend",
                  position: "bottom",
                  description: JSON.stringify(error.message),
                  status: "error",
                  duration: 5000,
                  isClosable: true,
              });
          }).finally(() => {
              setIsLoading(false);
          });
      } catch (error) {
          toast({
              title: "Failed to get Promo Codes from backend",
              position: "bottom",
              description: JSON.stringify(error.message),
              status: "error",
              duration: 5000,
              isClosable: true,
          });
      }
    }, [statusFilter, expiredFilter, pageRequest, toast]);

    const getPromoExport = () => {
        onOpen()
        setIsActiveOverlay(true)
        exportPromoCodes().then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                let exportData: ExportPromoCodesData[] = response.data
                let config = {
                    header: false
                }
                let arrayHeader = ["Code", "Value", "Value Type", "Type", "Start Date", "Expire Date", "Spend Limit", "Total Spent",
                    "Status", "Expired", "Is User Specific", "# Users Assigned", "# Users Used"];
                let header = arrayHeader.join(",") + '\n';
                let file = header + jsonToCSV(exportData, config)
                setCsvData(file)
                setIsActiveOverlay(false)
            } else {
                setIsActiveOverlay(false)
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            setIsActiveOverlay(false)
            toast({
                title: "Failed to get promo code export data",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    };

    return (
        <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
            <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink as={Link} to="/promoCodes">
                  <Text>Promo Codes</Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Flex>

              <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
                <InputGroup size="sm" w="50%">
                  <Input
                    type="search"
                    name="search"
                    placeholder="Search promo codes"
                    onChange={(e) => { searchPromo(e.target.value) }}
                  />
                  <InputRightElement width="2.5rem">
                    <Icon as={BiFilter} />
                  </InputRightElement>
                </InputGroup>
                <Button ml="auto" leftIcon={<MdFileUpload />} colorScheme="gostation" variant="solid" size="sm" onClick={getPromoExport}>EXPORT</Button>
                <Button as={Link} to="/promoCodes/add" leftIcon={<AddIcon />} ml={2} colorScheme="primary" variant="solid" size="sm">NEW PROMO CODE</Button>
              </Flex>

              {isLoading ? (
                <Loader />
              ) : (
                <>

              {promosList.length > 0 ? (
                <Box>
                  <Box h="6%" minH="42px" ml={6} pl={4} mr={6} pr={4} borderBottomWidth="1px">
                    <Stack direction="row" spacing={1} pt={3}>
                      <Menu>
                        <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
                          Promos/page: {pageRequest.pageSize}
                        </MenuButton>
                        <MenuList>
                          <MenuItem value={10} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>10</MenuItem>
                          <MenuItem value={50} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>50</MenuItem>
                          <MenuItem value={100} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>100</MenuItem>
                          <MenuItem value={9999} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>9999</MenuItem>
                        </MenuList>
                      </Menu>
                      <Menu>
                        <MenuButton as={Button} size="xs">
                          Status Filter: {statusFilter}
                        </MenuButton>
                        <MenuList>
                          <MenuItem value='ALL' onClick={() => { setStatusFilter('ALL') }}>All</MenuItem>
                          <MenuItem value='ACTIVE' onClick={() => { setStatusFilter('ACTIVE') }}>Active</MenuItem>
                          <MenuItem value='INACTIVE' onClick={() => { setStatusFilter('INACTIVE') }}>Inactive</MenuItem>
                        </MenuList>
                      </Menu>
                      <Menu>
                        <MenuButton as={Button} size="xs">
                          Expired Filter: {expiredFilter}
                        </MenuButton>
                        <MenuList>
                        <MenuItem value='ALL' onClick={() => { setExpiredFilter('ALL') }}>All</MenuItem>
                          <MenuItem value='NO' onClick={() => { setExpiredFilter('NO') }}>No</MenuItem>
                          <MenuItem value='YES' onClick={() => { setExpiredFilter('YES') }}>Yes</MenuItem>
                        </MenuList>
                      </Menu>
                      <Spacer />
                      <IconButton
                        variant="outline"
                        aria-label="Previous page"
                        isDisabled={!isPrevPage(pageRequest, totalHits)}
                        boxSize={6}
                        icon={<ChevronLeftIcon />}
                        onClick={() => { prevPage(pageRequest, setPageRequest) }}
                      />
                      <Text pl={1} fontSize="sm">
                        {(pageRequest.pageSize * pageRequest.page)}-{Math.min(pageRequest.pageSize * (pageRequest.page + 1), totalHits)} of {totalHits}
                      </Text>
                      <IconButton
                        variant="outline"
                        isDisabled={!isNextPage(pageRequest, totalHits)}
                        aria-label="Next page"
                        boxSize={6}
                        icon={<ChevronRightIcon />}
                        onClick={() => { nextPage(pageRequest, setPageRequest) }}
                      />
                    </Stack>
                  </Box>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th color="gostation.500">PROMO CODE</Th>
                        <Th color="gostation.500">VALUE</Th>
                        <Th color="gostation.500">TYPE</Th>
                        <Th color="gostation.500">USER SPECIFIC</Th>
                        <Th color="gostation.500">LOCATION RESTRICTED</Th>
                        <Th color="gostation.500">TEXT</Th>
                        <Th color="gostation.500">STATUS</Th>
                        <Th color="gostation.500">EXPIRED</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {promosList.map((promoCode: PromoCodeProjection, i: number) => (
                        <Tr onClick={() => { history.push("/promoCodes/" + promoCode.code) }} key={i} _hover={{ cursor: "pointer" }}>
                          <Td color="gostation.500"><NavLink to={"/promoCodes/" + promoCode.code}>{promoCode.code}</NavLink></Td>
                          {promoCode.valueType === "PERCENTAGE" ? <Td>-{promoCode.value}%</Td> : <Td>$ {promoCode.value}</Td>}
                          <Td>{promoCode.type}</Td>
                          <Td>{promoCode.isUserSpecific ? "YES" : "NO"}</Td>
                          <Td>{promoCode.isLocationRestricted ? "YES" : "NO"}</Td>
                          <Td>{promoCode.text}</Td>
                          <Td>{getStatus(promoCode)}</Td>
                          <Td>{getExpired(promoCode)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ) : (
                <Center ml={4} mr={4} minH={200} borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">
                  <VStack>
                    <Text>No Promo Codes Found</Text>
                    <Button as={Link} to="/promoCodes/add" leftIcon={<AddIcon />} colorScheme="primary" variant="solid" size="sm">NEW PROMO CODE</Button>
                  </VStack>
                </Center>
              )}
              <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none">
                <ModalOverlay />
                <ModalContent>
                  <LoadingOverlay active={isActiveOverlay} spinner text='Creating File...'>
                    <ModalHeader color="gostation.500">Export Promo Code Data as CSV</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <CSVDownloader
                        data={csvData}
                        filename={'promoCodeData'}
                      >
                        <Button mt={2} leftIcon={<MdFileDownload />} colorScheme="gostation" variant="solid" size="sm">DOWNLOAD</Button>
                      </CSVDownloader>
                    </ModalBody>
                  </LoadingOverlay>
                </ModalContent>
              </Modal>
            </>
          )}
        </Box>
      );
}
