enum Gender {
    ALL = "ALL",
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER",
    PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY"
}

const GenderDescriptions: Record<Gender, string> = {
    [Gender.ALL]: "All",
    [Gender.MALE]: "Male",
    [Gender.FEMALE]: "Female",
    [Gender.OTHER]: "Other",
    [Gender.PREFER_NOT_TO_SAY]: "Prefer not to say"
};

export { Gender, GenderDescriptions };
