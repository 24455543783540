import * as React from "react";
import {
  useToast,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  Text,
  Box,
  HStack,
  VStack,
  StackDivider,
  BreadcrumbLink,
  Link
} from "@chakra-ui/react";
import { UnifiedEvsesCsvFileComponentByParameter, UnifiedEvsesCsvFileComponent } from "./ReportEvseComponents";

export const ReportEvse = () => {
  const toast = useToast();

  const getUnifiedEvsesCsvFileComponentByParameter = () => {
    return UnifiedEvsesCsvFileComponentByParameter(toast);
  }

  const getUnifiedEvsesCsvFileComponent = () => {
    return UnifiedEvsesCsvFileComponent(toast);
  }

  return (

    <Box w="100%" h="90%" bg="background.secondary">
    <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
            <BreadcrumbItem color="gostation.500" isCurrentPage>
                <BreadcrumbLink as={Link} to="/reportEvse">
                <Text>Report: Unified CSV File of Go-Station and Roaming EVSEs</Text>
                </BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
    </Flex>

    <Flex pb="10%" h="90%" minH="660px" ml={6} mr={6}>
      <Box p='5'>
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={5}
          align='stretch'
        >
          <Box>
            <HStack spacing="2%">
              <Box>
                {getUnifiedEvsesCsvFileComponent()}
              </Box>
            </HStack>
          </Box>
          <Box>
            {getUnifiedEvsesCsvFileComponentByParameter()}
          </Box>
        </VStack>
      </Box>
    </Flex>
    </Box>
  );
}
