import {LoginAccessToken, LoginFormInputs, LoginResponse} from "../types/LoginTypes";
import {UserSession, UserToken} from "../types/UserTypes";
import {AxiosResponse} from "axios";
import jwt from 'jwt-decode'
import {authApiHeader, authCredentialsHeader} from "./AuthHeader";
import {initialSession} from "../context/AuthContext";
import {getPicture} from "./ProfileService";

const API_URL = process.env.REACT_APP_API_URL + "/oauth/token";
const axios = require('axios').default;

export const login = (loginData: LoginFormInputs) => {

    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', loginData.username);
    params.append('password', loginData.password);

    return axios
        .post(API_URL, params, authCredentialsHeader())
        .then((response: AxiosResponse<LoginResponse>) => {
            return saveUserData(response, loginData.staySignedIn);
        });
};

const jwtRefresh = async () => {

    const user: UserToken = getCurrentUser();

    if (!user || !user.refreshToken) {
        throw new Error('user has not logged in');
    }

    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', user.refreshToken);

    return axios
        .post(API_URL , params, authCredentialsHeader())
        .then((response: AxiosResponse<LoginResponse>) => {
            saveUserData(response, true)
            return response.data;
        });
};

export const logout = () => {
    localStorage.removeItem("user");
};

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user') as string);
};

const saveUserData = (response: AxiosResponse<LoginResponse>, staySignedIn: boolean) => {
    if (response.data.access_token) {
        // TODO: verify if from our backend
        const jwtToken : LoginAccessToken = jwt(response.data.access_token);
        let user : UserToken = {
            username: jwtToken.user_name,
            userRoles: jwtToken.authorities,
            accessToken:  response.data.access_token,
            refreshToken: staySignedIn ? response.data.refresh_token : ""
        }
        localStorage.setItem("user", JSON.stringify(user));
        return getPicture().then((response: AxiosResponse) => {
            let currentUser: UserSession = {
                isLoggedIn: true,
                username: user.username,
                userRoles: user.userRoles,
                picUrl: response.data
            }
            return currentUser
        })
    }
}

export function installInterceptors (history: any, setCurrentUser: any) {
    axios.interceptors.response.use(
        (resp: any) => resp,

        async (error: { config: any; response: any; }) => {
            // refresh token and retry request once more on 401 else log user out

            const {config: originalReq, response} = error

            // skip refresh token request, retry attempts to avoid infinite loops
            if (originalReq && originalReq.url !== API_URL && !originalReq.isRetryAttempt && response && response.status === 401) {
                try {
                    await jwtRefresh()
                    originalReq.isRetryAttempt = true
                    originalReq.headers = authApiHeader()
                    return await axios.request(originalReq)
                } catch (e) {
                    // log user out if fail to refresh (due to expired or missing token) or persistent 401 errors from original requests
                    if (e.message === 'user has not logged in' || (e.response && e.response.status === 401)) {
                        logout()
                        setCurrentUser(initialSession)
                        history.push('/login');
                        return Promise.reject(error);
                    }
                    // suppress original error to throw the new one to get new information
                    throw e
                }
            } else {
                throw error
            }
        }
    )
}
