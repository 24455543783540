import axios, { CancelTokenSource } from "axios";
import { EntityListResponse } from "../types/ApiTypes";
import { User } from "../types/UserTypes";
import {
  searchUserListFiltered
} from "../api/UserService";
import { getApiErrorMessage } from "../utils/ErrorMessage";

let cancelToken: CancelTokenSource;

export async function searchUserByPattern(params: any) {

  if (cancelToken) {
    // If we have a cancel token we cancel the previous request before making a new request
    cancelToken.cancel("Cancelled the previous request");
  }

  cancelToken = axios.CancelToken.source();

  try {
    const response = await searchUserListFiltered(params, cancelToken.token);
    let entityResponse: EntityListResponse<User> = response.data;
    return entityResponse;

  } catch (error) {
    if (!axios.isCancel(error)) {
      return getApiErrorMessage(error);
    }
  }
}
