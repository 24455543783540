import * as React from "react";
import {
  Box,
  HStack,
  Button,
  Heading
} from "@chakra-ui/react";
import { downloadCsvFile } from "../../utils/CsvAid";
import { displayErrorMessage } from "../../utils/PopUpMessage";
import { UploadCsvFile } from "../components/FileUpload";
import {
  downloadMergedEvsesCsvFile as downloadUnifiedEvsesCsvFileApi,
  downloadMergedEvsesCsvFileByParameter as downloadMergedEvsesCsvFileByParameterApi,
} from "../../api/ReportHandlingService";

const get_unified_evses_csv_file_error_message = () => {
  return "Failed to get the unified EVSEs CSV File from backend";
}

const UnifiedEvsesCsvFileComponentByParameter = (toast: any) => {
  const [goStationEvsesCsvFile, setGoStationEvsesCsvFile] = React.useState<File>();
  const [roamingEvsesCsvFile, setRoamingEvsesCsvFile] = React.useState<File>();

  const [csvButtonSwitch, setCsvButtonSwitch] = React.useState(0);

  const downloadUnifiedEvsesCsvFile = () => {
    if (goStationEvsesCsvFile === undefined || roamingEvsesCsvFile === undefined) {
      displayErrorMessage(toast, "Missing CSV File(s)", "Both files need to be provided", "bottom");
    } else {
      // Turn on loading spinning:
      setCsvButtonSwitch(1);
      downloadMergedEvsesCsvFileByParameterApi(goStationEvsesCsvFile, roamingEvsesCsvFile).then(function(response) {
        if (response !== null) {
          downloadCsvFile(response, "unified_gostation_and_roaming_evses.csv");
          // Turn off loading spinning:
          setCsvButtonSwitch(0);
        } else {
          displayErrorMessage(toast, get_unified_evses_csv_file_error_message(), JSON.stringify(response), "bottom");
        }
      });
    }
  }

  const getCsvDownloadButton = () => {
    if (csvButtonSwitch === 0) {
      return (<Button ml="7%" onClick={() => downloadUnifiedEvsesCsvFile()} bg="primary.500" color="white" variant="solid" size="sm">Download Unified EVSEs CSV File</Button>);
    } else if (csvButtonSwitch === 1) {
      return (<Button ml="7%" isLoading loadingText="Processing" colorScheme="teal" variant="outline"></Button>);
    }
  }

  return (
    <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <Box p='10' as='h4' lineHeight='tight'>
        {UploadCsvFile(toast, "Go-Station EVSEs CSV File", setGoStationEvsesCsvFile)}
      </Box>
      <Box p='10' as='h4' lineHeight='tight'>
        {UploadCsvFile(toast, "Roaming EVSEs CSV File", setRoamingEvsesCsvFile)}
      </Box>
      <Box p='10' as='h4' lineHeight='tight'>
        {getCsvDownloadButton()}
      </Box>
    </Box>
  );
}

const UnifiedEvsesCsvFileComponent = (toast: any) => {
  const [csvButtonSwitch, setCsvButtonSwitch] = React.useState(0);

  const downloadUnifiedEvsesCsvFile = () => {
    // Turn on loading spinning:
    setCsvButtonSwitch(1);
    downloadUnifiedEvsesCsvFileApi().then(function(response) {
      if (response !== null) {
        downloadCsvFile(response, "unified_gostation_and_roaming_evses.csv");
        // Turn off loading spinning:
        setCsvButtonSwitch(0);
      } else {
        displayErrorMessage(toast, get_unified_evses_csv_file_error_message(), JSON.stringify(response), "bottom");
      }
    });
  }

  const getCsvDownloadButton = () => {
    if (csvButtonSwitch === 0) {
      return (<Button ml="7%" onClick={() => downloadUnifiedEvsesCsvFile()} bg="primary.500" color="white" variant="solid" size="sm">Download Unified EVSEs CSV File From AWS</Button>);
    } else if (csvButtonSwitch === 1) {
      return (<Button ml="7%" isLoading loadingText="Processing" colorScheme="teal" variant="outline"></Button>);
    }
  }

  return (
    <Box h="40px">
      <HStack spacing="0%">
        <Box>
          <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.100">Unified CSV File of Go-Station and Roaming EVSEs Files from AWS:</Heading>
        </Box>
        <Box>
          {getCsvDownloadButton()}
        </Box>
      </HStack>
    </Box>
  );
}

export { UnifiedEvsesCsvFileComponentByParameter, UnifiedEvsesCsvFileComponent }
