import {PageRequest} from "../types/ApiTypes";


export const initialDescription : PageRequest = {
    page: 0,
    pageSize: 10,
}

export const initialDescriptionShort : PageRequest = {
    page: 0,
    pageSize: 5,
}

export const initialDescriptionQuestionnaire : PageRequest = {
    page: 0,
    pageSize: 8,
}

export const initialDescriptionValidQuestions : PageRequest = {
    page: 0,
    pageSize: 6,
}

export const initialDescriptionInvalidQuestions : PageRequest = {
    page: 0,
    pageSize: 4,
}

export const nextPage = (pageRequest: PageRequest, setPageRequest: (page: PageRequest)=>void) => {
    let newResponse : PageRequest = {
        page: pageRequest.page + 1,
        pageSize: pageRequest.pageSize,
    }
    setPageRequest(newResponse)
};

export const prevPage = (pageRequest: PageRequest, setPageRequest: (page: PageRequest)=>void) => {
    let newResponse : PageRequest = {
        page: pageRequest.page - 1,
        pageSize: pageRequest.pageSize,
    }
    setPageRequest(newResponse)
};

export const isPrevPage = (pageRequest: PageRequest, totalHits: number) => {
    return (pageRequest && pageRequest.page > 0 && totalHits > pageRequest.pageSize)
};

export const isNextPage = (pageRequest: PageRequest, totalHits: number) => {
    return (pageRequest && totalHits > (pageRequest.page + 1) * pageRequest.pageSize)
};

export const changeSize = (element: HTMLButtonElement, pageRequest: PageRequest, setPageRequest: (page: PageRequest)=>void) => {
    let newResponse : PageRequest = {
        page: pageRequest.page,
        pageSize: Number(element.value),
    }
    setPageRequest(newResponse)
};