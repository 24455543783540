import {authApiHeader} from "./AuthHeader";
import {Question} from "../types/QuestionnaireTypes";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/question";

//Questions
export const getQuestionsList = (params: any) => {
    return axios.get(API_URL + "/statusFilter", { headers: authApiHeader(), params});
};

export const getQuestion = (params: any) => {
    return axios.get(API_URL + "/findByKey", { headers: authApiHeader(), params});
};

export const searchQuestion = (params: any) => {
    return axios.get(API_URL + "/search", { headers: authApiHeader(), params});
};

export const addQuestion = (body: Question) => {
    return axios.post(API_URL, body,{ headers: authApiHeader()});
};

export const updateQuestion = (body: Question) => {
    return axios.put(API_URL, body,{ headers: authApiHeader()});
};