import * as React from "react";
import {
  useToast,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  Text,
  Box,
  HStack,
  VStack,
  StackDivider,
  Button,
  Link,
  BreadcrumbLink,
  Select
} from "@chakra-ui/react";
import { MdArrowDropDown } from 'react-icons/md';
import { BiLoader } from 'react-icons/bi';
import { AxiosResponse } from 'axios';
import { downloadCsvFile } from "../../utils/CsvAid";
import { displayErrorMessage } from "../../utils/PopUpMessage";
import { PromoCodeProjection } from "../../types/PromoCodeTypes";
import { getPromoCodeFindAllCodes } from "../../api/PromoCodeService";
import { downloadPromoCodeCsv, downloadPromoCodesCsv } from "../../api/ReportHandlingService";

export const ReportPromoCodes: React.FC = () => {
  const toast = useToast();

  const [promoCodesList, setPromoCodesList] = React.useState<PromoCodeProjection[]>([]);
  const [selectedPromoCode, setSelectedPromoCode] = React.useState<string>("");
  const [csvButtonSwitch, setCsvButtonSwitch] = React.useState<number>(0);
  const [csvAllButtonSwitch, setAllCsvButtonSwitch] = React.useState<number>(0);

  const LoadAllPromoCodes = (): void => {
    getPromoCodeFindAllCodes({ page: 0, size: 50 }).then((response: AxiosResponse) => {
      if ([200, 201].includes(response.status) && response.data) {
        let entityResponse = response.data as { data: PromoCodeProjection[] }; 
        setPromoCodesList(entityResponse.data);
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        displayErrorMessage(toast, "Failed to get Promo Codes from backend", JSON.stringify(error), "bottom");
      }
    }).catch((error: any) => {
      displayErrorMessage(toast, "Error", JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message), "bottom");
    });
  };

  React.useEffect(() => {
    LoadAllPromoCodes();
  }, []);

  const downloadPromoCodeCsvReport = (): void => {
    setCsvButtonSwitch(1);
    downloadPromoCodeCsv(selectedPromoCode).then(function(response) {
      if (response !== null) {
        downloadCsvFile(response, 'promocode_'+selectedPromoCode.toLowerCase().replace(/ /g, '_') + '.csv');
        setCsvButtonSwitch(0);
      } else {
        displayErrorMessage(toast, "Failed to get Promo Codes from backend", JSON.stringify(response), "bottom");
      }
    });
  };

  const downloadAllPromoCodesCsvReport = (): void => {
    setAllCsvButtonSwitch(1);
    downloadPromoCodesCsv().then(function(response) {
      if (response !== null) {
        downloadCsvFile(response, 'promocodes_data.csv');
        setAllCsvButtonSwitch(0);
      } else {
        displayErrorMessage(toast, "Failed to get Promo Codes from backend", JSON.stringify(response), "bottom");
      }
    });
  };

  const getSelectComponent = (list: PromoCodeProjection[], setSelected: React.Dispatch<React.SetStateAction<string>>, placeholder: string): JSX.Element => {
    return (
      <Select minW="240px" icon={list.length > 0 ? <MdArrowDropDown /> : <BiLoader />} placeholder={placeholder} isDisabled={list.length === 0} onChange={(e) => setSelected(e.target.value)}>
        {list.map((item: PromoCodeProjection, index: number) => {
          return (<option key={index} value={item.code}>{item.code}</option>);
        })}
      </Select>
    );
  };

  const getCsvButtonComponent = (): JSX.Element => {
    if (csvButtonSwitch === 0) {
      return (
        <Button
          ml="7%"
          onClick={() => downloadPromoCodeCsvReport()}
          bg="primary.500"
          color="white"
          variant="solid"
          size="sm"
          isDisabled={selectedPromoCode === ""}>
          Download CSV
        </Button>
      );
    } else {
      return (<Button isLoading loadingText='Processing' colorScheme='teal' variant='outline'></Button>);
    }
  };

  const getAllCsvButtonComponent = (): JSX.Element => {
    if (csvAllButtonSwitch === 0) {
      return (
        <Button
          ml="7%"
          onClick={() => downloadAllPromoCodesCsvReport()}
          bg="primary.500"
          color="white"
          variant="solid"
          size="sm"
          isDisabled={selectedPromoCode !== ""}>
          Download All Promo Codes CSV
        </Button>
      );
    } else {
      return (<Button isLoading loadingText='Processing' colorScheme='teal' variant='outline'></Button>);
    }
  };

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
          <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
              <BreadcrumbItem color="gostation.500" isCurrentPage>
                  <BreadcrumbLink as={Link} to="/reportPromoCodes">
                  <Text>Report: Promo Codes Data Export</Text>
                  </BreadcrumbLink>
              </BreadcrumbItem>
          </Breadcrumb>
      </Flex>

    <Flex pb="10%" h="90%" minH="660px" ml={6} mr={6}>
      <Box p='5'>
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={5}
          align='stretch'
        >
          <Box h='40px'>
              <HStack spacing="2%">
                <Box>
                  {getSelectComponent(promoCodesList, setSelectedPromoCode, 'Select a Promo Code')}
                </Box>
                <Box>
                  {getCsvButtonComponent()}
                </Box>
                <Box>
                  {getAllCsvButtonComponent()}
                </Box>
              </HStack>
            </Box>
        </VStack>
      </Box>
    </Flex>
    </Box>
  );
}
