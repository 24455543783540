import * as React from "react";
import { BiFilter } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  Flex,
  Box,
  Input,
  Button,
  Text,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  InputRightElement,
  InputGroup,
  Stack,
  Spacer,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Checkbox
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, LinkIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { changeSize, isNextPage, isPrevPage, prevPage, nextPage } from "../../utils/pagination";
import { UserEmployer, EmployerStatus } from "../../types/EmployerTypes";
import { displayInfoMessage } from "../../utils/PopUpMessage";
import { PageRequest } from "../../types/ApiTypes";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import Loader from "../../utils/Loading";
import {
  isStatusValidForEnroll,
  isThereAnyPendingStatus,
  filterByStatus,
  isThereAnyCheckedItem,
  handleCheckboxObjects,
  searchUserEmployersByPattern,
  enrollUsers,
  enrollCheckedUserEmployers
} from "../../api/EmployerHandlingService";
import { AnyAction } from "@reduxjs/toolkit";
import { debounce } from 'lodash';

const UserEmployers = (toast: any, pageRequest: PageRequest, totalHits: number,
                       employerStatus: EmployerStatus,
                       userEmployers: UserEmployer[],
                       checkedUserEmployers: UserEmployer[],
                       checkedItems: boolean[],
                       allChecked: boolean,
                       isIndeterminate: boolean,
                       dispatch: React.Dispatch<AnyAction>,
                       setPageRequest: (pageRequest: PageRequest) => void,
                       setTotalHits: (totalHits: number) => void,
                       setEmployerStatus: (employerStatus: EmployerStatus) => void,
                       setUserEmployers: (userEmployers: UserEmployer[]) => void,
                       setCheckedUserEmployers: (userEmployers: UserEmployer[]) => void,
                       setCheckedItems: (checkedItems: boolean[]) => void) => {

  const isLoading = useSelector((state: RootState) => state.loading && state.loading.isLoading)

  const getUsersByPageMenu = () => {
    return <Menu>
      <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
        Users/page: {pageRequest.pageSize}
      </MenuButton>
      <MenuList>
        <MenuItem value={10} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>10</MenuItem>
        <MenuItem value={50} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>50</MenuItem>
        <MenuItem value={100} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>100</MenuItem>
        <MenuItem value={9999} onClick={(e) => { changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest) }}>9999</MenuItem>
      </MenuList>
    </Menu>
  }

  const getUserStatusMenu = () => {
    return <Menu>
      <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
        User status: {employerStatus === EmployerStatus.ALL ? "ALL" : employerStatus}
      </MenuButton>
      <MenuList>
        <MenuItem value={EmployerStatus.ALL} onClick={(e) => { filterByStatus(toast, pageRequest, e.target as HTMLButtonElement, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedItems, dispatch) }}>All</MenuItem>
        <MenuItem value={EmployerStatus.ENROLLED} onClick={(e) => { filterByStatus(toast, pageRequest, e.target as HTMLButtonElement, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedItems, dispatch) }}>Enrolled</MenuItem>
        <MenuItem value={EmployerStatus.INACTIVE} onClick={(e) => { filterByStatus(toast, pageRequest, e.target as HTMLButtonElement, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedItems, dispatch) }}>Inactive</MenuItem>
        <MenuItem value={EmployerStatus.PENDING} onClick={(e) => { filterByStatus(toast, pageRequest, e.target as HTMLButtonElement, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedItems, dispatch) }}>Pending</MenuItem>
      </MenuList>
    </Menu>
  }

  const getEnrollmentMainCheckbox = () => {
    if (EmployerStatus.ENROLLED === employerStatus || EmployerStatus.INACTIVE === employerStatus || !isThereAnyPendingStatus(userEmployers)) {
      return <Checkbox
        isDisabled
        isChecked={ allChecked }
        isIndeterminate={ isIndeterminate }
        spacing="-2">
        <span className="css-1vv1vye">ALL</span>
      </Checkbox>
    }
    return <Checkbox
      isChecked={ allChecked }
      isIndeterminate={ isIndeterminate }
      onChange={ (e) => setCheckedItems(userEmployers.map(() => e.target.checked)) }
      spacing="-2">
      <span className="css-1vv1vye">ALL</span>
    </Checkbox>
  }

  const getEnrollmentCheckbox = (userEmployer: UserEmployer, index: number) => {
    handleCheckboxObjects(checkedUserEmployers, checkedItems, userEmployer, index)

    if (isStatusValidForEnroll(userEmployer.status)) {
      return <Checkbox
        key={index}
        isChecked={checkedItems[index]}
        onChange = { (e) => setCheckedItems([...checkedItems.slice(0, index), e.target.checked, ...checkedItems.slice(index + 1)]) }>
      </Checkbox>
    }
    return <Checkbox
      isDisabled
      key={index}
      isChecked={ false }>
    </Checkbox>
  }

  const getEnrollmentButton = (userEmployer: UserEmployer) => {
    if (EmployerStatus.PENDING === userEmployer?.status) {
      let userEmployers: UserEmployer[] = []
      userEmployers.push(userEmployer)
      return <Button onClick={() => { enrollUsers(toast, pageRequest, userEmployers, employerStatus, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedItems) }} leftIcon={<LinkIcon />} ml={2} colorScheme="primary" variant="solid" size="sm">ENROLL</Button>
    } else {
      return <Button onClick={() => { displayInfoMessage(toast, "User enrollment", "The user: " + userEmployer.username + " is already enrolled.", "bottom") }} leftIcon={<NotAllowedIcon />} ml={2} colorScheme="primary" variant="ghost" size="sm">ENROLL</Button>
    }
  }

  const getEnrollmentButtonWhenItemChecked = () => {
    if (isThereAnyCheckedItem(checkedItems)) {
      return <Button onClick={() => { enrollCheckedUserEmployers(toast, pageRequest, checkedUserEmployers, employerStatus, setEmployerStatus, setTotalHits, setUserEmployers, setCheckedUserEmployers, setCheckedItems) }} leftIcon={<LinkIcon />} colorScheme="primary" variant="solid" size="xs" mr="15px" ml="0px">ENROLL SELECTED</Button>
    }
  }

  const debouncedSearchUserEmployersByPattern = React.useCallback(
    debounce((pattern: string) => {
      searchUserEmployersByPattern(toast, pageRequest, pattern, setTotalHits, setUserEmployers, setCheckedItems, dispatch)
    }, 500),
    [searchUserEmployersByPattern]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearchUserEmployersByPattern(e.target.value);
   };

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/employers">
              <Text>Employer Enrollment</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
        <InputGroup size="sm" w="50%" >
          <Input
            type="search"
            name="search"
            placeholder="Search users"
            onChange={handleInputChange}
          />
          <InputRightElement width="2.5rem">
            <Icon as={BiFilter} />
          </InputRightElement>
        </InputGroup>
      </Flex>
      <Box h="6%" ml={6} pl={4} mr={6} pr={4} borderBottomWidth="1px">
        <Stack direction="row" spacing={1} pt={3}>
          { getEnrollmentButtonWhenItemChecked() }
          { getUsersByPageMenu() }
          { getUserStatusMenu() }
          <Spacer />
          <IconButton
            variant="outline"
            aria-label="Previous page"
            isDisabled={!isPrevPage(pageRequest, totalHits)}
            boxSize={6}
            icon={<ChevronLeftIcon />}
            onClick={() => { prevPage(pageRequest, setPageRequest) }}
          />
          <Text pl={1} fontSize="sm">
            { (pageRequest.pageSize * pageRequest.page) } - { Math.min(pageRequest.pageSize * (pageRequest.page + 1), totalHits) } of { totalHits }
          </Text>
          <IconButton
            variant="outline"
            isDisabled={ !isNextPage(pageRequest, totalHits) }
            aria-label="Next page"
            boxSize={6}
            icon={<ChevronRightIcon />}
            onClick={() => { nextPage(pageRequest, setPageRequest) }}
          />
        </Stack>
      </Box>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th color="gostation.500">
              { getEnrollmentMainCheckbox() }
            </Th>
            <Th color="gostation.500">USERNAME</Th>
            <Th color="gostation.500">EMAIL</Th>
            <Th color="gostation.500">LOCATION ID</Th>
            <Th color="gostation.500">NETWORK ID</Th>
            <Th color="gostation.500">EMAIL VALIDATED</Th>
            <Th color="gostation.500">STATUS</Th>
            <Th color="gostation.500">ENROLLMENT</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? <Loader /> : (
            <>
              {userEmployers && userEmployers.map((userEmployer: UserEmployer, index: number) => {
                return (
                  <Tr key={index}>
                    <Td>{ getEnrollmentCheckbox(userEmployer, index) }</Td>
                    <Td>{ userEmployer.username }</Td>
                    <Td>{ userEmployer.email }</Td>
                    <Td>{ userEmployer.selectedLocation?.locationId }</Td>
                    <Td>{ userEmployer.selectedLocation?.networkId }</Td>
                    <Td>{ userEmployer?.isEmailValidated ? "Yes" : "No" }</Td>
                    <Td>{ userEmployer?.status }</Td>
                    <Td>{ getEnrollmentButton(userEmployer) }</Td>
                  </Tr>
                );
              })}
            </>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
export { UserEmployers }
