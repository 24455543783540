import { extendTheme } from "@chakra-ui/react";

const colors = {
  background: {
    primary: '#F6F9FB',
    secondary: '#FFFFFF'
  },
  gostation: {
    50: '#e5eaf4',
    100: '#6491E4',
    200: '#5E8BDF',
    300: '#4c71b4',
    400: '#325daa',
    500: '#0C4D95', // this is the original blue
    600: '#007AFF',
    700: '#002568',
    800: '#001a4a',
    900: '#00153b',
  },
  primary: {
    50: '#eef8ec',
    100: '#E5FCF1',
    200: '#27EF96',
    300: '#10DE82',
    400: '#0EBE6F',
    500: '#63D039', // this is the original green
    600: '#0A864F',
    700: '#086F42',
    800: '#075C37',
    900: '#064C2E',
  }
};

export const customTheme = extendTheme({
  colors,
  components: {
    Breadcrumb: {
      baseStyle: {
        link: {
          paddingTop: "20px",
          paddingBottom: "20px",
          color: "gostation.500",
          _hover: {
            textDecoration: "none",
          },
        },
        container: {
          bg: "background.primary",
          width: "calc(100% + 24px)",
          paddingLeft: "20px",
          marginLeft: "-24px",
          marginBottom: "10px"
        },
      },
    },
  },
});

