import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    InputGroup,
    Input,
    InputRightElement,
    Icon,
    Button,
    Stack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Spacer, IconButton, Table, Thead, Tr, Th, Tbody, Td, useToast, Center, VStack,
} from "@chakra-ui/react"
import {Link, useHistory} from "react-router-dom"
import {BiFilter} from "react-icons/bi";
import {AddIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import {changeSize, initialDescription, isNextPage, isPrevPage, nextPage, prevPage} from "../../utils/pagination";
import {Ad} from "../../types/AdsTypes";
import {getAdsList, searchAd} from "../../api/AdService";
import {AxiosResponse} from "axios";
import {EntityListResponse} from "../../types/ApiTypes";
import {useEffect} from "react";
import Loader from "../../utils/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import { setLoading } from '../../actions/loadingActions';
import { debounce } from 'lodash';

export function Ads() {
    const toast = useToast();
    const history = useHistory();

    let initialAds : Ad[] = []

    const [adsList, setAdsList] = React.useState(initialAds)
    const [pageRequest, setPageRequest] = React.useState(initialDescription)
    const [totalHits, setTotalHits] = React.useState(0)
    const dispatch = useDispatch()
    const isLoading = useSelector((state: RootState) => state.loading.isLoading)

    const getStatus = (active: boolean) => {
        if (!active) {
            return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#EB5757" color="#EB5757">INACTIVE</Center>;
        } else {
            return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">ACTIVE</Center>;
        }
    };

    const searchAds = (name: string) => {
        let params = {
            page: pageRequest ? pageRequest.page : 0,
            size: pageRequest ? pageRequest.pageSize : 50,
            name: name
        }
        dispatch(setLoading(true));
        searchAd(params).then((response: AxiosResponse) => {
            dispatch(setLoading(false));

            if ([200, 201].includes(response.status)) {
                let entityResponse: EntityListResponse<Ad> = response.data
                setTotalHits(entityResponse.responseDescription.totalHits)
                setAdsList(entityResponse.data);
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }
        }).catch( (error: any) => {
            dispatch(setLoading(false));
            toast({
                title: "Failed to get Ads from backend",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })})
    };

    useEffect(() => {
        dispatch(setLoading(true));

        let params = {
            page: pageRequest ? pageRequest.page : 0,
            size: pageRequest ? pageRequest.pageSize : 50
        }
        getAdsList(params).then((response: AxiosResponse) => {
            dispatch(setLoading(true));

            if ([200, 201].includes(response.status)) {
                let entityResponse: EntityListResponse<Ad> = response.data
                setTotalHits(entityResponse.responseDescription.totalHits)
                setAdsList(entityResponse.data);
            } else {
                const error = (response.data && response.data.message) || response.statusText;
                return Promise.reject(error);
            }

        }).catch( (error: any) => {
            toast({
                title: "Failed to get Ads from backend",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 5000,
                isClosable: true,
            })})
            .finally(() => {
                dispatch(setLoading(false));
});
    }, [pageRequest, toast, dispatch]);


    const debouncedSearchAds = React.useCallback(
        debounce((pattern: string) => {
          searchAds(pattern)
        }, 500),
        [searchAds]
      );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearchAds(e.target.value);
    };

    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem isCurrentPage>
                      <BreadcrumbLink as={Link} to="/ads">
                          <Text>Ads</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          {(adsList.length > 0 || isLoading) ? (
          <Box>
          <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
              <InputGroup size="sm" w="50%" >
                  <Input
                      type="search"
                      name="search"
                      placeholder="Search ads"
                      onChange={handleInputChange}
                  />
                  <InputRightElement width="2.5rem">
                      <Icon as={BiFilter}/>
                  </InputRightElement>
              </InputGroup>
              <Button as={Link} to="/ads/add" leftIcon={<AddIcon />} ml="auto" colorScheme="primary" variant="solid" size="sm">NEW AD</Button>
          </Flex>
          <Box h="6%" ml={6} pl={4} mr={6} pr={4} borderBottomWidth="1px">
              <Stack direction="row" spacing={1} pt={3}>
                  <Menu>
                      <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
                          Ads/page: {pageRequest.pageSize}
                      </MenuButton>
                      <MenuList>
                          <MenuItem value={10} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>10</MenuItem>
                          <MenuItem value={50} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>50</MenuItem>
                          <MenuItem value={100} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>100</MenuItem>
                          <MenuItem value={9999} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>9999</MenuItem>
                      </MenuList>
                  </Menu>
                  <Spacer/>
                  <IconButton
                      variant="outline"
                      aria-label="Previous page"
                      isDisabled={!isPrevPage(pageRequest, totalHits)}
                      boxSize={6}
                      icon={<ChevronLeftIcon />}
                      onClick={() => {prevPage(pageRequest, setPageRequest)}}
                  />
                  <Text pl={1} fontSize="sm">
                      {(pageRequest.pageSize * pageRequest.page)}-{Math.min(pageRequest.pageSize * (pageRequest.page +1), totalHits)} of {totalHits}
                  </Text>
                  <IconButton
                      variant="outline"
                      isDisabled={!isNextPage(pageRequest, totalHits)}
                      aria-label="Next page"
                      boxSize={6}
                      icon={<ChevronRightIcon />}
                      onClick={() => {nextPage(pageRequest, setPageRequest)}}
                  />
              </Stack>
          </Box>
          {isLoading ? (
                < Loader />
            ) : (
          <Table variant="simple" size="sm">
              <Thead>
                  <Tr>
                      <Th color="gostation.500">NAME</Th>
                      <Th color="gostation.500">CATEGORY</Th>
                      <Th color="gostation.500">TYPE</Th>
                      <Th color="gostation.500">STATUS</Th>
                  </Tr>
              </Thead>
              <Tbody>
                  {adsList && adsList.map((ad: Ad , i: number) => {
                      return (
                          <Tr onClick={() => {history.push("/ads/" + ad.name)}} key={i} _hover={{cursor: "pointer"}}>
                              <Td>{ad.name}</Td>
                              <Td>{ad.categoryName}</Td>
                              <Td>{ad.type}</Td>
                              <Td>{getStatus(ad.active as boolean)}</Td>
                          </Tr>)
                  })}
              </Tbody>
          </Table>
          )}
          </Box>) : (<Center ml={4} mr={4} minH={200} borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">
            <VStack>
                <Text>No Ads</Text>
                <Button as={Link} to="/ads/add" leftIcon={<AddIcon />} colorScheme="primary" variant="solid" size="sm">NEW AD</Button>
            </VStack>
          </Center>

      )}
      </Box>
  );
}
