import * as React from "react";
import { Link as ReactLink, NavLink } from "react-router-dom";
import { GrEdit, GrTrash, GrMenu } from "react-icons/all";
import {
  Flex,
  Box,
  Text,
  Heading,
  VStack,
  HStack,
  Center,
  Button,
  List,
  ListItem,
  ListIcon,
  Spacer,
  Stack,
  IconButton,
  Link
} from "@chakra-ui/react";
import { AddIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  initialDescriptionInvalidQuestions,
  initialDescriptionQuestionnaire,
  initialDescriptionValidQuestions, isNextPage,
  isPrevPage, nextPage,
  prevPage
} from "../../utils/pagination";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot, DraggableId
} from "react-beautiful-dnd";
import Loader from "../../utils/Loading";
import { addQuestionnaireQuestions } from "../../api/QuestionnaireHandlingService";
import { Question, QuestionnaireQuestion } from "../../types/QuestionnaireTypes";
import { displaySuccessMessage, displayErrorMessage } from "../../utils/PopUpMessage";

export const QuestionnaireQuestions = (toast: any,
                                       history: any,
                                       selectedQuestionnaireName: string,
                                       questionnaireQuestionsList: Question[],
                                       activeQuestionsList: Question[],
                                       inactiveQuestionsList: Question[],
                                       pageRequestQuestionnaire: any,
                                       pageRequestActive: any,
                                       pageRequestInactive: any,
                                       totalHitsQuestionnaire: number,
                                       totalHitsActive: number,
                                       totalHitsInactive: number,
                                       isLoadingQuestionnaireQuestions: boolean,
                                       isLoadingActiveQuestions: boolean,
                                       isLoadingInactiveQuestions: boolean,
                                       setQuestionnaireQuestionsList: (questionnaireQuestionsList: Question[]) => void,
                                       setActiveQuestionsList: (activeQuestionsList: Question[]) => void,
                                       setInactiveQuestionsList: (inactiveQuestionsList: Question[]) => void,
                                       setPageRequestQuestionnaire: (pageRequestQuestionnaire: any) => void,
                                       setPageRequestActive: (pageRequestActive: any) => void,
                                       setPageRequestInactive: (pageRequestInactive: any) => void,
                                       setTotalHitsQuestionnaire: (totalHitsQuestionnaire: number) => void,
                                       setTotalHitsActive: (totalHitsActive: number) => void,
                                       setTotalHitsInactive: (totalHitsInactive: number) => void) => {
  const saveAction = () => {
    let questionnaireQuestions: QuestionnaireQuestion[] = [];
    questionnaireQuestionsList.forEach((question: Question , index: number) => {
      let questionnaireQuestion: QuestionnaireQuestion = {
        key: question.key,
        order: index
      }
      questionnaireQuestions.push(questionnaireQuestion);
    });
    insertQuestionnaireQuestions(selectedQuestionnaireName, questionnaireQuestions);
  }

  const insertQuestionnaireQuestions = (questionnaireName: string, questionnaireQuestions: QuestionnaireQuestion[]) => {
    addQuestionnaireQuestions(questionnaireName, questionnaireQuestions).then(function(response) {
      if (response === true) {
        displaySuccessMessage(toast, "Questionnaire", "Questions added successfully to the Questionnaire", "top");
        setPageRequestQuestionnaire({ ...initialDescriptionQuestionnaire });
        history.push('/questionnaires');
      } else {
        displayErrorMessage(toast, "Failed to add questionnaire questions", JSON.stringify(response), "bottom");
      }
    });
  }

  const deleteQuestionnaireQuestion = (index: number) => {
    const temp = [...questionnaireQuestionsList];
    temp.splice(index, 1);
    setQuestionnaireQuestionsList(temp);
  }

  const clearState = () => {
    let emptyQuestionArray: Question[] = [];
    let activeEmptyQuestionArray: Question[] = [];
    let inactiveEmptyQuestionArray: Question[] = [];

    setQuestionnaireQuestionsList({ ...emptyQuestionArray });
    setActiveQuestionsList({ ...activeEmptyQuestionArray });
    setInactiveQuestionsList({ ...inactiveEmptyQuestionArray });
    setPageRequestQuestionnaire({ ...initialDescriptionQuestionnaire });
    setPageRequestActive({ ...initialDescriptionValidQuestions });
    setPageRequestInactive({ ...initialDescriptionInvalidQuestions });
    setTotalHitsQuestionnaire(0);
    setTotalHitsActive(0);
    setTotalHitsInactive(0);
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId === "activeList") {
      return;
    }

    if (result.source.droppableId === "questionnaire") {
      if (result.destination.index === result.source.index) {
        return;
      }
      const temp = [...questionnaireQuestionsList];
      const [reorderedItem] = temp.splice(result.source.index, 1);
      temp.splice(result.destination.index, 0, reorderedItem);

      setQuestionnaireQuestionsList(temp);
    } else {
      const temp = [...questionnaireQuestionsList];
      const newItem = { ...activeQuestionsList[result.source.index] };
      let index = temp.findIndex(q => q.key === newItem.key);
      if (index < 0) {
        temp.splice(result.destination.index, 0, newItem);
      } else {
        const [reorderedItem] = temp.splice(index, 1);
        temp.splice(result.destination.index > index ? result.destination.index - 1 : result.destination.index, 0, reorderedItem);
      }
      setQuestionnaireQuestionsList(temp);
    }
  }

  return (
    <Flex w="96%" h="90%" minH="660px" ml={0} mr={6}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <HStack>
          <VStack minW="550px" minH="660px" pl={4} pr={4} bg="white" alignItems="left">
            <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Selected Questions</Heading>
            {questionnaireQuestionsList.length > 0 ? (
  <Box>
    <Droppable droppableId="questionnaire" direction="vertical">
      {(droppableProvided: DroppableProvided) => (
        <List spacing={3} variant="simple" size="sm"
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}>
          {questionnaireQuestionsList && questionnaireQuestionsList.map((question : Question, i: number) => {
            return (
              <Draggable
                key={question.key}
                draggableId={question.key as DraggableId}
                index={i}
              >
                {(
                  draggableProvided: DraggableProvided,
                  snapshot: DraggableStateSnapshot
                ) => {
                  return (
                    <ListItem bg="#F9F9F9" fontWeight="bold" fontSize="md" color="gostation.500" p={3} key={i}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={{
                        ...draggableProvided.draggableProps.style,
                          background: snapshot.isDragging
                            ? "rgba(245,245,245, 0.75)"
                            : "none"
                      }}>
                      <ListIcon as={GrMenu} />
                      {question.text}
                      <ListIcon as={GrTrash} float="right" m={1} _hover={{cursor: "pointer"}} onClick={() => deleteQuestionnaireQuestion(i)}/>
                      <Link as={NavLink} to={"/questionnaires/questions/" + question.key} float="right"><ListIcon as={GrEdit}/></Link>
                    </ListItem>
                  );
                }}
              </Draggable>
            )
          })}
          {droppableProvided.placeholder}
        </List>
      )}
    </Droppable>
    <Box h="6%" pr={2}>
      <Stack direction="row" spacing={1} pt={3}>
        <Spacer/>
        <IconButton variant="outline" aria-label="Previous page" isDisabled={!isPrevPage(pageRequestQuestionnaire, totalHitsQuestionnaire)} boxSize={6}
          icon={<ChevronLeftIcon />} onClick={ () => {prevPage(pageRequestQuestionnaire, setPageRequestQuestionnaire)} }/>
        <Text pl={1} fontSize="sm">
          { (pageRequestQuestionnaire.pageSize * pageRequestQuestionnaire.page) } - { Math.min(pageRequestQuestionnaire.pageSize * (pageRequestQuestionnaire.page +1), totalHitsQuestionnaire) } of { totalHitsQuestionnaire }
        </Text>
        <IconButton variant="outline" isDisabled={!isNextPage(pageRequestQuestionnaire, totalHitsQuestionnaire)} aria-label="Next page"
          boxSize={6} icon={<ChevronRightIcon />} onClick={ () => {nextPage(pageRequestQuestionnaire, setPageRequestQuestionnaire)} }/>
      </Stack>
    </Box>
  </Box>) :
  (<Box>
    <Droppable droppableId="questionnaire" direction="vertical">
      {(droppableProvided: DroppableProvided) => (
        <List spacing={3} variant="simple" size="sm"
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}>
          <Center ml={4} mr={4} minH={200} position='relative' borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">
            {isLoadingQuestionnaireQuestions ? (
              <div className="custom-loader"></div>
            ) : (
              <VStack>
                <Heading fontWeight="bold" size="md">No Questions</Heading>
                <Text fontSize="md" p={2}>Drag and drop question from the list on the right</Text>
              </VStack>
            )}
          </Center>
          {droppableProvided.placeholder}
        </List>
      )}
    </Droppable>
    <Box h="6%" pr={2}>
      <Stack direction="row" spacing={1} pt={3}>
        <Spacer/>
        <IconButton variant="outline" aria-label="Previous page" isDisabled={!isPrevPage(pageRequestQuestionnaire, totalHitsQuestionnaire)} boxSize={6}
          icon={<ChevronLeftIcon />} onClick={ () => {prevPage(pageRequestQuestionnaire, setPageRequestQuestionnaire)} }/>
        <Text pl={1} fontSize="sm">
          { (pageRequestQuestionnaire.pageSize * pageRequestQuestionnaire.page) } - { Math.min(pageRequestQuestionnaire.pageSize * (pageRequestQuestionnaire.page +1), totalHitsQuestionnaire) } of { totalHitsQuestionnaire }
        </Text>
        <IconButton variant="outline" isDisabled={!isNextPage(pageRequestQuestionnaire, totalHitsQuestionnaire)} aria-label="Next page"
          boxSize={6} icon={<ChevronRightIcon />} onClick={ () => {nextPage(pageRequestQuestionnaire, setPageRequestQuestionnaire)} }/>
      </Stack>
    </Box>
  </Box>)}
            <Spacer/>
            <HStack spacing={6} p={4}>
              <Button onClick={ clearState } colorScheme="gostation" variant="outline" size="md">CANCEL</Button>
              <Button onClick={ () => saveAction(toast, history, initialDescriptionQuestionnaire, questionnaireQuestionsList, setPageRequestQuestionnaire) } mr="auto" colorScheme="gostation" variant="solid" size="md" isDisabled={ selectedQuestionnaireName === "" }>PUBLISH</Button>
            </HStack>
          </VStack>
          <VStack minW="550px" minH="660px" pl={4} pr={4} alignItems="left">
            <HStack>
              <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Active Questions</Heading>
              <Spacer/>
              <Button as={ReactLink} to="/questionnaires/questions/add" leftIcon={<AddIcon />} colorScheme="primary" variant="solid" size="sm">NEW QUESTION</Button>
            </HStack>
            {activeQuestionsList.length > 0 ? (
              <Box>
                <Droppable droppableId="activeList" direction="vertical">
                  {(droppableProvided: DroppableProvided) => (
                    <List spacing={3} variant="simple" size="sm"
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}>
                      {activeQuestionsList && activeQuestionsList.map((question : Question, i: number) => {
                        return (
                          <Draggable
                            key={question.key + "active"}
                            draggableId={(question.key + "active") as DraggableId}
                            index={i}
                          >
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <ListItem bg="white" fontWeight="bold" fontSize="md" color="gostation.500" p={2} key={i}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                      background: snapshot.isDragging
                                        ? "rgba(245,245,245, 0.75)"
                                        : "none"
                                  }}>
                                  {question.text}
                                  <Link as={NavLink} to={"/questionnaires/questions/" + question.key} float="right"><ListIcon as={GrEdit}/></Link>
                                </ListItem>
                              );
                            }}
                          </Draggable>
                        )
                      })}
                      {droppableProvided.placeholder}
                    </List>
                  )}
                </Droppable>
                <Box h="6%" pr={2}>
                  <Stack direction="row" spacing={1} pt={2}>
                    <Spacer/>
                    <IconButton variant="outline" aria-label="Previous page" isDisabled={!isPrevPage(pageRequestActive, totalHitsActive)} boxSize={6}
                      icon={<ChevronLeftIcon />} onClick={() => {prevPage(pageRequestActive, setPageRequestActive)}}/>
                    <Text pl={1} fontSize="sm">
                      {(pageRequestActive.pageSize * pageRequestActive.page)}-{Math.min(pageRequestActive.pageSize * (pageRequestActive.page +1), totalHitsActive)} of {totalHitsActive}
                    </Text>
                    <IconButton variant="outline" isDisabled={!isNextPage(pageRequestActive, totalHitsActive)} aria-label="Next page"
                      boxSize={6} icon={<ChevronRightIcon />} onClick={() => {nextPage(pageRequestActive, setPageRequestActive)}}/>
                  </Stack>
                </Box>
              </Box>) :
              (<Center ml={4} mr={4} minH={200} borderWidth={1} position='relative' borderStyle="dashed" color="gray" fontSize="xl">
                {isLoadingActiveQuestions ? (
                  <div className="custom-loader"></div>
                ) : (
                <VStack>
                  <Heading fontWeight="bold" size="md">No active Questions</Heading>
                  <Text fontSize="md" p={2}>Please add a new one</Text>
                </VStack>
                )}
              </Center>)}
            <Heading pb={2} pt={2} fontWeight="bold" size="sm" color="gostation.500">Inactive Questions</Heading>
            {inactiveQuestionsList.length > 0 ? (<Box>
              <List spacing={3} variant="simple" size="sm">
                {inactiveQuestionsList && inactiveQuestionsList.map((question : Question, i: number) => {
                  return (
                    <ListItem bg="white" fontWeight="bold" fontSize="md" color="gray" p={2} key={i}>
                      {question.text}
                      <Link as={NavLink} to={"/questionnaires/questions/" + question.key} float="right"><ListIcon as={GrEdit}/></Link>
                    </ListItem>)
                })}
              </List>
              <Box h="6%" pr={2}>
                <Stack direction="row" spacing={1} pt={2}>
                  <Spacer/>
                  <IconButton variant="outline" aria-label="Previous page" isDisabled={!isPrevPage(pageRequestInactive, totalHitsInactive)} boxSize={6}
                    icon={<ChevronLeftIcon />} onClick={() => {prevPage(pageRequestInactive, setPageRequestInactive)}}/>
                  <Text pl={1} fontSize="sm">
                    {(pageRequestInactive.pageSize * pageRequestInactive.page)}-{Math.min(pageRequestInactive.pageSize * (pageRequestInactive.page +1), totalHitsInactive)} of {totalHitsInactive}
                  </Text>
                  <IconButton variant="outline" isDisabled={!isNextPage(pageRequestInactive, totalHitsInactive)} aria-label="Next page"
                    boxSize={6} icon={<ChevronRightIcon />} onClick={() => {nextPage(pageRequestInactive, setPageRequestInactive)}}/>
                </Stack>
              </Box>
            </Box>) :
            (<Center ml={4} mr={4} position='relative' minH={200} borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">
              {isLoadingInactiveQuestions ? (
                  <div className="custom-loader"></div>
                ) : (
              <VStack>
                <Heading fontWeight="bold" size="md">No inactive Questions</Heading>
              </VStack>)}
            </Center>)}
          </VStack>
        </HStack>
      </DragDropContext>
    </Flex>
  )
}
