import { AxiosResponse } from "axios";
import { VehicleMake, VehicleModel, VehicleUpdate } from "../types/VehicleTypes";
import { CsvImportResponse, EntityListResponse } from "../types/ApiTypes";
import {
  getMakeList,
  getModelListByBrand,
  addMake as addMakeApi,
  addModel as addModelApi,
  addMakeModelCsv,
  modifyMake as modifyMakeApi,
  modifyModel as modifyModelApi,
  modifyMakeAndModel as modifyMakeAndModelApi
} from "./VehicleService";

const getErrorMessage = (error: any) => {
  return error.message + " (API: " + error.response.data.apierror.debugMessage + ")";
}

export async function sendCsvFile(csvFile: VehicleModel[]) {
  return await addMakeModelCsv(csvFile).then((response: AxiosResponse) => {
    let importResponse: CsvImportResponse = response.data;
    return importResponse;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function getVehicleBrands() {
  let params = {
    page: 0,
    size: 999
  }
  return await getMakeList(params).then((response: AxiosResponse) => {
    let entityResponse: EntityListResponse<string> = response.data;
    return entityResponse.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function getVehicleModels(brand: string) {
  let params = {
    page: 0,
    size: 999,
    brand: brand
  }
  return await getModelListByBrand(params).then((response: AxiosResponse) => {
    let entityResponse: EntityListResponse<string> = response.data;
    return entityResponse.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function addVehicleBrand(newBrand: string) {
  let body: VehicleMake = {
    brand: newBrand
  }
  return await addMakeApi(body).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function addVehicleModel(brand: string, newModel: string) {
  let body: VehicleModel = {
    brand: brand,
    model: newModel,
    mmdId: null,
    startYear: null,
    endYear: null
  }
  return await addModelApi(body).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function modifyVehicleBrand(brand: string, newBrand: string) {
  let body: VehicleUpdate = {
    brand: brand,
    model: null,
    newBrand: newBrand,
    newModel: null
  }
  return await modifyMakeApi(body).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function modifyVehicleModel(brand: string, model: string, newModel: string) {
  let body: VehicleUpdate = {
    brand: brand,
    model: model,
    newBrand: null,
    newModel: newModel
  }
  return await modifyModelApi(body).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}

export async function modifyVehicleBrandAndModel(brand: string, model: string, newBrand: string, newModel: string) {
  let body: VehicleUpdate = {
    brand: brand,
    model: model,
    newBrand: newBrand,
    newModel: newModel
  }
  return await modifyMakeAndModelApi(body).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error: any) => {
    return getErrorMessage(error);
  });
}
