import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"

export function DashBoard() {

    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem isCurrentPage>
                      <BreadcrumbLink as={Link} to="/dashboard">
                          <Text>Dashboard</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
      </Box>
  );
}
